.flexContainer {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
}

.flexContainer > button {
    flex: 1;
}

.wideComponent {
    width: 100%;
}

.gridContainer {
    display: flex;
    flex-wrap: nowrap;
    gap: 12px;
    width: 100%;
    align-items: end;
    margin-top: -7px;
}

.gridContainer > div:first-child {
    flex-grow: 1;
}

.horizontalContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 12px;
}

.imageCont {
    width: 212px;
    height: 58px;
    border-radius: 8px;
    position: relative;
    border: 1px solid transparent;
    top: 12px;
}

.imageCont img {
    width: 100%;
    height: 58px;
    object-fit: cover;
    border-radius: 8px;
}

.dialogueError {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -150%);
    width: max-content;
    white-space: nowrap;
    color: var(--primary-red);
}

.changeCoverButton {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    display: flex;
    gap: 3px;
    max-width: 100%;
    white-space: nowrap;
    background: var(--text-light-gray-secondary);
    color: var(--white);
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    border-radius: 14px;
    backdrop-filter: blur(2px);
    padding: 0 6px;
    cursor: pointer;
    transform: translate(-50%, -50%);
}

.iconCloud svg {
    transform: scale(0.75);
    filter: invert(1);
    min-height: 16px;
}

.currentTopicContainer {
    margin-left: -4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% + 4px);
    height: 32px;
    background-color: var(--transparent-gray);
    border-radius: 8px;
    padding: 4px;
    padding-right: 0;
}

.currentTopicContainer p {
    font-size: 12px;
}

.buttonsContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: auto;
}
