.wordCard {
    display: flex;
    width: calc(100% - 20px);
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 28px;
    box-shadow: 0px 0px 7px 0px var(--black-transparent);
    height: fit-content;
    min-height: 250px;
    position: relative;
}

.wordText {
    display: flex;
    align-items: flex-end;
    gap: 5px;
    font-weight: 400;
    line-height: 14px;
    color: var(--text-light-gray-secondary);
    word-wrap: break-word;
}

.wordText span {
    font-size: 22px;
    font-weight: 500;
    line-height: 20px;
    width: 100%;
    color: var(--night-gray);
    width: min-content;
}

.errorStatus {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: var(--primary-red);
}

.wordInfo {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--text-light-gray-secondary);
}

.infoLabel {
    text-transform: lowercase;
    color: var(--words-green);
    width: fit-content;
}

.examplesCont {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

.translation {
    color: var(--text-light-gray-secondary);
    margin-bottom: 6px;
}

.exampleCont {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 6px;
}

.exampleCont svg {
    width: 14px;
    height: 12px;
}

.exampleText {
    text-decoration: underline;
}

.emptyAlert {
    margin: auto;
    text-align: center;
    color: var(--text-light-gray-secondary);
}

.buttons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.buttons button:last-child {
    margin-left: auto;
}

.horizontalCont {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
}

.addButton {
    cursor: pointer;
    font-size: 20px;
    font-weight: 100;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--transparent-gray);
    border: 1px solid var(--secondary-light-gray);
    line-height: 26px;
    text-align: center;
    transition: all 0.2s;
}

@media (hover: hover) {
    .addButton:hover {
        background-color: var(--secondary-light-gray);
        border: 1px solid var(--secondary-light-gray);
    }
}
