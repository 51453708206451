.notifyCard {
    display: flex;
    width: calc(100% - 20px);
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 28px;
    box-shadow: 0px 0px 7px 0px var(--black-transparent);
    height: fit-content;
    min-height: 250px;
    position: relative;
}

.section {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.label {
    padding: 2px 6px;
    margin-bottom: 6px;
    background: var(--transparent-gray);
    color: var(--text-light-gray-secondary);
    width: fit-content;
    border-radius: 12px;
    text-transform: capitalize;
}

.text {
    margin-left: 6px;
    line-height: 38px;
}

.descriptionContainer {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.description {
    font-size: 12px;
    margin-left: 6px;
    line-height: 12px;
    color: var(--text-light-gray-secondary);
    position: relative;
}

.buttonsCont {
    margin-top: auto;
    display: flex;
    gap: 20px;
}
