.serviceList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
}

.loadingPlaceholder {
    width: 50%;
    height: 50px;
    border-radius: 28px;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(
        to right,
        var(--secondary-light-gray) 1%,
        var(--medium-gray) 18%,
        var(--secondary-light-gray) 33%
    );
    background-size: 800px 104px;
    z-index: 4;
    opacity: 0.5;
}

.buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -800px 0;
    }
    100% {
        background-position: 800px 0;
    }
}
