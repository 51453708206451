.entityCard {
    width: 100%;
    position: relative;
    border-radius: 14px;
    overflow: hidden;
    box-shadow: 0px 20px 44px 0px var(--black-transparent);
    cursor: pointer;
}

.entityCard.loading {
    cursor: default;
    position: relative;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(
        to right,
        var(--secondary-light-gray) 1%,
        var(--medium-gray) 18%,
        var(--secondary-light-gray) 33%
    );
    background-size: 800px 104px;
    position: relative;
    z-index: 4;
}

.entityCard.loading::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: var(--white);
    pointer-events: none;
    opacity: 0.8;
    z-index: 1;
}

.entityTitle,
.infoObj {
    background: var(--text-light-gray-secondary);
    color: var(--white);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    border-radius: 14px;
    backdrop-filter: blur(2px);
    z-index: 2;
}

.entityTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    max-height: 60%;
    max-width: 80%;
    padding: 8px 16px;
    margin: 0;
    text-transform: capitalize;
}

.status {
    position: absolute;
    top: 3px;
    right: 3px;
    z-index: 3;
    gap: 4px;
    display: flex;
}

.entityCard img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: all 0.2s;
    pointer-events: none;
    transform: translateY(-15%);
}

.infoCont {
    position: absolute;
    z-index: 3;
    display: flex;
    gap: 5px;
}

.infoCont.top {
    top: 3px;
    left: 3px;
}

.infoCont.bottom {
    bottom: 3px;
    right: 3px;
}

.infoObj {
    display: flex;
    gap: 3px;
    padding: 3px 6px;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    text-transform: capitalize;
}

.icon svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
}

.friendly path {
    fill: var(--primary-green);
}

.neutral path {
    fill: var(--yellow);
}

.rude path {
    fill: var(--primary-red);
}

@media (hover: hover) {
    .entityCard:hover img {
        transform: scale(1.05) translateY(-15%);
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -800px 0;
    }
    100% {
        background-position: 800px 0;
    }
}
