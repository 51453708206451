.fileModal {
    width: 100%;
    height: 370px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 50px 100px;
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    user-select: none;
}

.uploadTitle,
.uploadSubtitle {
    font-family: 'Inter';
    font-style: normal;
    color: var(--black);
    font-weight: 400;
}

.uploadTitle {
    font-size: 32px;
    line-height: 39px;
}

.uploadSubtitle {
    font-size: 14px;
    line-height: 17px;
}

.inputCont {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
}

.inputCont p {
    font-size: 15px;
}

.uploadIcon {
    content: url('../../assets/svg/icon-file-upload.svg');
    width: 60px;
    height: 60px;
    filter: grayscale(1) brightness(0.5);
}

.customUploadButton {
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    width: min-content;
    white-space: nowrap;
    line-height: 20px;
    user-select: none;
    -webkit-user-drag: none;
    position: relative;
}

.customUploadButton input[type='file'] {
    display: none;
}

.lessonModal {
    background: var(--white);
    border-radius: 16px;
    position: relative;
    min-width: 250px;
    max-height: 80vh;
    overflow: auto;

    display: flex;
    max-width: 580px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
    padding: 0;
    height: fit-content;
}

.lessons .uploadTitle {
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: -8px;
    line-height: 30px;
}

.lessons .uploadSubtitle {
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    color: var(--text-light-gray-secondary);
    margin-bottom: 12px;
}

.fileModal.lessons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 600px;
    padding: 25px;
    height: fit-content;
}

.fileModal.lessons > * {
    width: 100%;
}

.lessons .uploadIcon {
    display: none;
}

.lessons .inputCont .uploadSubtitle {
    display: none;
}

.lessons .inputCont {
    width: 100%;
    height: 100px;
    border-radius: 20px;
    margin: 30px 0;
    position: relative;
    border: 1px dashed var(--text-light-gray-secondary);
}

.lessons .customUploadButton {
    display: flex;
    gap: 6px;
    margin: auto;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 32px;
    padding: 12px 24px;
    position: relative;
    border-radius: 8px;
    white-space: nowrap;
    user-select: none;
    -webkit-user-drag: none;
    transition: all 0.2s;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    background: var(--primary-light-gray);
    color: var(--black);
}

@media (hover: hover) {
    .customUploadButton:hover {
        background: var(--border-light-gray);
    }
}
