.arrow {
    width: 11px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s;
    margin-top: -3px;
    max-width: 0;
}

.arrow.visible {
    max-width: 11px;
    min-width: 11px;
    margin-right: 30px;
}

.arrow:hover {
    transform: scale(1.1);
}
