.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    overscroll-behavior: none;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-height: 400px;
    gap: 16px;
    position: absolute;
}

.phoneContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: fit-content;
    height: 100%;
    max-height: 100%;
    position: absolute;
    padding: 15px 0;
}

.logoCont {
    display: flex;
    justify-content: center;
}

.ytLogo {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    margin: 0 auto;
}

.ytLogo img {
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
}

.buttonText {
    max-width: 50%;
    margin: 0;
}

@media (max-width: 480px) {
    .titleContainer {
        margin-top: -25%;
        justify-content: center;
    }
    .logoCont {
        margin-bottom: 25%;
    }
    .buttonText {
        max-width: 100%;
    }
}
