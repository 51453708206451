.chapterSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 var(--_24px);
    margin: 150px 0 100px;
}

.sectionTitle {
    font-size: var(--_36px);
    font-weight: 500;
    line-height: var(--_42px);
    letter-spacing: -0.02em;
    color: var(--black);
    text-align: center;
    margin-bottom: var(--_12px);
}

.sectionSubtitle {
    font-size: var(--_16px);
    font-weight: 400;
    line-height: var(--_22px);
    letter-spacing: -0.02em;
    color: #1c1c1e80;
    max-width: 60%;
    margin-bottom: var(--_40px);
}

.sectionSubtitle span {
    font-size: var(--_16px);
    font-weight: 500;
    line-height: var(--_22px);
    letter-spacing: -0.02em;
    color: var(--black);
}

.previewContainer {
    background-color: var(--white-pressed);
    width: 100%;
    border-radius: var(--_16px);
    max-width: 1638px;
    height: calc(100vh - 115px);
    max-height: calc(100vh - 115px);
    display: flex;
    flex-direction: column;
    gap: 0;
    padding-top: var(--_12px);
}

.previewInner {
    width: 100%;
    display: flex;
    flex-grow: 1;
    max-height: calc(100vh - 175px);
    gap: var(--_12px);
    padding: var(--_12px);
}

.chaptersContainer {
    max-width: 38%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--_24px);
    justify-content: space-between;
    border-radius: var(--_12px);
}

.chaptersList {
    background-color: var(--white);
    border-radius: var(--_12px);
    border: 1px solid var(--border-light-gray);
    box-shadow: 0px 4px 26.9px 0px var(--landing-transparent-shadow);
    width: 100%;
    height: 100%;
    overflow: scroll;
}

.chaptersList > .taskDescription:last-child {
    border-bottom: none;
}

.buttonDescription {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--_4px);
}

.buttonDescriptionTitle {
    font-size: var(--_18px);
    font-weight: 400;
    line-height: var(--_22px);
    text-align: center;
}

.buttonDescriptionSubtitle {
    font-size: var(--_13px);
    line-height: var(--_20px);
    font-weight: 400;
    color: #1c1c1e80;
    margin-bottom: var(--_12px);
}

.selectContainer {
    width: 100%;
}

.exerciseTypeSelect {
    padding: var(--_13px);
    gap: var(--_8px);
    background-color: var(--blue);
    color: var(--white);
    box-shadow: 0px 4px 26.9px 0px var(--landing-transparent-shadow);
    width: 100%;
    padding: var(--_13px);
    font-size: var(--_13px);
    min-height: var(--_36px);
    height: var(--_36px);
    border-radius: var(--_12px);
    align-items: center;
    cursor: pointer;
    width: 100%;
}

.exerciseTypeSelect * {
    font-size: var(--_13px);
}

.buttonsBar {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: var(--_12px);
}

.buttonsBar .select,
.button,
.teacherButton {
    font-size: var(--_13px);
    line-height: var(--_18px);
    min-height: var(--_36px);
    height: var(--_36px);
    border-radius: 12px;
    width: 100%;
}

.buttonsBar .select * {
    font-size: var(--_12px);
    line-height: var(--_20px);
}

.buttonsBar .select.words {
    background: var(--words-green);
    color: var(--white);
}

.buttonsBar .select.exercise {
    background: var(--blue);
    color: var(--white);
}

.buttonsBar .select.track {
    background: var(--purple);
    color: var(--white);
}

.buttonsBar .select.situation {
    background: var(--orange);
    color: var(--white);
}

.teacherButton {
    width: 100%;
}

.buttonLang > svg:last-child {
    margin-left: auto;
}

.button {
    gap: var(--_6px);
    flex-direction: row-reverse;
    min-height: var(--_36px);
    height: var(--_36px);
    border-radius: 12px;
    width: 100%;
}

.startIcon {
    min-width: var(--_16px);
}

.eyeIcon {
    min-width: var(--_20px);
}

.hidden {
    opacity: 0;
    width: 0;
    height: 0;
    pointer-events: none;
    display: none;
}
