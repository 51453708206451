.exerciseCard {
    width: calc(100% + 24px);
    margin-left: -12px;
    height: min-content;
    box-sizing: border-box;
    padding: 12px 16px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    transition: all 0.1s;
    position: relative;
    margin-bottom: -12px;
}

.exerciseCard:last-child {
    margin-bottom: 0;
}

.preview {
    width: 100px;
    min-width: 100px;
    height: 56px;
    border-radius: 8px;
    position: relative;
    background: var(--white);
}

.cardInner {
    display: flex;
    flex-grow: 1;
    max-width: calc(100% - 116px);
    overflow: visible;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
    position: relative;
    height: fit-content;
}

.title {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    height: 20px;
    min-height: 20px;
    text-align: left;
}

.title span {
    color: var(--text-light-gray);
    text-transform: uppercase;
    margin-left: 4px;
    font-size: 16px;
    line-height: 20px;
}

.horizontalContainer {
    display: flex;
    align-items: center;
    gap: 48px;
}

.horizontalContainer button {
    padding: 6px 12px;
    min-height: 28px;
    height: 28px;
}

@media (hover: hover) {
    .exerciseCard:hover {
        background: var(--light-gray);
    }
}
