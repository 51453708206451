.container {
    width: 100%;
    height: 100%;
    min-height: 600px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.textContainer {
    width: 100%;
    height: 80%;
    max-height: 600px;
    margin-top: auto;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    justify-content: space-between;
    z-index: 2;
    letter-spacing: 0px;
    text-align: center;
}

.gradient {
    width: 100%;
    max-width: 100vw;
    height: 100dvh;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    min-height: 600px;
    transform: scale(1.4);
}

.decoration {
    position: absolute;
}

.decoration:nth-child(1) {
    top: 0;
    left: 0;
}

.decoration:nth-child(2) {
    top: 0;
    right: 0;
}

.decoration:nth-child(3) {
    bottom: -5%;
    right: 0;
}

.buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.navigation {
    display: flex;
    width: 100%;
    gap: 30px;
    justify-content: center;
}

.text {
    font-family: Montserrat;
    transition: color 0.2s;
    margin: 0;
    white-space: nowrap;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
}

.buttonText {
    margin-top: 8px;
    width: 100%;
    max-width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
}

@media (max-width: 768px) {
    .gradient {
        transform: scale(1.5);
    }
    .buttonText,
    .text {
        font-size: 12px;
        line-height: 13px;
    }
    .decoration:nth-child(3) {
        bottom: -10%;
        left: 10%;
    }
}

@media (max-width: 480px) {
    .textContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: auto;
        margin-bottom: 50px;
        height: 50%;
        justify-content: space-between;
    }
    .decoration {
        transform: scale(0.9);
    }
    .decoration:nth-child(1) {
        top: -10%;
        left: -10%;
    }
    .decoration:nth-child(2) {
        top: 0;
        right: -10%;
    }
    .decoration:nth-child(3) {
        bottom: -5%;
        right: 5%;
        transform: scale(0.8) rotate(15deg);
    }
    .buttonText,
    .text {
        font-size: 10px;
        line-height: 11px;
    }
}
