.chip {
    padding: 4px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    background: var(--primary-light-gray);
    transition: all 0.2s;
    border-radius: 13px;
    font-weight: 400;
    cursor: default;
    line-break: anywhere;
}

.chip span {
    font-size: 12px;
    line-height: 12px;
}

.iconClose {
    cursor: pointer;
    width: 12px;
    height: 12px;
    fill: var(--dark-gray);
}
