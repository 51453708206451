.teacherLanding {
    width: 100%;
}

.teacherLanding.blocked {
    overflow: hidden;
}

/* .teacherLanding > * {
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: visible;
} */

* {
    font-family: 'Noto Sans', sans-serif;
    color: var(--black-hover);
}

:root {
    --_2px: calc(var(--adptv) * 0.09);
    --_3px: calc(var(--adptv) * 0.133);
    --_4px: calc(var(--adptv) * 0.18);
    --_6px: calc(var(--adptv) * 0.266);
    --_8px: calc(var(--adptv) * 0.36);
    --_9px: calc(var(--adptv) * 0.4);
    --_10px: calc(var(--adptv) * 0.45);
    --_11px: calc(var(--adptv) * 0.49);
    --_12px: calc(var(--adptv) * 0.54);
    --_13px: calc(var(--adptv) * 0.58);
    --_14px: calc(var(--adptv) * 0.62);
    --_16px: calc(var(--adptv) * 0.71);
    --_18px: calc(var(--adptv) * 0.8);
    --_20px: calc(var(--adptv) * 0.89);
    --_22px: calc(var(--adptv) * 0.98);
    --_24px: calc(var(--adptv) * 1.07);
    --_28px: calc(var(--adptv) * 1.24);
    --_30px: calc(var(--adptv) * 1.37);
    --_32px: calc(var(--adptv) * 1.42);
    --_36px: calc(var(--adptv) * 1.6);
    --_40px: calc(var(--adptv) * 1.78);
    --_42px: calc(var(--adptv) * 1.87);
    --_48px: calc(var(--adptv) * 2.13);
    --_50px: calc(var(--adptv) * 2.22);
    --_58px: calc(var(--adptv) * 2.58);
    --_64px: calc(var(--adptv) * 2.84);
    --_72px: calc(var(--adptv) * 3.2);
}
