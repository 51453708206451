.modalCont {
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    min-width: 600px;
}

.modalTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 15px;
}

.inputsCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.labeledInput {
    min-width: 600px;
}

.alert {
    min-width: 20px;
}

.alert:empty::before {
    content: '';
    display: inline-block;
}
