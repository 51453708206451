.translationCont {
    padding: 12px 48px 6px 12px;
    margin-left: -12px;
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    width: 100%;
    height: 100%;
    height: 15%;
    max-height: 15%;
    background: var(--black);
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
    pointer-events: none;
    background: var(--modal-overlay-background);
}

.translationText {
    color: var(--white);
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
}

.translationText.blur {
    filter: blur(5px);
}

.translationText span {
    background: var(--purple);
    color: var(--white);
    font-family: Noto Sans;
    padding: 3px 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
}

.eyeButton {
    width: 36px;
    height: 36px;
    max-height: 36px;
    border-radius: 50%;
    padding: 0;
    position: absolute;
    right: 0;
    opacity: 1;
    background: var(--text-light-gray-secondary);
}

.eyeButton svg {
    height: 16px;
    width: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.eyeButton path {
    stroke: var(--white);
}
