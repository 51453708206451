.cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.status {
    padding: 5px 10px;
    width: fit-content;
    background: var(--green-transparent);
    border: 0.5px solid var(--green);
    border-radius: 10px;
}

.status.red {
    background: var(--primary-transparent-red);
    border: 0.5px solid var(--primary-red);
}

.preview {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
    width: 100%;
}
