.addTheme {
    height: 100%;
    min-width: calc(25% - 9px);
    max-width: calc(25% - 9px);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--text-light-gray);
    border-style: dashed;
    border-radius: 12px;
    cursor: pointer;
}

.addTheme p {
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: var(--text-light-gray);
}

.emptyCard {
    padding: 8px 16px 8px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    height: fit-content;
    border-radius: 12px;
    border: 1px solid var(--primary-light-gray);
    background: var(--light-gray);
    min-width: calc(25% - 9px);
    max-width: calc(25% - 9px);
}

.emptyCard > input {
    padding: 0px 8px 6px 0px;
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    background: transparent;
    border: transparent;
    border-radius: 0;
}

.buttons {
    margin-top: 6px;
    display: flex;
    justify-content: flex-start;
    gap: 6px;
    margin-left: -8px;
}

.buttons button {
    padding: 6px 10px;
}

.buttons button:last-child {
    background: transparent;
}

.buttons button:last-child path {
    fill: var(--black);
}
