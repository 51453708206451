.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--_24px) var(--_48px) var(--_48px) var(--_24px);
    user-select: none;
    position: relative;
}

.navigationBlock {
    display: flex;
}

.navigation {
    width: 500px;
    height: 160px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;
    position: relative;
}

.navigation.hidden {
    opacity: 0;
    pointer-events: none;
}

/* .footer:before {
    content: '';
    width: 100%;
    height: 1px;
    background: var(--text-light-gray);
    position: absolute;
    top: 0;
    left: 0;
} */

.navigation:before {
    left: 0;
}

.navigation:after {
    right: 0;
}

.teacherLogo {
    width: 32px;
    height: 32px;
    margin-top: -4.5px;
}

.teacherLogoText {
    margin-top: 1px;
    transform: scale(0.8);
}

.link {
    width: calc(50% - 8px);
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
    letter-spacing: -0.02em;
    text-align: left;
    text-decoration: underline;
}

.logo {
    display: flex;
    gap: var(--_6px);
    margin-left: var(--_4px);
    margin-top: var(--_6px);
    position: relative;
    width: 100px;
    min-width: 100px;
    cursor: pointer;
    margin-right: auto;
}

/* .navigation .link:nth-child(3) {
    margin-bottom: 40px;
} */

.greyBlock {
    flex: 1;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--_16px);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: var(--_42px);
    padding: 0 var(--_24px);
    background: var(--primary-light-gray);
}

.greyBlock .link {
    color: var(--text-light-gray-secondary);
    width: fit-content;
    white-space: nowrap;
}

.greyBlock .link:first-child {
    margin-right: auto;
    text-decoration: none;
}

.greyText {
    color: #1c1c1e66;
}
