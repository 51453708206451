.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.container::-webkit-scrollbar {
    width: 0;
}

.horizontalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
}

@media (max-width: 768px) {
    .horizontalContainer {
        gap: 16px;
    }
}

@media (max-width: 768px) {
    .horizontalContainer {
        gap: 8px;
    }
}
