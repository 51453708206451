.container {
    width: calc(100% + 40px);
    overflow: hidden scroll;
    height: 100%;
    padding: 20px;
    margin-left: -20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 130px;
    gap: 8px 16px;
    box-sizing: border-box;
}

.container::-webkit-scrollbar {
    width: 0;
}
