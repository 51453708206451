.newLangCard {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    width: 100%;
    background: var(--transparent-gray);
    border: 1px solid var(--transparent-gray);
    padding: 8px 10px 10px 10px;
    border-radius: 32px;
}

.newLangCard.error {
    border: 1px solid var(--primary-red);
}

.controls {
    margin-left: auto;
    display: flex;
    gap: 4px;
    align-items: center;
}

.controls path {
    fill: var(--dark-gray);
}

.controls svg {
    cursor: pointer;
}

.controls svg:hover {
    transform: scale(1.1);
}
