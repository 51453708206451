.editModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 680px;
    height: 440px;
    min-height: 440px;
    padding: 0 24px 24px;
    background: var(--white);
    border-radius: 16px;
    position: relative;
    min-width: 250px;
    max-height: 80vh;
    overflow: auto;

    max-width: 1090px;
}

.editModal > * {
    width: 100%;
}

.flexContainer {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
}

.flexContainer > * {
    flex: 1;
}

.flexContainer:last-child {
    margin-top: auto;
}

.select {
    width: 100%;
}

.modalTitle {
    width: 100%;
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    background: var(--transparent-gray);
    width: calc(100% + 48px);
    padding: 14px 24px;
    margin-bottom: -10px;
}

.iconStroke path {
    stroke: var(--medium-gray);
    stroke-opacity: 1;
}
