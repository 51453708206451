.landingCont {
    width: 100vw;
    background: var(--secondary-light-gray);
    position: relative;
    display: grid;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
}

.landingCont::-webkit-scrollbar {
    width: 0;
}
