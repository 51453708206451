.controlsCont {
    width: 100%;
    padding: 0 14px;
    height: 50px;
    position: absolute;
    bottom: -2px;
    background: linear-gradient(
        360deg,
        var(--black) 0%,
        var(--black-transparent) 100%
    );
}

.controlsCont button {
    position: relative;
}

.trackLine {
    width: calc(100% + 6px);
    height: 3px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s;
    margin-bottom: 0px;
    margin-left: -3px;
    background: var(--transparent-gray);
}

.trackLine::before {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    height: 9px;
    left: 0;
    background-color: transparent;
    top: -3px;
    z-index: 0;
}

.trackLine::after {
    content: '';
    position: absolute;
    height: 3px;
    background: var(--transparent-gray);
    pointer-events: none;
    z-index: 1;
}

.trackLineWrapper {
    position: relative;
}

.playPauseBtnsCont {
    height: calc(100% - 3px);
    position: relative;
    display: flex;
    justify-content: center;
    gap: 48px;
    padding: 0 20px;
    align-items: center;
    transition: filter 0.2s;
}

.playPauseBtnsCont.disabled {
    filter: grayscale(1) brightness(0.2);
    pointer-events: none;
}

.playPauseBtn {
    width: 50px;
    height: 50px;
    position: relative;
    z-index: 12;
}

.dragger {
    position: absolute;
    top: 50%;
    transition: top 0.3s;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    width: 13px;
    height: 13px;
    background: var(--purple);
    pointer-events: none;
    z-index: 6;
}

.timeItem {
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    position: absolute;
    top: 10px;
    color: var(--white);
}

.timeItem:first-child {
    left: 0;
}

.timeItem:last-of-type {
    right: 0;
    color: var(--transparent-gray);
}

.trackProgress {
    background: var(--purple);
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;
    z-index: 3;
}

.controlBtn,
.button,
.settingsBtn,
.translationBtn {
    background: none;
    border: none;
    cursor: pointer;
}

.button *,
.settingsBtn *,
.translationBtn * {
    transition: all 0.2s;
}

.button svg {
    width: 35px;
    height: 35px;
}

.button.disabled {
    filter: grayscale(1);
    opacity: 0.5;
    pointer-events: none;
}

.speedCont,
.pauseCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
    padding: 0 15px;
    border-radius: 37px;
    z-index: 10;
    pointer-events: all;
    background: transparent;
    position: relative;
}

.pauseCont {
    padding: 0 5px;
}

.activeStroke path {
    stroke: var(--purple);
}

.whiteStroke path {
    stroke: var(--white);
}

.activeFill path {
    stroke: var(--purple);
    fill: var(--purple);
}

.whiteFill path {
    stroke: var(--white);
    fill: var(--white);
}

.playbackBtn {
    position: relative;
}

.speedPicker {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 37px;
    top: -5.5vh;
    transform: translate(-50%, -100%);
    left: 50%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s;
}

.speedPicker.visible {
    opacity: 1;
    pointer-events: all;
}

.speedPicker.landscape {
    top: 30px;
    left: -50px;
}

.speedBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 37px;
    cursor: pointer;
    background-color: var(--white);
}

.speedBtn.active {
    background-color: var(--purple);
    color: var(--white);
}
