.List {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1 1 auto;
    overflow: auto;
    min-height: 300px;
    overflow-x: visible;
    padding: 15px 0;
}

.loaderOverlay {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
}

.overlayInner {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    backdrop-filter: blur(2px);
    background: var(--text-light-gray);
}
