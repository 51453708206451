.exerciseText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
    user-select: none;
}

.wordContainer {
    display: flex;
    align-items: center;
}

.dropdown {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    min-width: 44px;
    height: 19px;
    padding: 1px 9px;
    padding-right: 18px;
    border-radius: 5px;
    background-color: var(--primary-light-gray);
    position: relative;
    border: 1px solid transparent;
}

.dropdownIcon {
    content: url('../../../../../../../../assets/svg/arrow_down.svg');
    width: 10px;
    height: 10px;
    position: absolute;
    top: 5px;
    right: 4px;
    filter: brightness(2.2);
}

.dropdownTooltip {
    visibility: hidden;
    width: max-content;
    height: min-content;
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    position: absolute;
    top: 25px;
    background: var(--white);
    border: 1px solid var(--border-light-gray);
    box-shadow: 0px 0px 7px 0px var(--black-transparent);
    border-radius: 8px;
    z-index: 1;
    padding: 6px 12px;
    transform: translate(-35%, 3px);
}

.dropdownText {
    color: var(--text-light-gray-secondary);
}

.optionsCont {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    max-height: 130px;
    overflow-y: scroll;
}

.optionsCont::-webkit-scrollbar {
    width: 0;
}

.optionsCont span {
    background-color: var(--primary-light-gray);
    border-radius: 5px;
    max-width: 300px;
    line-break: anywhere;
    padding: 1px 9px;
    text-align: center;
}

.optionsCont span:hover {
    background-color: var(--secondary-light-gray);
}

.dropdown.selected .dropdownTooltip {
    visibility: visible;
}

.dropdown.results {
    padding: 1px 8px;
    pointer-events: none;
}

.dropdown.results .dropdownIcon {
    display: none;
}

.dropdown.correct {
    background: var(--green-transparent);
    border: 1px solid var(--text-light-green);
    color: var(--black);
}

.dropdown.wrong {
    background: var(--primary-transparent-red);
    border: 1px solid var(--text-light-red);
    color: var(--black);
}
