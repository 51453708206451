.bubble {
    position: absolute;
    background-size: cover;
    opacity: 0.5;
    z-index: 0;
}

.en {
    width: 12vw;
    height: 12vw;
    top: 6%;
    left: 4%;
    background-image: url('../../../../assets/png/logo-en.png');
}

.es {
    width: 12vw;
    height: 12vw;
    bottom: 4%;
    left: 36%;
    background-image: url('../../../../assets/png/logo-es.png');
}

.de {
    width: 12vw;
    height: 12vw;
    top: 34%;
    right: 2%;
    background-image: url('../../../../assets/png/logo-de.png');
}

.fr {
    width: 30vw;
    height: 30vw;
    top: -24%;
    right: 12%;
    background-image: url('../../../../assets/png/logo-fr.png');
}

.it {
    width: 30vw;
    height: 30vw;
    bottom: -16%;
    left: -6%;
    background-image: url('../../../../assets/png/logo-it.png');
}

.pt {
    width: 25vw;
    height: 25vw;
    top: 75%;
    right: -6%;
    background-image: url('../../../../assets/png/logo-pt.png');
}
