.navBar {
    display: flex;
    gap: 10px;
    align-items: flex-end;
    width: 100%;
}

.recentlyCounter {
    height: 38px;
    line-height: 22px;
    padding: 7px 10px;
    white-space: nowrap;
    color: var(--black);
    border-radius: 20px;
    border: 1px solid var(--black);
}

.recentlyCounter span {
    font-weight: 500;
}

.tableHeader {
    display: flex;
    padding: 10px 0;
    align-items: flex-end;
    gap: 5px;
}

.lockedSearch {
    position: relative;
    width: 100%;
    height: 38px;
    padding: 0px 15px;
    padding-right: 47px;
    display: flex;
    align-items: center;
    color: var(--black);
    border: 1px solid var(--secondary-light-gray);
    background: var(--secondary-light-gray);
    border-radius: 20px;
    font-weight: 400;
    cursor: not-allowed;
}

.amount {
    padding: 4px;
    padding-left: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--text-light-gray-secondary);
}

.columnName:nth-child(1) {
    flex: 0 1 20%;
}

.columnName:nth-child(2) {
    flex: 0 1 20%;
}

.columnName:nth-child(3) {
    flex: 0 1 15%;
}

.columnName:nth-child(4) {
    flex: 0 1 15%;
}

.columnName:nth-child(5) {
    flex: 0 1 15%;
}

.columnName:nth-child(6) {
    flex: 0 1 15%;
}
