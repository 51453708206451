.container {
    width: 100%;
    height: 100%;
    position: relative;
    overscroll-behavior: none;
}

.innerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.textContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.chipsContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: calc(1vw + 1vh);
    pointer-events: none;
    padding: 0 10px;
}

.yellowText {
    width: 90%;
    max-width: 1150px;
    background-size: 100%;
    background-repeat: repeat;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    background-image: linear-gradient(
        180deg,
        var(--accent-orange) 0%,
        var(--accent-orange-transparent) 100%
    );
    -webkit-background-clip: text;
    line-height: 80px;
}

.yellowText span {
    font-size: 42%;
}

.gradient {
    width: 100%;
    max-width: 100vw;
    height: 100vh;
    min-height: 600px;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.8;
    pointer-events: none;
}

.decoration {
    position: absolute;
    width: 100vw;
    height: 100%;
    bottom: 0%;
    opacity: 0.25;
    top: 20%;
    transform: scale(1.3);
    pointer-events: none;
}

.hidden {
    opacity: 0;
}

.languagesContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px 6px;
    flex-wrap: wrap;
    max-width: 80%;
    position: absolute;
    bottom: 5%;
}

@media (max-width: 1368px) {
    .yellowText {
        width: 80%;
        line-height: 75px;
    }
}

@media (max-width: 1024px) {
    .yellowText {
        line-height: 65px;
    }
}

@media (max-width: 768px) {
    .yellowText {
        line-height: 50px;
    }
    .languagesContainer {
        gap: 6px 3px;
    }
}

@media (max-width: 480px) {
    .yellowText {
        width: 75%;
        line-height: 36px;
    }
    .decoration {
        transform: none;
        bottom: -10%;
    }
}

@supports (height: 100dvh) {
    .chipsContainer {
        gap: calc(1vw + 1dvh);
    }
    .gradient {
        height: 100dvh;
    }
    .decoration {
        width: 100dvw;
    }
}
