.chaptersContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border: 1px solid var(--border-light-gray);
    border-radius: 12px;
    overflow: hidden;
}

.emptyAlert {
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    width: 80%;
}

.emptyAlert svg path {
    fill: var(--primary-light-gray);
}

.alertTitle,
.alertSubtitle {
    text-align: center;
    color: var(--text-light-gray);
}

.alertTitle {
    margin-top: 26px;
    font-size: 32px;
    font-weight: 400;
    line-height: 43.58px;
}

.alertSubtitle {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.list {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    max-height: 100%;
}
