.input {
    width: 100%;
    padding: 0px 15px;
    margin: 0;
    background: var(--white);
    border: 1px solid var(--border-light-gray);
    height: 38px;
    max-height: 38px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    outline: none;
    border-radius: 20px;
}

.input::placeholder {
    color: var(--text-light-gray-secondary);
    font-weight: 300;
}

.input.blur {
    background: var(--transparent-gray);
}

.input.red {
    background: var(--white);
    border: 1px solid var(--primary-red);
}

.input[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}
