.searchBar {
    display: flex;
    column-gap: 10px;
    align-items: flex-end;
    flex-wrap: nowrap;
}

.amount {
    padding: 10px;
    padding-bottom: 3px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--text-light-gray-secondary);
}

.regularOptions {
    display: flex;
    width: 100%;
    column-gap: 10px;
    align-items: flex-end;
    flex-wrap: nowrap;
}

.regularOptions.disabled {
    opacity: 0;
    pointer-events: none;
}
