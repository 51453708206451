.file {
    min-height: 80px;
    max-height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    padding: 12px 16px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    transition: all 0.1s;
    position: relative;
    cursor: pointer;
}

.file.active {
    background: var(--primary-light-gray);
}

.imageContainer {
    width: 100px;
    min-width: 100px;
    height: 56px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    border: 1px solid var(--primary-light-gray);
}

.imageContainer .pdfPreview {
    width: 100px;
    height: 100%;
    max-width: 100px;
    max-height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.pdfPreview * {
    pointer-events: none;
}

.cardInner {
    display: flex;
    flex-grow: 1;
    max-width: calc(100% - 116px);
    overflow: visible;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 4px;
    position: relative;
}

.title {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    height: 40px;
    white-space: pre-wrap;
    text-align: left;
    overflow: hidden;
}

.title::after {
    content: '\A';
    white-space: pre;
}

.infoContainer {
    display: flex;
    gap: 4px;
    justify-content: flex-start;
    align-items: center;
    min-height: 12px;
    max-height: 12px;
}

.label {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: var(--text-light-gray);
    text-transform: uppercase;
    white-space: nowrap;
}

.label::after {
    content: '∙';
    font-weight: 600;
    margin-left: 4px;
}

.label:last-child::after {
    display: none;
}

.file > * {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    text-align: center;
    color: var(--text-light-gray-secondary);
}

@media (hover: hover) {
    .file:not(.active):hover {
        background: var(--white-hover);
    }

    .file.active:hover {
        background: var(--secondary-light-gray);
    }
}
