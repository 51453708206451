.previewContainer {
    background-color: var(--white);
    border: 1px solid var(--border-light-gray);
    border-radius: var(--_12px);
    padding: 0px;
    box-shadow: 0px 4px 26.9px 0px var(--landing-transparent-shadow);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--_42px);
    overflow: scroll;
    scroll-behavior: smooth;
}
