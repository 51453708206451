.wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    border-radius: 12px;
    overflow: hidden;
    user-select: none;
}

.wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 500%;
    margin-left: -200%;
    height: 100%;
}

.controls {
    position: absolute;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    width: 100%;
    transition: opacity 0.2s;
}

.player {
    pointer-events: none;
}

@media (hover: hover) {
    .wrapper:not(.idle):hover .controls {
        opacity: 1;
        pointer-events: all;
    }
}
