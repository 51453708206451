.flexContainer {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
}

.flexContainer > * {
    flex: 1;
}

.horizontalContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 12px;
}

.currentWordPack {
    background: var(--transparent-gray);
    padding: 0 8px;
    border-radius: 8px;
    max-height: 32px;
    font-size: 12px;
    line-height: 32px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.buttonsContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
    margin-top: auto;
}
