.policyCont {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 10px;
    overflow: scroll;
    padding-bottom: 40px;
    text-align: justify;
}

.policyCont ul {
    list-style-type: none;
}

.title {
    font-size: 20px;
    font-weight: 600;
}

.info {
    padding: 20px 0;
}

.paragraph {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 15px 0;
}
