.infoCont {
    display: flex;
    justify-content: center;
    gap: 50px;
}

.infoCont span {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--text-light-gray-secondary);
    position: relative;
}

.infoCont span::after {
    content: '';
    width: 1px;
    height: 100%;
    background: var(--text-light-gray-secondary);
    position: absolute;
    right: -25px;
}

.infoCont span:last-child::after {
    display: none;
}
