.generateModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 680px;
    height: 440px;
    max-height: 440px;
    overflow: hidden;
    padding: 0 24px 24px;
    background: var(--white);
    border-radius: 16px;
    position: relative;
    min-width: 250px;
    max-width: 1090px;
}

.generateModal > * {
    width: 100%;
}

.modalInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
    height: 100%;
    overflow: visible;
    padding-top: 10px;
}

.modalInner > * {
    width: 100%;
}

.title {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    width: calc(100% + 48px);
    padding: 14px 24px;
    background-position: calc(50% - 50px) calc(50% + 6px);
}

.title.exercise {
    background-image: url('../../../../assets/svg/wizard-exercise-background.svg');
    background-position: calc(50% - 50px) calc(50% + 12px);
}

.title.words {
    background-image: url('../../../../assets/svg/wizard-words-background.svg');
}

.title.situation {
    background-image: url('../../../../assets/svg/wizard-situation-background.svg');
}

.title.track {
    background-image: url('../../../../assets/svg/wizard-track-background.svg');
}

.subtitle {
    width: 100%;
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    color: var(--text-light-gray-secondary);
    margin-top: 12px;
}
