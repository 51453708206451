.toolbar {
    display: flex;
    gap: 30px;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 20px);
    margin-left: 10px;
}

.container {
    display: flex;
    padding: 10px;
    gap: 10px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    flex: 1;
}

.aside,
.preview {
    height: 100%;
    min-height: 100%;
    border: 1px solid var(--border-light-gray);
    border-radius: 12px;
}

.aside {
    width: 30%;
    min-width: 30%;
    max-width: 30%;
    overflow: scroll;
}

.preview {
    width: calc(70% - 10px);
    min-width: calc(70% - 10px);
    max-width: calc(70% - 10px);
    max-height: 100%;
    overflow: hidden scroll;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    padding: 16px;
    padding-bottom: 0;
    border-radius: 12px;
    box-sizing: border-box;
    border: 1px solid var(--border-light-gray);
    background: var(--white);
    transition: all 0.2s;
    position: relative;
}

.emptyAlert {
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    width: 80%;
}

.emptyProgress {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    align-items: center;
}

.emptyAlert svg {
    transform: scale(0.5);
}

.emptyAlert svg path {
    fill: var(--text-light-gray);
}

.alertTitle,
.emptyProgressTitle,
.label {
    font-size: 14px;
    font-weight: 400;
    line-height: 43.58px;
    text-align: center;
    color: var(--text-light-gray-secondary);
}

.label span {
    font-size: 14px;
    font-weight: 400;
    line-height: 43.58px;
    color: var(--black);
    margin-left: 4px;
}

.alertTitle {
    margin-top: -30px;
}
