.writeCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 20px;
    height: 100%;
}

.sentences {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    margin: 12px;
}

.translationText {
    color: var(--text-light-gray-secondary);
    transition: filter 0.2s;
    user-select: none;
    text-align: center;
}

.translationText.active {
    color: var(--black);
    font-size: 22px;
}

.translationText.correct {
    color: var(--primary-green);
}

.exampleText {
    color: var(--black);
    transition: filter 0.2s;
    user-select: none;
    text-align: center;
    cursor: pointer;
    margin: 20px 0;
}

.exampleText.blur {
    filter: blur(5px);
}
