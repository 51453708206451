.navBar {
    display: flex;
    justify-content: center;
    width: 100%;
    background: var(--transparent-gray);
    border-radius: 32px;
    min-width: 180px;
    margin: 10px auto;
}

.item {
    flex: 1;
    padding: 8px 10px 10px 10px;
    border-radius: 32px;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s;
    text-transform: capitalize;
}

.item.active {
    background: var(--night-gray);
    color: var(--white);
}

@media (hover: hover) {
    .item:hover {
        background-color: var(--border-light-gray);
    }
    .item.active:hover {
        background: var(--night-gray);
        box-shadow: 0px 9px 19px 0px var(--black-transparent);
    }
}
