.container {
    display: flex;
    gap: 12px;
    overflow: hidden;
    flex-grow: 1;
    padding-bottom: 20px;
}

.hidden {
    display: none;
    height: 0;
    opacity: 0;
    pointer-events: none;
}
