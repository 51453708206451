.ponyLogo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ponyLogo.small {
    width: 108px;
    height: 108px;
    border-radius: 25.5px;
}

.ponyLogo.large {
    width: 144px;
    height: 144px;
    border-radius: 34px;
}

.ponyLogo.responsive {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: fit-content;
    max-width: 150px;
    margin: 0 auto;
}

.ponyLogoInner {
    width: 100%;
    background: transparent;
    filter: drop-shadow(0px 7px 16px var(--modal-overlay-background));
}

.ponyLogo.small .svg {
    transform: scale(0.8);
    margin: 0 auto;
}

.ponyLogo.large .svg {
    transform: scale(1);
    margin: 0 auto;
}

.ponyLogo.responsive .svg {
    margin: 0 auto;
    width: 100%;
    height: 100%;
}

@media (max-width: 1024px) {
    .ponyLogo.small {
        width: 108px;
        height: 108px;
        border-radius: 25.5px;
    }

    .ponyLogo.large {
        width: 144px;
        height: 144px;
        border-radius: 34px;
    }
}

@media (max-width: 768px) {
    .ponyLogo.small {
        width: 108px;
        height: 108px;
        border-radius: 25.5px;
    }

    .ponyLogo.large {
        width: 144px;
        height: 144px;
        border-radius: 34px;
    }
}

@media (max-width: 480px) {
    .ponyLogo.small {
        width: 88px;
        height: 88px;
        border-radius: 21px;
    }

    .ponyLogo.small .svg {
        transform: scale(0.7);
        margin: 0 auto;
    }

    .ponyLogo.large {
        width: 48px;
        height: 48px;
        border-radius: 12px;
    }
    .ponyLogo.large .svg {
        transform: scale(0.7);
    }
    .ponyLogo.responsive {
        min-width: 100px;
    }
}
