.toggleButtonCont {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.sectionTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    text-transform: capitalize;
}

.toggleButtonCont span {
    font-weight: 500;
}

.optionsContainer {
    display: flex;
    justify-content: center;
    width: min-content;
    background: var(--transparent-gray);
    border-radius: 32px;
}

.optionsContainer.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
}

.option {
    padding: 10px;
    border-radius: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 400;
    height: 38px;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s;
    text-transform: capitalize;
    user-select: none;
    font-weight: 400;
    white-space: nowrap;
    position: relative;
}

.tooltip {
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translate(-50%, 100%);
    text-transform: none;
    opacity: 0;
    pointer-events: none;
    white-space: nowrap;
    padding: 3px 6px;
    border-radius: 12px;
    transition: 0.2s opacity;
    background: var(--text-light-gray-secondary);
    color: var(--white);
    z-index: 10;
    box-shadow: 0px 0px 6px 3px var(--black-transparent);
}

.tooltip::before {
    content: '';
    width: 10px;
    height: 10px;
    transform: rotate(45deg) translate(-50%, -50%);
    background: var(--text-light-gray-secondary);
    position: absolute;
    top: 5px;
    left: 50%;
}

.option.round {
    width: 38px;
}

.option.active {
    background: var(--night-gray);
    color: var(--white);
}

.option.unavailable:not(.active) {
    color: var(--text-light-gray-secondary);
}

.option.disabled {
    color: var(--text-disabled);
    font-weight: 100;
    cursor: not-allowed;
}

@media (hover: hover) {
    .option:not(.disabled):hover {
        background-color: var(--border-light-gray);
    }
    .option.active:hover {
        background: var(--night-gray);
        box-shadow: 0px 9px 19px 0px var(--black-transparent);
    }
    .option.disabled:hover .tooltip {
        opacity: 1;
        transition-delay: 0.4s;
    }
}
