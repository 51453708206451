.statementsCont {
    position: relative;
    overflow: hidden scroll;
    flex: 1;
}

.statements {
    position: relative;
    display: flex;
    gap: 8px;
    flex-direction: column;
    width: calc(100% + 20px);
    margin-left: -10px;
    padding: 20px 30px 40px;
    background: transparent;
    font-size: 18px;
}

.fromMe,
.fromThem {
    max-width: 75%;
    position: relative;
    word-wrap: break-word;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    padding: 6px 12px;
    color: var(--black);
    box-shadow: 0px 1px 4px 0px var(--black-transparent);
    border-radius: 8px;
    transition: border 0.2s ease-in-out;
    border: 2px solid transparent;
}

.fromMe {
    align-self: flex-end;
    background: var(--primary-light-gray);
}

.fromMe.active,
.fromThem.active {
    border: 2px solid var(--dialogue-accent-gray);
}

.fromThem {
    align-items: flex-start;
    background: var(--white);
}

.buttons {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    top: 50%;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s;
}

.buttons.active {
    pointer-events: all;
    opacity: 1;
}

.fromMe .buttons {
    left: -10px;
    transform: translate(-100%, -50%);
}

.fromThem .buttons {
    right: -10px;
    transform: translate(100%, -50%);
}

.buttonIcon {
    height: 12px;
    width: 20px;
    min-width: 20px;
}

.buttonIcon path {
    stroke: var(--dark-gray);
}

.emptyAlert {
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    width: 80%;
    margin-top: auto;
    opacity: 0;
    animation: fadeIn 0.3s ease-in-out forwards;
}

.alertTitle {
    margin-top: 26px;
    font-size: 32px;
    font-weight: 400;
    line-height: 43.58px;
    text-align: center;
    color: var(--text-light-gray-secondary);
}

.speakerLabels {
    display: flex;
    justify-content: space-between;
}

.label {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: var(--black);
    margin: 0 18px -18px;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
