.playerContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
    padding: 20px 20px 0;
    width: 100%;
    position: relative;
}

.playerContainer > * {
    max-width: 100%;
}

.playerContainer.ponyPlayer > * {
    max-width: 50%;
    width: 50%;
}

.ponyPlayer {
    flex-direction: row;
    padding: 20px;
}

.title {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    min-height: 22px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.title.guest {
    font-size: var(--_24px);
    font-weight: 400;
    line-height: var(--_36px);
    min-height: var(--_36px);
}

.microButtons {
    position: absolute;
    top: 16px;
    right: 20px;
    display: flex;
    gap: 6px;
    z-index: 3;
}

.iconStroke path {
    stroke: var(--medium-gray);
    stroke-opacity: 1;
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden scroll;
    width: calc(100% + 40px);
    max-width: calc(100% + 40px);
    margin-left: -20px;
    padding: 20px;
}

.trackContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1;
}

.ponyPlayer .content .trackContainer {
    margin: 0 auto;
}

.transcriptionTitle {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
}

.transcriptionTitle p {
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--black);
    position: relative;
    text-align: center;
}

.line {
    height: 1px;
    background: var(--text-light-gray-secondary);
    flex-grow: 1;
}

.textContainer {
    z-index: 1;
    width: calc(100% + 28px);
    margin-left: -14px;
    background: var(--white);
}

.ponyPlayer .content .textContainer {
    border-radius: 12px;
    border: 1px solid var(--border-light-gray);
}

.trackErrorAlert {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 100%;
}

.trackErrorAlert p {
    font-size: 12px;
    color: var(--text-light-gray-secondary);
}

@keyframes scrollDown {
    from {
        margin-top: -75%;
    }
    to {
        margin-top: 0;
    }
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
