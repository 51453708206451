.button {
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    padding: 9px 18px;
    border-radius: 40px;
    background: var(--secondary-light-gray);
    color: var(--black);
    transition: all 0.2s;
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    width: min-content;
    white-space: nowrap;
    line-height: 20px;
    user-select: none;
    -webkit-user-drag: none;
    position: relative;
}

.white {
    background: var(--white);
    color: var(--black);
    box-shadow: 0px 1px 4px 0px var(--black-transparent);
}

.dark {
    background: var(--night-gray);
    color: var(--white);
}

.red {
    background: var(--primary-transparent-red);
    color: var(--primary-red);
}

.magenta {
    background: var(--purple);
    color: var(--white);
}

.green {
    background: var(--green-transparent);
    color: var(--green);
}

.button[disabled] {
    background: var(--secondary-light-gray);
    color: var(--text-light-gray);
    cursor: default;
}

.button[disabled] .icon {
    opacity: 0.5;
}

.button.dark[disabled] {
    background: var(--night-gray);
    color: var(--transparent-gray);
}

.button.red[disabled] {
    background: var(--primary-transparent-red);
    color: var(--text-light-gray);
}

@media (hover: hover) {
    .button:hover:not([disabled]) {
        background: var(--border-light-gray);
    }

    .button.white:hover:not([disabled]) {
        background: var(--white-hover);
    }

    .button.dark:hover:not([disabled]) {
        background: var(--night-gray);
        box-shadow: 0px 7px 16px 0px var(--black-transparent);
    }

    .button.red:hover:not([disabled]) {
        background: var(--primary-transparent-red);
    }

    .button.green:hover:not([disabled]) {
        background: var(--green-transparent-hover);
    }

    .button.magenta:hover:not([disabled]) {
        background: var(--purple);
    }

    .button:disabled:hover {
        animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(1px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-2px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(2px, 0, 0);
    }
}
