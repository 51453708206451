.chapterCard {
    min-height: 80px;
    max-height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    padding: 12px 16px;
    padding-right: 36px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    transition: background-color 0.1s;
    position: relative;
    cursor: pointer;
    user-select: none;
}

.chapterCard.active {
    background: var(--primary-light-gray);
}

.chapterCard.onboarding {
    transition: none !important;
}

.imageContainer {
    width: 100px;
    min-width: 100px;
    height: 56px;
    border-radius: 8px;
    transition: margin 0.2s cubic-bezier(0.42, 0, 0.58, 1);
    position: relative;
}

.imageContainer img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 8px;
    opacity: 0;
    animation: fadeIn 0.2s ease-in-out forwards;
}

.track .imageContainer {
    background: var(--purple);
}

.situation .imageContainer {
    background: var(--orange);
}

.exercise .imageContainer {
    background: var(--white);
}

.words .imageContainer {
    background: var(--white);
}

.cardInner {
    display: flex;
    flex-grow: 1;
    max-width: calc(100% - 116px);
    overflow: visible;
    flex-direction: column;
    gap: 4px;
    position: relative;
}

.title {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    white-space: pre-wrap;
    text-align: left;
    overflow: hidden;
}

.infoContainer {
    display: flex;
    gap: 4px;
    justify-content: flex-start;
    align-items: center;
    min-height: 12px;
    max-height: 12px;
}

.cardInner button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 2;
}

.labelCont {
    display: flex;
    gap: 3px;
    align-items: center;
}

.labelCont,
.label {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: var(--text-light-gray);
    text-transform: uppercase;
    white-space: nowrap;
}

.label::after {
    content: '∙';
    font-weight: 600;
    margin-left: 4px;
}

.label:last-child::after {
    display: none;
}

.label.exercise {
    color: var(--blue);
}

.label.words {
    color: var(--words-green);
}

.label.situation {
    color: var(--orange);
}

.label.track {
    color: var(--purple);
}

.modalityIcon svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
}

.friendly path {
    fill: var(--primary-green);
}

.neutral path {
    fill: var(--yellow);
}

.rude path {
    fill: var(--primary-red);
}

.imageContainer .folder,
.imageContainer .count {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.imageContainer .count {
    top: calc(50% + 5px);
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--words-green);
}

.progressContainer {
    width: 96px;
    min-height: 2px;
    display: flex;
    gap: 2px;
    flex-wrap: nowrap;
    position: absolute;
    bottom: 6px;
    left: 18px;
    transition: margin 0.2s cubic-bezier(0.42, 0, 0.58, 1);
}

.exerciseProgress,
.situationProgress,
.trackProgress {
    height: 2px;

    border-radius: 10px;
    overflow: hidden;
    position: relative;
    bottom: 0;
    left: 0;
    flex-grow: 1;
}

.exerciseProgress {
    background: var(--blue);
}

.situationProgress {
    background: var(--orange);
}

.trackProgress {
    background: var(--purple);
}

.progressFill {
    height: 100%;
    right: 0;
    position: absolute;
    background-color: var(--secondary-light-gray);
}

.actionButtonContainer {
    position: absolute;
    top: 12px;
    right: 16px;
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
}

.slideButton {
    height: 48px;
    width: 48px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--green);
    position: relative;
    top: 4px;
}

.slideButton svg {
    transform: scale(1.5);
}

.slideButton svg path {
    stroke: var(--white);
    stroke-opacity: 1;
}

.slideButton.disabled {
    filter: grayscale(1);
    cursor: not-allowed;
}

.status.unfinished,
.status.awaitingEvaluation,
.status.done {
    white-space: nowrap;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 12px;
    text-align: center;
    padding: 1px 2px;
    border-radius: 4px;
    width: fit-content;
}

.status.unfinished {
    color: var(--purple);
    background: var(--white);
    border: 1px solid var(--purple-transparent);
}

.status.awaitingEvaluation {
    color: var(--white);
    background: var(--purple);
    border: 1px solid var(--purple-transparent);
}

.status.done {
    color: var(--white);
    background: var(--words-green);
    border: 1px solid var(--words-green-transparent);
}

.dot {
    width: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: var(--text-light-gray);
    text-transform: uppercase;
    font-weight: 600;
}

@media (hover: hover) {
    .chapterCard:not(.onboarding):not(.active):hover {
        background: var(--white-hover);
    }

    .chapterCard:not(.onboarding).active:hover {
        background: var(--secondary-light-gray);
    }

    .chapterCard:not(.onboarding).active:not(.teacher):hover
        .actionButtonContainer {
        pointer-events: all;
        opacity: 1;
    }
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
