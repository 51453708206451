.wordsSearchFilter {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.horizontalContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 12px;
}

.wordsList {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 6px;
    width: 100%;
    height: 32px;
    border-radius: 8px;
}
