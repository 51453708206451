.rangeContainer {
    width: 100%;
    height: 38px;
    padding: 10px 2px;
    display: flex;
    border-radius: 32px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    margin-top: 10px;
}

.range {
    -webkit-appearance: none;
    margin: 0 auto;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background-color: var(--purple);
    cursor: pointer;
}

.range::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 19px;
    height: 19px;
    background-color: var(--white);
    box-shadow: 0px 0px 3px 1px var(--black-transparent);
    border-radius: 50%;
    cursor: pointer;
}

.range::-moz-range-thumb {
    width: 24px;
    height: 24px;
    background-color: var(--purple);
    border-radius: 50%;
}

.range::-ms-thumb {
    width: 24px;
    height: 24px;
    background-color: var(--purple);
    border-radius: 50%;
}

.range::-moz-range-track {
    background-color: var(--purple);
    height: 3px;
}

.range::-ms-track {
    background-color: var(--purple);
    height: 3px;
}

.range::-ms-fill-lower {
    background-color: var(--purple);
}

.range::-ms-fill-upper {
    background-color: var(--purple);
}

.label {
    position: absolute;
    bottom: -10px;
    font-family: Noto Sans;
    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
    color: var(--text-light-gray-secondary);
}

.label:first-child {
    left: 10px;
}

.label:last-child {
    right: 10px;
}

.currentValueContainer {
    position: absolute;
    width: 95%;
    height: 20px;
    pointer-events: none;
}

.currentValue {
    position: absolute;
    top: -25px;
    font-family: Noto Sans;
    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
    color: var(--text-light-gray-secondary);
    transform: translateX(-50%);
}
