.categoryTitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    padding: 0 20px;
    letter-spacing: 0px;
    text-align: center;
    text-transform: capitalize;
    cursor: pointer;
    position: relative;
}

.categoryTitle span {
    font-size: 18px;
    font-weight: 500;
}

.controls {
    display: flex;
    gap: 10px;
    position: absolute;
    right: 0;
}

.editIcon {
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s;
}

.editIcon path {
    fill: var(--dark-gray);
}

.closeIcon {
    min-width: 16px;
}

.closeIcon path {
    fill: var(--white);
}

.titleEditor {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    min-height: 44px;
    gap: 10px;
}

@media (hover: hover) {
    .categoryTitle:hover .editIcon {
        opacity: 1;
    }
}
