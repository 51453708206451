.container {
    max-width: 60%;
    min-width: 60%;
    max-height: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
    position: relative;
    box-sizing: border-box;
}

.studentButtons {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.student,
.selectStudent,
.emptyAlert {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
}

.container > button:last-child {
    margin-right: 30px;
}

.emptyAlert {
    color: var(--text-light-gray-secondary);
    margin: 0 auto;
}

.selectStudent {
    color: var(--text-light-gray-secondary);
}

.student {
    padding: 0px;
    height: 32px;
    border-radius: 8px;
    color: var(--black);
    white-space: nowrap;
    font-weight: 500;
    line-height: 32px;
    user-select: none;
}

.planIcon {
    min-width: 16px;
    width: 16px;
    margin-top: -1px;
}

.planIcon path {
    stroke: var(--white);
}

.currentStudent {
    margin-left: 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--purple);
}
