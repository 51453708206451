.costBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
    padding: 0 var(--_24px);
    margin: 100px 0;
    position: relative;
    overflow: visible;
}

.title,
.title span {
    font-size: var(--_36px);
    font-weight: 500;
    line-height: var(--_42px);
    letter-spacing: -0.02em;
    margin-bottom: var(--_12px);
}

.title span {
    color: var(--purple);
}

.subtitle,
.subtitle span {
    font-size: var(--_16px);
    font-weight: 400;
    line-height: var(--_22px);
    letter-spacing: -0.02em;
    color: #1c1c1e80;
    max-width: 45vw;
    margin-bottom: var(--_40px);
}

.subtitle span {
    color: var(--black);
}

.cardsBlock {
    padding: var(--_24px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--_48px);
    position: relative;
    width: 100%;
    border-radius: var(--_16px);
    overflow: hidden;
    background: var(--white-pressed);
}

.cardsContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--_24px);
}

.purpleCircleLeft,
.purpleCircleRight {
    position: absolute;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background-color: var(--purple);
    z-index: 0;
    pointer-events: none;
    opacity: 0.3;
    filter: blur(500px);
}

.purpleCircleRight {
    bottom: 0px;
    right: 0px;
    transform: translate(110%, 80%);
}

.purpleCircleLeft {
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
}

.joinButton {
    border-radius: var(--_12px);
    padding: var(--_16px) var(--_20px);
    font-size: var(--_16px);
    font-family: Noto Sans;
    font-weight: 400;
    line-height: var(--_16px);
    max-height: unset;
    height: unset;
    width: 30%;
}

.costBlock .logoEN {
    width: 24vw;
    height: 24vw;
    top: unset;
    bottom: 2%;
    left: 7%;
    z-index: -1;
}

.costBlock .logoDE {
    width: 17vw;
    height: 17vw;
    top: -20%;
    left: unset;
    right: 7%;
    z-index: -1;
}

.costBlock .logoPT {
    width: 13vw;
    height: 13vw;
    top: 8%;
    left: 5%;
    z-index: -1;
}

.costBlock .logoFR {
    width: 22vw;
    height: 22vw;
    top: unset;
    left: unset;
    bottom: -5%;
    right: -5%;
    z-index: -1;
    transform: rotate(-45deg);
}
