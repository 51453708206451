.box {
    display: flex;
    gap: 12px;
    align-items: flex-end;
    margin-bottom: 20px;
}

.inputCont {
    flex-basis: 50%;
}

.loadingText {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-top: 17px;
}
