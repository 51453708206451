.buttons {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.addButtonContainer {
    width: 100%;
    flex-grow: 1;
    min-height: 296px;
    border: 1px dashed var(--text-light-gray-secondary);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
