.tree {
    position: relative;
    padding: 0px 15px;
    padding-right: 47px;
    height: 32px;
    display: flex;
    color: var(--text-light-gray-secondary);
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 400;
    cursor: pointer;
    outline: none;
    width: 100%;
    align-items: center;
    transition: background-color 0.2s;
}

.tree.white {
    background: var(--white);
}

.tree.outlined {
    background: var(--white);
    height: 42px;
    border: 0.3px solid var(--border-light-gray);
}

.tree.error {
    background: var(--white);
    height: 42px;
    border: 0.3px solid var(--primary-red);
}

.tree.errorSmall {
    background: var(--white);
    height: 32px;
    border: 0.3px solid var(--primary-red);
}

.tree.grey {
    background: var(--primary-light-gray);
}

.tree.narrow {
    padding: 0 24px 0 6px;
}

.tree .resetButton {
    width: 20px;
    position: relative;
    left: 16px;
}

.tree.narrow .resetButton,
.tree.narrow .resetButton button {
    width: 12px;
    height: 12px;
    border-radius: 3px;
}

.tree.narrow .resetButton button {
    position: relative;
    left: -12px;
}

.tree > * {
    width: 100%;
    user-select: none;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    text-align: left;
}

.tree.disabled {
    cursor: not-allowed;
}

.arrowDown {
    content: url('/src/assets/svg/lessons-arrow.svg');
    position: absolute;
    width: 12px;
    height: 12px;
    top: 50%;
    transform: rotate(180deg) translateY(50%);
    right: 13px;
}

.arrowUp {
    content: url('/src/assets/svg/lessons-arrow.svg');
    position: absolute;
    width: 12px;
    height: 12px;
    top: 50%;
    transform: translateY(-50%);
    right: 13px;
}

.arrowRight {
    content: url('/src/assets/svg/lessons-arrow.svg');
    position: absolute;
    width: 12px;
    height: 12px;
    top: 50%;
    transform: rotate(90deg) translateY(-50%);
    right: 13px;
}

.tree.narrow .arrowDown,
.tree.narrow .arrowUp {
    right: 6px;
}

.options {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: max-content;
    max-height: 400px;
    overflow: hidden scroll;
    color: var(--text-light-gray-secondary);
    background: var(--white);
    z-index: 100;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 8px;
    padding: 5px 0;
    box-shadow: 0px 2px 12px 0px var(--black-transparent);
}

.options.fixed {
    position: fixed;
}

.options.down {
    top: calc(100% + 5px);
    border-top: none;
}

.options.up {
    bottom: calc(100% + 5px);
    border-bottom: none;
}

.options::-webkit-scrollbar {
    width: 0;
}

.chapterContainer,
.topic {
    display: flex;
    align-items: center;
    width: calc(100% - 10px);
    font-size: 12px;
    padding: 0 6px;
    margin: 0 auto;
    border-radius: 8px;
    border: none;
    min-height: 35px;
    cursor: pointer;
    position: relative;
}

.chapterContainer {
    gap: 5px;
}

.chapterContainer .arrowDown,
.chapterContainer .arrowRight {
    position: static;
    transform: none;
}

.chapterContainer .arrowDown {
    transform: rotate(180deg);
}

.chapterContainer .arrowRight {
    transform: rotate(90deg);
}

.topic.active {
    font-weight: 700;
}

.topicContainer {
    display: flex;
    flex-direction: column;
    margin: 5px 0px 0px 30px;
    gap: 5px;
}

.value {
    padding-right: 0px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--text-light-gray-secondary);
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    text-align: left;
}

.value.active {
    color: var(--black);
}

.tree.disabled .value {
    color: var(--text-light-gray);
}

.tooltip {
    opacity: 0;
    pointer-events: none;
    top: -45px;
    left: 20px;
    width: min-content;
    transition: opacity 0.1s;
}

.emptyAlert {
    display: flex;
    font-size: 12px;
    line-height: 24px;
    padding: 0 12px;
    border-radius: 8px;
    border: none;
    min-height: 24px;
    cursor: pointer;
    white-space: nowrap;
    text-align: left;
    color: var(--text-light-gray);
}

@media (hover: hover) {
    .chapterContainer:hover:not(.disabled):not(.active),
    .topic:hover {
        background: var(--primary-light-gray);
    }

    .tree.white:hover:not(.expanded):not(.disabled) {
        background: var(--white-hover);
    }

    .tree.outlined:hover:not(.expanded):not(.disabled) {
        background: var(--white-hover);
    }

    .tree.grey:hover:not(.expanded):not(.disabled) {
        background: var(--primary-light-gray-hover);
    }

    .tree.disabled:hover .tooltip {
        opacity: 1;
    }
}
