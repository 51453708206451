.searchBar {
    display: flex;
    align-items: flex-end;
    gap: 6px;
}

.searchBar.blacklist {
    margin-top: 17px;
}

.total {
    padding: 4px 16px;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    color: var(--text-light-gray-secondary);
}
