.teacherCard {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    border: 1px solid var(--border-light-gray);
    border-radius: 12px;
    transition: transform 0.2s;
    cursor: pointer;
}

.teacherCard * {
    font-family: Noto Sans;
}

.teacherName {
    font-family: Noto Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
}

.infoContainer {
    display: flex;
    gap: 4px;
    justify-content: flex-start;
    align-items: center;
    min-height: 10px;
}

.label {
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: var(--text-light-gray-secondary);
    position: relative;
    text-transform: uppercase;
}

.label::after {
    content: '∙';
    font-weight: 600;
    margin-left: 4px;
}

.label:last-child::after {
    display: none;
}

.controls {
    display: flex;
    gap: 8px;
    margin-top: 8px;
}

@media (hover: hover) {
    .teacherCard:hover {
        transform: scale(1.03);
    }
}
