.inputCont {
    position: relative;
}

.input {
    width: 100%;
    padding: 8px 24px 8px 16px;
    margin: 0;
    background: var(--white);
    border: 1px solid var(--transparent-gray);
    height: 30px;
    max-height: 42px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex: 1 1 100%;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    outline: none;
    flex-shrink: 0;
    flex-grow: 0;
}

.input.blur {
    background: var(--transparent-gray);
    border: 1px solid var(--transparent-gray);
    color: var(--black);
    transition: all 0.2s;
    cursor: pointer;
}

.deleteIcon {
    opacity: 0;
    transition: opacity 0.2s;
    transform: translateY(-50%);
}

@media (hover: hover) {
    .input.blur:hover {
        background-color: var(--secondary-light-gray);
        border: 1px solid var(--secondary-light-gray);
    }
    .inputCont:hover .deleteIcon.focus {
        opacity: 1;
    }
}
