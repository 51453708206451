.overlay,
.transparentOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--modal-overlay-background);
    backdrop-filter: blur(0.5px);
    z-index: 10;
    opacity: 0;
}

.overlay {
    animation: fadeIn 0.2s ease-in-out forwards;
    transition: opacity 0.5s;
}

.transparentOverlay {
    animation: none;
    opacity: 0;
}

.messageBubble {
    position: fixed;
    padding: var(--_12px);
    border-radius: var(--_6px);
    background: var(--onboarding-bubble-background);
    border: 1px solid var(--transparent-gray);
    border-radius: var(--_12px);
    backdrop-filter: blur(42px);
    width: fit-content;
    max-width: 30vw;
    z-index: 11;
    transition: opacity 0.5s;
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out forwards;
    animation-delay: 0.5s;
    height: fit-content;
    cursor: default;
}

.transparentOverlay ~ .messageBubble {
    background-color: var(--black-transparent-secondary);
    backdrop-filter: blur(5px);
    z-index: 11;
}

.transparentOverlay ~ .border {
    background-color: var(--black-transparent-secondary);
    backdrop-filter: blur(5px);
    z-index: 11;
}

.title {
    font-size: var(--_18px);
    font-weight: 500;
    line-height: var(--_20px);
    text-align: left;
    color: var(--white);
    white-space: pre-line;
}

.subtitle,
.subtitle b {
    font-size: var(--_16px);
    line-height: var(--_18px);
    font-weight: 400;
    text-align: left;
    color: var(--white);
    white-space: pre-line;
}

.subtitle:not(:first-child) {
    margin-top: var(--_8px);
}

.subtitle:not(:last-child) {
    margin-bottom: var(--_16px);
}

.messageBubble button:nth-child(2) {
    margin-top: var(--_16px);
}

.messageBubble button {
    width: 100%;
    margin-top: var(--_24px);
    font-size: var(--_16px);
    line-height: var(--_20px);
    height: var(--_32px);
    min-height: var(--_32px);
}

.arrowContainer {
    position: fixed;
    transform: translate(-50%, -50%);
    z-index: 999;
    pointer-events: none;
}

.arrow {
    position: relative;
}

.arrow path {
    fill: var(--white);
}

.arrowContainer.right .arrow {
    animation: moveRight 1s ease-in-out infinite;
}

.arrowContainer.left .arrow {
    transform: rotate(180deg);
    animation: moveLeft 1s ease-in-out infinite;
}

.arrowContainer.leftClose .arrow {
    transform: rotate(180deg);
    animation: moveLeftClose 1s ease-in-out infinite;
}

.arrowContainer.top .arrow {
    transform: rotate(-90deg);
    animation: moveTop 1.25s ease-in-out infinite;
}

.arrowContainer.bottom .arrow {
    transform: rotate(90deg);
    animation: moveBottom 1.25s ease-in-out infinite;
}

.border {
    box-shadow: 0 0 0 12px var(--transparent-gray);
    position: fixed;
    z-index: 19;
    opacity: 0;
    animation: fadeIn 0.2s ease-in-out forwards;
    transition: opacity 0.5s;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

@keyframes moveRight {
    0% {
        right: -30px;
    }
    50% {
        right: -36px;
    }
    100% {
        right: -30px;
    }
}

@keyframes moveLeft {
    0% {
        left: -30px;
    }
    50% {
        left: -36px;
    }
    100% {
        left: -30px;
    }
}

@keyframes moveLeftClose {
    0% {
        left: -18px;
    }
    50% {
        left: -20px;
    }
    100% {
        left: -18px;
    }
}

@keyframes moveTop {
    0% {
        top: -12px;
    }
    50% {
        top: -14px;
    }
    100% {
        top: -12px;
    }
}

@keyframes moveBottom {
    0% {
        bottom: -12px;
    }
    50% {
        bottom: -14px;
    }
    100% {
        bottom: -12px;
    }
}
