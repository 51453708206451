.manageWordsModal {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 500px;
    padding: 0;

    background: var(--white);
    border-radius: 16px;
    position: relative;
    min-width: 250px;
    max-height: 80vh;
    overflow: auto;

    max-width: 1090px;
}

.title {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    padding: 14px 24px 0;
}

.subtitle {
    width: 100%;
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    color: var(--text-light-gray-secondary);
    padding: 0px 24px 6px;
}

.wordsList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
    padding: 12px 24px 24px;
}

.horizontalContainer {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    width: 100%;
    transition: all 0.2s;
}

.horizontalContainer.disabled {
    opacity: 0.3;
    pointer-events: none;
    filter: grayscale(1);
}

.currentWordPack {
    background: var(--primary-light-gray);
    padding: 0 16px;
    border-radius: 8px;
    max-height: 32px;
    font-size: 12px;
    line-height: 32px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.checkbox {
    margin-bottom: 9.5px;
    justify-content: flex-end;
    flex-direction: row-reverse;
}

.select {
    flex-grow: 1;
}

.modalButton {
    width: 100%;
    max-width: 230px;
}
