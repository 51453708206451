.intro {
    background: var(--white);
    border-radius: 16px;
    position: relative;
    min-width: 250px;
    max-height: 80vh;
    overflow: auto;

    padding: 20px;
    max-width: 640px;
    opacity: 0;
    transition: opacity 0.5s;
    animation: fadeIn 0.2s ease-in-out forwards;
    animation-delay: 0.5s;
    user-select: none;
}

.title {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
}

.subtitle {
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    text-align: left;
    color: var(--text-light-gray-secondary);
    margin-top: 12px;
    white-space: pre-line;
    text-align: justify;
}

.intro > button {
    width: 100%;
    margin-top: 24px;
    font-size: 15px;
    line-height: 18px;
    height: 38px;
    min-height: 38px;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
