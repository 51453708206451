.lessonCard {
    margin-bottom: 6px;
    user-select: none;
}

.lessonCard.unfinished .cardInner {
    background: var(--unfinished-status-color);
    border: 1px solid var(--purple-transparent);
}

.lessonCard.done .cardInner {
    background: var(--light-green);
    border: 1px solid var(--words-green-transparent);
}

.cardInner {
    display: flex;
    flex-direction: column;
    padding: 12px;
    box-shadow: 0px 0px 7px 0px var(--black-transparent);
    border-radius: 12px;
    background: var(--white);
    border: 1px solid var(--white);
    cursor: pointer;
    min-height: 60px;
    transition:
        transform 0.2s,
        background-color 0.2s,
        border 0.2s;
    position: relative;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 32px;
}

.title,
.title span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
}

.title span {
    font-weight: 300;
    color: var(--text-light-gray);
}

.titleContainer button {
    box-shadow: 0px 1px 4px 0px var(--black-transparent);
}

.infoContainer {
    display: flex;
    gap: 4px;
    justify-content: flex-start;
    align-items: center;
    min-height: 10px;
    margin-top: 6px;
}

.chapters {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin: 6px;
    margin-top: 16px;
}

.statItem,
.emptyLessonAlert {
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: var(--text-light-gray-secondary);
    position: relative;
}

.statItem::after {
    content: '∙';
    font-weight: 600;
    margin-left: 4px;
}

.statItem:last-child::after {
    content: '';
}

.emptyLessonAlert {
    margin: auto;
}

.chapters * {
    color: var(--black);
}

.controlButtons {
    display: none;
    align-items: end;
    gap: 8px;
    margin-left: 4px;
}

.editor > input {
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    height: 20px;
    line-height: 20px;
    text-align: left;
    border: none;
    padding: 0;
    border-radius: 0;
}

.selectContainer {
    display: flex;
    gap: 8px;
    margin-top: 8px;
}

.notification {
    min-width: 16px;
    height: 16px;
    padding: 0 3px;
    border-radius: 8px;
    background: var(--purple);
    color: var(--white);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    margin-left: auto;
    margin-right: 6px;
}

.collapsedChapters {
    display: flex;
    flex-wrap: nowrap;
    gap: 9px;
    margin-top: 6px;
}

.iconContainer {
    display: flex;
    align-items: center;
    gap: 3px;
}

.iconContainer .icon {
    height: 14px;
    width: 14px;
}

.iconContainer p {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
}

.iconContainer p.exercise {
    color: var(--blue);
}

.iconContainer p.situation {
    color: var(--orange);
}

.iconContainer p.words {
    color: var(--words-green);
}

.iconContainer p.track {
    color: var(--purple);
}

@media (hover: hover) {
    .lessonCard:not(.active):hover .cardInner .controlButtons {
        display: flex;
    }
}
