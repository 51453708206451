.errorMessage {
    width: min-content;
    min-width: 100%;
    height: 100%;
    position: absolute;
    padding: 3px 9px;
    border-radius: 40px;
    transition: opacity 0.2s;
    opacity: 0;
    pointer-events: none;
    z-index: 10;
    background: var(--primary-red);
    text-wrap: nowrap;
    color: var(--white);

    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorMessage.visible {
    opacity: 1;
}
