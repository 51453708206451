.errorBoundary {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 12px;
}

.errorBoundary > * {
    font-family: Noto Sans;
}

.details {
    margin-top: 40px;
    white-space: prewrap;
    width: 40%;
    text-align: center;
}

.details p {
    text-align: left;
}
