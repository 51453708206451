.priorityTracksModal {
    width: 90vw;
    height: 90vh;
    padding: 12px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 12px;
    background: var(--white);
    border-radius: 16px;
    position: relative;
    min-width: 250px;
    max-height: 80vh;
    max-width: 1090px;
}

.tracksList {
    flex-grow: 1;
    width: calc(100% + 24px);
    margin-left: -12px;
    padding: 0 12px;
    overflow: hidden scroll;
    position: relative;
}

.listInner {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex-grow: 1;
}

.emptyAlert {
    color: var(--text-light-gray-secondary);
    font-size: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
