.editorCont {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.option {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    width: 100%;
    border: 0.3px solid var(--border-light-gray);
    border-radius: 8px;
    padding: 3px 32px 3px 8px;
    overflow-y: hidden;
    transition: background-color 0.1s;
    resize: none;
    outline: none;
    font-weight: 400;
    font-size: 12px;
    min-height: 25px;
    line-height: 19px;
    background: var(--primary-light-gray);
}

.option.correct {
    background: var(--green-transparent);
    border-color: var(--text-light-green);
}

.option:focus {
    background: var(--white);
}

.option svg {
    height: 12px;
}
