.textDisplay {
    border-radius: 20px;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    width: 100%;
    color: var(--black);
    padding: 5px 10px;
    overflow: hidden scroll;
    position: relative;
}

.dot {
    width: 12px;
    height: 12px;
    right: 0px;
    top: 0;
    border-radius: 50%;
    transform: translate(calc(-23px - -50%), -50%);
    position: fixed;
    background: var(--dark-gray);
    transition: all 0.2s;
}
