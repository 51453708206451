.profile {
    border-radius: 24px;
    background: var(--light-gray);
    border: 1px solid var(--primary-light-gray);
    width: 40%;
    min-width: 350px;
    height: fit-content;
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding: 60px 30px;
    position: relative;
}

.title {
    font-family: Noto Sans;
    font-size: 30px;
    text-align: center;
}

.horizontalCont,
.infoCont {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.infoCont > div {
    width: 50%;
}

.infoCont input {
    background: var(--light-gray);
    cursor: not-allowed;
}

.logoutIcon path {
    fill: var(--primary-red);
}
