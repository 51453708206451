.exercisePreview {
    width: 97vw;
    max-width: 97vw;
    height: 97vh;
    max-height: 97vh;
    padding: 25px;
    background: var(--white);
    border-radius: 16px;
    position: relative;
    overflow: auto;
}
