.landingLoaderOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(2px);
    background: var(--white-transparent);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

.landingLoaderCont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: fadeIn 0.2s ease-in-out forwards;
}

.landingLoader {
    width: 160px;
    height: 160px;
    position: relative;
    z-index: 5;
}

.loader {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 160px;
    height: 160px;
}

.loader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: inherit;
    height: inherit;
    border: 1px solid var(--border-light-gray);
    border-width: 10px;
    border-radius: 50%;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--border-light-gray) transparent transparent transparent;
    background: transparent;
}

.loader div:nth-child(1) {
    animation-delay: -0.45s;
}

.loader div:nth-child(2) {
    animation-delay: -0.3s;
}

.loader div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
