.header {
    width: 100%;
    max-width: 100vw;
    padding: 10px 20px;
    height: 56px;
    max-height: 56px;
    z-index: 1;
}

.headerInner {
    margin: 0 auto;
    display: flex;
    align-items: center;
    max-width: 1440px;
    transition: all 0.2s;
}

.currentLanguage {
    background: var(--night-gray);
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    border-radius: 50%;
}

.profileBtns {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-left: auto;
}

.teacherHeader {
    width: 100%;
    max-width: 100vw;
    height: 56px;
    padding: 10px 20px;
}

.teacherHeaderInner {
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 100%;
    transition: all 0.2s;
}

.leftPart {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: auto;
}

.teacherHeaderInner > *:first-child {
    margin-right: 8px;
}

.teacherLink {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    position: relative;
}

.teacherLink button {
    padding: 5px 12px;
    font-size: 16px;
}

.teacherLogo {
    width: 32px;
    height: 32px;
    margin-top: -4.5px;
}

.teacherLogoText {
    margin-top: 1px;
    transform: scale(0.8);
}

.teacherProfileBtns {
    display: flex;
    align-items: center;
    align-self: flex-end;
    gap: 8px;
}

.currentLanguage {
    background: var(--night-gray);
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
}

.notification {
    min-width: 16px;
    padding: 0 3px;
    border-radius: 8px;
    background: var(--purple);
    color: var(--white);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    position: absolute;
    right: 7px;
    top: -5px;
    transform: translateX(100%);
    z-index: 1;
}

.fullScreenButton {
    width: 32px;
    min-width: 32px;
    padding: 0;
    height: 32px;
    max-height: 32px;
    border-radius: 50%;
    background: var(--primary-light-gray);
}

.fullScreenButton svg {
    height: 18px;
    width: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fullScreenButton svg path {
    fill: var(--text-light-gray);
}

@media (hover: hover) {
    .teacherLink:not(.active):not(:first-child):hover::after {
        opacity: 1;
        width: 100%;
    }
}
