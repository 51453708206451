.audioCont {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.button {
    width: 56px;
    height: 56px;
    min-height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--black);
    margin-top: 16px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
}

.button.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.button.active {
    background: var(--transparent-gray);
}

.button.active path {
    stroke: var(--black);
}
