.buttonsContainer {
    width: calc(100% - 32px);
    margin-left: 16px;
}

.emptyLesson {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    height: fit-content;
    box-shadow: 0px 0px 7px 0px var(--black-transparent);
    border-radius: 12px;
    background: var(--white);
}

.emptyLesson > input {
    font-size: 16px;
    font-weight: 400;
    height: 20px;
    line-height: 20px;
    text-align: left;
    border: none;
    padding: 0;
    border-radius: 0;
    margin-top: 1px;
}

.addButton {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--dark-gray);
    background: var(--light-gray);
    padding: 6px;
    cursor: pointer;
    position: relative;
    /* TODO fix transition bug while onboarding @saratovkin */
    /* transition: background-color 0.2s; */
    border-radius: 8px;
}

.buttons {
    margin-top: 11px;
    display: flex;
    justify-content: flex-start;
    gap: 6px;
}

.buttons button {
    padding: 6px 10px;
}

.buttons button:last-child {
    background: transparent;
}

.buttons button:last-child path {
    fill: var(--black);
}

.buttonsContainer.blocked .addButton {
    cursor: default;
    color: var(--text-light-gray-secondary);
}

.selectContainer {
    display: flex;
    gap: 8px;
    margin-top: 11px;
    justify-content: space-between;
}

.selectContainer > * {
    width: calc(33% - 3px);
    max-width: calc(33% - 3px);
}

@media (hover: hover) {
    .addButton:hover {
        background: var(--secondary-light-gray);
    }

    .buttonsContainer.blocked:hover .addButton .tooltip {
        opacity: 1;
    }
}
