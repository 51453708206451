.podcastCard {
    width: 100%;
    height: 180px;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;
    border: 1px solid var(--secondary-light-gray);
    border-radius: 20px;
    padding: 20px;
}

.imgCont {
    width: 150px;
    height: 150px;
    overflow: hidden;
    min-width: 150px;
    border-radius: 50%;
    border: 1px solid var(--secondary-light-gray);
}

.podcastImg {
    width: 150px;
    height: 150px;
    object-fit: cover;
    transition: transform 0.2s;
}

.imgCont:hover .podcastImg {
    transform: scale(1.05);
}

.podcastInfo {
    display: flex;
    flex-direction: column;
    height: 160px;
    justify-content: space-between;
    width: calc(100% - 140px);
}

.title {
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    width: fit-content;
    position: relative;
}

.title::after {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    height: 2px;
    background: var(--purple);
    transition: all 0.3s;
    opacity: 0;
    width: 0;
}

.description {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    -webkit-box-orient: vertical;
    min-height: 60px;
}

.verticalBox {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

@media (hover: hover) {
    .trackBtn:hover {
        background: var(--border-light-gray);
    }
    .title:hover::after {
        opacity: 0.5;
        width: 100%;
    }
}
