.sentenceCont {
    cursor: grab;
    display: flex;
    gap: 10px;
    width: 100%;
    background-color: transparent !important;
    margin-bottom: 10px;
}

.sentenceCont > div {
    flex: 1 1 auto;
    width: 100%;
}

.sentence {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    width: 100%;
    border: 0.3px solid var(--border-light-gray);
    border-radius: 8px;
    padding: 3px 32px 3px 8px;
    overflow-y: hidden;
    transition: background-color 0.1s;
    resize: none;
    outline: none;
    font-weight: 400;
    font-size: 12px;
    min-height: 25px;
    line-height: 19px;
    background: var(--primary-light-gray);
}

.sentence:focus {
    background: var(--white);
}

.sentence svg {
    height: 12px;
}
