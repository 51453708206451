.questionsLabel {
    position: relative;
    text-align: center;
}

.questionsLabel:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0px;
    width: 100%;
    height: 1px;
    background: var(--text-light-gray-secondary);
    z-index: 0;
}

.questionsLabel span {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    font-size: 12px;
    line-height: 16px;
    color: var(--black);
    background: var(--white);
    z-index: 1;
}
