.modalInner {
    display: flex;
    width: 400px;
    min-height: 350px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
}

.title {
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    width: -moz-fit-content;
    width: fit-content;
}

.horizontalContainer {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 20% 80%;
    gap: 10px;
}

.description,
.error {
    font-size: 12px;
    color: var(--text-light-gray-secondary);
    white-space: nowrap;
    margin: 12px 20px;
    max-width: 90%;
    white-space: wrap;
}

.error {
    color: var(--primary-red);
}
