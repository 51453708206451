.sectionsContainer {
    margin: auto;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 12px 24px;
}

.sectionCard {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    border-radius: 12px;
    box-shadow: 0px 0px 7px 0px var(--black-transparent);
    padding: 16px 24px;
    transition: all 0.2s;
    cursor: pointer;
    position: relative;
}

.sectionCard svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0.7;
    filter: saturate(0);
    transition: all 0.4s;
}

.sectionCard p {
    font-weight: 300;
    font-size: 16px;
    z-index: 3;
    position: relative;
    padding: 6px 18px;
    border-radius: 18px;
    background: var(--text-light-gray-secondary);
    color: var(--white);
    text-transform: capitalize;
    transform: scale(1.2);
}

.sectionCard span {
    font-size: 12px;
    line-height: 16px;
    color: var(--text-light-gray-secondary);
    text-align: center;
}

@media (hover: hover) {
    .sectionCard:hover {
        transform: scale(1.05);
    }

    .sectionCard:hover svg {
        opacity: 1;
        filter: saturate(1);
    }
}
