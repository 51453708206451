.searchBarCont {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.searchBar {
    display: flex;
    column-gap: 10px;
    align-items: flex-end;
    flex-wrap: nowrap;
}

.subBar {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    align-items: center;
    width: 100%;
}

.subBar p {
    width: 30%;
    color: var(--text-light-gray-secondary);
}

.subBar span {
    color: var(--text-light-gray-secondary);
}
