.serviceCont {
    width: 100%;
    box-shadow: 0px 0px 7px 0px var(--black-transparent);
    border-radius: 28px;
}

.serviceState {
    width: 100%;
    height: 50px;
    margin: 0 5px;
    padding: 6px 12px;
    display: flex;
    gap: 3px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-overflow: ellipsis;
    position: relative;
}

.serviceName {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
}

.serviceName.loading {
    opacity: 0.2;
}

.status {
    margin-right: auto;
    line-height: 16px;
}

.serviceState.errored .status {
    color: var(--primary-red);
}

.serviceState.healthy .status {
    color: var(--green);
}

.loaderCont {
    height: 20px;
    width: 20px;
    position: relative;
    margin-right: 20px;
}

.childService {
    width: 100%;
    height: 25px;
    margin: 6px 12px;
    padding: 6px 12px;
    display: flex;
    gap: 3px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 28px;
    transition: all 0.2s;
    text-overflow: ellipsis;
    position: relative;
}

.childService .serviceName {
    font-weight: 400;
}

.childService.errored .status {
    color: var(--primary-red);
}

.childService.healthy .status {
    color: var(--green);
}
