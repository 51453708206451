.createExercise {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 680px;
    height: 440px;
    overflow: hidden;
    padding: 0 24px 24px;
    background: var(--white);
    border-radius: 16px;
    position: relative;
    min-width: 250px;
    max-height: 80vh;

    max-width: 1090px;
}

.createExercise > * {
    width: 100%;
}

.modalInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
    height: 100%;
    overflow: visible;
    padding-top: 20px;
}

.modalInner > * {
    width: 100%;
}

.title,
.shiftedTitle {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    width: calc(100% + 48px);
    padding: 14px 24px;
    background-image: url('../../../../../../assets/svg/wizard-exercise-background.svg');
    background-position: calc(50% - 50px) calc(50% + 12px);
}

.shiftedTitle {
    padding-left: 34px;
}

.backArrow {
    position: absolute;
    left: 12px;
    top: 13px;
    transform: rotate(-90deg);
    height: 26px;
    width: 14px;
    cursor: pointer;
}

.backArrow path {
    stroke: var(--black);
    stroke-opacity: 1;
}
