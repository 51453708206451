.sentencesCont {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    padding-bottom: 100px;
}

.sentence {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.text,
.variantsCont {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    width: 100%;
    user-select: none;
    min-height: 19px;
}

.text {
    min-height: 16px;
    position: relative;
}

.text::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--text-light-gray-secondary);
}

.answer,
.variant {
    cursor: pointer;
    box-sizing: border-box;
    height: 19px;
    min-width: 30px;
    padding: 0 9px;
    border-radius: 5px;
    background-color: var(--primary-light-gray);
    transition: all 0.2s;
    border: 1px solid transparent;
}

.answer.correct {
    background: var(--green-transparent);
    border: 1px solid var(--text-light-green);
    color: var(--black);
    cursor: default;
}

.answer.wrong {
    background: var(--primary-transparent-red);
    border: 1px solid var(--text-light-red);
    color: var(--black);
    cursor: default;
}

@media (hover: hover) {
    .variant:hover {
        background-color: var(--border-light-gray);
    }
}
