.navLink {
    display: flex;
    flex-direction: column;
    gap: 1px;
    align-items: center;
    font-size: 13px;
    line-height: 15px;
    margin-top: 4px;
    color: var(--dark-gray);
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: 500;
}

.navLink.active {
    font-weight: 700;
}

.navLink::after {
    content: '';
    height: 2px;
    background: var(--dark-gray);
    transition: all 0.3s;
    opacity: 0;
    width: 0;
}

.navLink.underline.active::after {
    opacity: 1;
    width: 100%;
}

.navLinkDivider {
    height: 16px;
    width: 1px;
    background: var(--border-light-gray);
    margin: 0 20px;
}

@media (hover: hover) {
    .navLink.underline:not(.active):hover::after {
        opacity: 0.5;
        width: 100%;
    }
}
