.studentCard {
    min-height: 80px;
    max-height: 80px;
    overflow: hidden;
    box-sizing: border-box;
    padding: 12px 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    transition: all 0.2s;
    position: relative;
    cursor: pointer;
}

.studentCard.active {
    background: var(--white-hover);
}

.studentCard svg {
    width: 14px;
    height: 14px;
}

.cardInner {
    display: flex;
    align-items: center;
    gap: 16px;
}

.profilePic {
    width: 44px;
    height: 44px;
    background: var(--secondary-light-gray);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
}

.profilePic p {
    margin-top: 2px;
    font-size: 24px;
    line-height: 24px;
}

.titleCont {
    display: flex;
    gap: 10px;
}

.name {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.level,
.onboardingLevel {
    font-size: 12px;
    font-weight: 400;
    width: fit-content;
    color: var(--text-light-gray-secondary);
}

.onboardingLevel {
    border-radius: 24px;
    padding: 5px 10px;
    background: var(--white);
}

.notification {
    min-width: 16px;
    height: 16px;
    padding: 0 3px;
    border-radius: 8px;
    background: var(--purple);
    color: var(--white);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
}

@media (hover: hover) {
    .studentCard:hover {
        background: var(--white-hover);
    }
}
