.studentsSelect {
    position: relative;
    padding: 0px 15px;
    padding-right: 30px;
    height: 32px;
    display: flex;
    color: var(--text-light-gray-secondary);
    background: var(--white);
    border-radius: 8px;
    cursor: pointer;
    outline: none;
    width: 230px;
    min-width: 230px;
    align-items: center;
    font-weight: 400;
    text-align: left;
    transition: background-color 0.2s;
    border: 1px solid var(--white);
}

.studentsSelect.modal {
    border: 1px solid var(--dialogue-accent-gray);
}

.studentsSelect.active {
    padding-right: 30px;
    border: 1px solid var(--purple);
}

.studentsSelect > * {
    width: 100%;
    user-select: none;
}

.studentsSelect .optionCont {
    padding-right: 0px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--text-light-gray-secondary);
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
}

.name {
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--purple);
}

.studentsSelect .optionCont {
    height: 32px;
    max-height: 32px;
}

.filterButton {
    width: 20px !important;
}

.filterButton .iconClose {
    width: 22px;
}

.filterButton .white path {
    stroke: var(--white);
}

.filterButton .black path {
    stroke: var(--black);
}

.closeButton path {
    stroke: var(--purple);
}

.arrowDown {
    content: url('/src/assets/svg/lessons-arrow.svg');
    position: absolute;
    width: 12px;
    height: 12px;
    top: 50%;
    right: 13px;
    transform: rotate(180deg) translateY(50%);
}

.arrowUp {
    content: url('/src/assets/svg/lessons-arrow.svg');
    position: absolute;
    width: 12px;
    height: 12px;
    top: 50%;
    right: 13px;
    transform: translateY(-50%);
}

.studentsSelect .arrowDown,
.studentsSelect .arrowUp {
    right: 10px;
}

.options {
    position: absolute;
    top: calc(100% + 10px);
    border-top: none;
    width: 230px;
    min-width: 230px;
    height: max-content;
    max-height: 600px;
    overflow: hidden scroll;
    color: var(--black);
    z-index: 3;
    display: flex;
    flex-direction: column;
    padding: 5px;
    gap: 5px;
    background: var(--white);
    box-shadow: 0px 4px 12px 0px var(--black-transparent);
    border-radius: 12px;
    right: 0;
}

.options::-webkit-scrollbar {
    width: 0;
}

.emptyList {
    position: absolute;
    top: calc(100% + 10px);
    border-top: none;
    right: 0;
    width: fit-content;
    background: var(--white);
    padding: 6px 12px;
    white-space: nowrap;
    background: var(--white);
    box-shadow: 0px 4px 12px 0px var(--black-transparent);
    color: var(--text-light-gray-secondary);
    border-radius: 8px;
    font-size: 12px;
    cursor: default;
    z-index: 1;
}

.optionCont {
    cursor: pointer;
    position: relative;
    transition: all 0.2s;
    white-space: nowrap;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 6px;
    border-radius: 8px;
    border: none;
    min-height: 32px;
    max-height: 32px;
    cursor: pointer;
    position: relative;
}

.level {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    min-width: 32px;
    letter-spacing: 0px;
    text-align: center;
    cursor: pointer;
    text-transform: capitalize;
    -webkit-user-select: none;
    user-select: none;
    font-weight: 400;
    white-space: nowrap;
    position: relative;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    padding: 0 6px;
    text-align: center;
    background: var(--primary-light-gray);
    border: 1px solid transparent;
    color: var(--text-light-gray-secondary);
}

.optionCont p {
    margin-right: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
}

.value {
    padding-right: 0px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media (hover: hover) {
    .studentsSelect:hover {
        background: var(--white-hover);
    }
    .optionCont:hover {
        background: var(--primary-light-gray);
    }
}
