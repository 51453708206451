.exerciseText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
    user-select: none;
}

.wordContainer {
    display: flex;
    align-items: center;
}

.answer {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: var(--primary-light-gray);
    padding: 1px 9px;
    user-select: none;
    transition: all 0.2s;
    min-height: 18px;
    border: 1px solid transparent;
}

.answer.correct {
    background: var(--green-transparent);
    border: 1px solid var(--text-light-green);
    color: var(--black);
    cursor: default;
}

.answer.wrong {
    background: var(--primary-transparent-red);
    border: 1px solid var(--text-light-red);
    color: var(--black);
    cursor: default;
}
