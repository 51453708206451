.trackCard {
    display: flex;
    flex-shrink: 0;
    gap: 20px;
    padding: 10px 20px;
    overflow: hidden;
    border: 1px solid var(--secondary-light-gray);
    border-radius: 20px;
    transition: all 0.2s;
    position: relative;
}

.trackCard.light {
    background: var(--border-light-gray);
    opacity: 0.8;
}

.trackCard.error {
    border: 1px solid var(--primary-red);
}

.leftColumn {
    display: flex;
    width: 180px;
    flex-direction: column;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
}

.imageContainer {
    width: 100%;
    height: 100px;
    background: var(--border-light-gray);
    overflow: hidden;
    border-radius: 20px;
    position: relative;
}

.imageContainer img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.infoCont {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    text-transform: uppercase;
}

.infoText {
    font-size: 14px;
    background: var(--night-gray);
    color: var(--white);
    width: min-content;
    white-space: nowrap;
    padding: 1px 6px;
    border-radius: 10px;
}

.infoCont .infoText:last-child {
    margin-left: auto;
}

.rightColumn {
    display: flex;
    flex-direction: column;
    width: calc(100% - 200px);
    gap: 10px;
    position: relative;
}

.titleContainer {
    display: flex;
    gap: 10px;
    align-items: center;
}

.title {
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    width: fit-content;
    position: relative;
    cursor: pointer;
}

.title::after {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    height: 2px;
    background: var(--night-gray);
    transition: all 0.3s;
    opacity: 0;
    width: 0;
}

.description {
    display: -webkit-box;
    overflow: hidden;
    line-height: 18px;
    font-size: 12px;
    color: var(--medium-gray);
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.levelsContainer {
    margin-top: auto;
    display: flex;
    gap: 5px;
    align-items: end;
}

.buttonsContainer {
    display: flex;
    gap: 5px;
    position: absolute;
    right: 20px;
    bottom: 10px;
    opacity: 0;
    transition: all 0.3s;
}

.buttonsContainer button,
.languagesContainer button,
.extraControls button {
    padding: 3px 6px;
    padding-top: 3px;
    font-size: 12px;
    line-height: 16px;
    max-height: 22px;
}

.languagesContainer button {
    margin: auto;
}

.languagesContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: var(--white);
}

.loaderCont {
    transform: scale(0.3);
}

.languagesList {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
    padding: 0 6px;
}

.language {
    font-size: 14px;
    background: var(--secondary-light-gray);
    color: var(--black);
    width: min-content;
    white-space: nowrap;
    padding: 1px 6px;
    border-radius: 10px;
    text-transform: uppercase;
    transition: all 0.2s;
    cursor: pointer;
}

.language.active {
    background: var(--night-gray);
    color: var(--white);
}

.language.active.loading {
    content: '...';
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(
        to right,
        var(--secondary-light-gray) 1%,
        var(--medium-gray) 18%,
        var(--secondary-light-gray) 33%
    );
    background-size: 480px 104px;
}

.extraControls {
    margin-left: auto;
    display: flex;
    gap: 12px;
}

.rating {
    padding: 3px 6px;
    padding-top: 3px;
    font-size: 12px;
    line-height: 16px;
    max-height: 22px;
    border-radius: 40px;
    color: var(--white);
    background: var(--purple);
    min-width: fit-content;
}

@media (hover: hover) {
    .trackCard:hover .buttonsContainer {
        opacity: 1;
    }
    .trackCard:hover .categoryButtonContainer {
        opacity: 1;
    }
    .title:hover::after {
        opacity: 0.5;
        width: 100%;
    }
    .language:hover {
        background: var(--border-light-gray);
    }
    .language.active:hover {
        background: var(--night-gray);
        box-shadow: 0px 7px 16px 0px var(--black-transparent);
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -800px 0;
    }
    100% {
        background-position: 800px 0;
    }
}
