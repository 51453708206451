.textCont {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.exerciseText {
    overflow: hidden scroll;
    flex-grow: 1;
    border: 0.3px solid var(--border-light-gray);
    border-radius: 8px;
}

.textInner {
    box-sizing: border-box;
    background: var(--white);
    padding: 8px 32px 7px 8px;
    width: 100%;
    height: 100%;
    color: var(--dark-gray);
    font-weight: 400;
    font-size: 12px;
    font-family: Inter;
    text-align: left;
}

.textCont.edit .exerciseText {
    overflow: hidden;
}

.sentence {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 25px;
    min-height: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 4px;
    position: relative;
    margin-bottom: 3px;
}
