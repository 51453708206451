.titleContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.icon {
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon svg {
    width: 34px;
    height: 34px;
}

.title {
    font-size: 36px;
    font-weight: 400;
    line-height: 49.03px;
    text-align: left;
}
