.notifyContainer {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 30% 70%;
    height: 100%;
    max-height: calc(100% - 170px);
    gap: 20px;
}

.emptyAlert {
    margin: auto;
}

.addIcon {
    background: var(--secondary-light-gray);
    content: '+';
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 12px;
    margin: auto;
}
