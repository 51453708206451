.bottomButtons {
    display: flex;
    gap: 12px;
    background: var(--white);
    /* padding: 3px 4px; */
}

@media (max-width: 1024px) {
    .bottomButtons {
        gap: 6px;
    }
}
