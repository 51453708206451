.statementsCont {
    position: relative;
    height: fit-content;
    max-height: calc(100% - 120px);
    padding-bottom: 20px;
}

.statements {
    border-radius: 16px;
    background: var(--transparent-gray);
    max-width: 600px;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 8px;
    flex-direction: column;
    margin: 0 auto 14px;
    padding: 10px 20px;
    padding-bottom: 40px;
    font-family: 'SanFrancisco';
    font-size: 18px;
    overflow: hidden scroll;
    position: relative;
}

.fromMe,
.fromThem,
.placeholder {
    border-radius: 16px;
    font-size: 15px;
    line-height: 1.25;
    max-width: 75%;
    padding: 7px 12px;
    position: relative;
    word-wrap: break-word;
    cursor: pointer;
}

.fromMe.active,
.fromThem.active {
    background: transparent;
}

.fromMe {
    align-self: flex-end;
    background: var(--medium-gray);
    color: var(--white);
}

.fromMe ~ .fromMe {
    margin: 3.5px 0 0;
}

.fromMe ~ .fromMe:not(:last-child) {
    margin: 3.5px 0 0;
}

.fromMe ~ .fromMe:last-child {
    margin-bottom: 7px;
}

.fromThem {
    align-items: flex-start;
    background: var(--white);
    color: var(--black);
}

.buttons {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    top: 50%;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s;
}

.buttons.active {
    pointer-events: all;
    opacity: 1;
}

.fromMe .buttons {
    left: -10px;
    transform: translate(-100%, -50%);
}

.fromThem .buttons {
    right: -10px;
    transform: translate(100%, -50%);
}

.buttonIcon {
    height: 12px;
    width: 20px;
    min-width: 20px;
}

.buttonIcon path {
    stroke: var(--white);
}

.editIcon {
    height: 14px;
    width: 20px;
    min-width: 20px;
}

.editMe,
.editThem {
    border-radius: 16px;
    font-size: 15px;
    line-height: 1.25;
    padding: 7px 12px;
    word-wrap: break-word;
    cursor: pointer;
    position: absolute;
    width: calc(100% + 10px);
    height: 100% !important;
    top: 50%;
    left: 50%;
    border: none;
    transform: translate(-50%, -50%);
    resize: none;
    background: var(--border-light-gray);
    color: var(--black);
    outline: none;
}

.placeholder {
    background: var(--secondary-light-gray);
    width: 560px;
    position: relative;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(
        to right,
        var(--secondary-light-gray) 1%,
        var(--border-light-gray) 18%,
        var(--secondary-light-gray) 33%
    );
    background-size: 800px 104px;
    position: relative;
    z-index: 2;
    color: transparent;
}

.modalInner {
    display: flex;
    min-width: 600px;
    padding: 30px 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.title {
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    width: -moz-fit-content;
    width: fit-content;
}

.horizontalContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    width: 100%;
}

.topDesc,
.bottomDesc,
.statementDesc {
    font-size: 12px;
    color: var(--text-light-gray-secondary);
    white-space: nowrap;
}

.topDesc {
    margin-top: -25px;
}

.bottomDesc {
    margin-bottom: -25px;
}

.statementDesc {
    color: var(--text-light-gray);
    text-align: left;
    width: 100%;
    margin-left: 30px;
}

.statementsInfoCont {
    width: 100%;
    max-height: 450px;
    overflow: hidden scroll;
}

.statementsInfoContInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    height: max-content;
    padding-top: 30px 10px;
}

.statementInfoObj {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 5px;
    padding: 0 10px;
    margin-bottom: 15px;
}

.statementInfoObj:last-child {
    margin-bottom: 100px;
}

.me,
.them {
    width: 100%;
    max-width: 300px;
    padding: 6px 12px;
    border-radius: 12px;
}

.me {
    background: var(--medium-gray);
    color: var(--white);
}

.them {
    background: var(--border-light-gray);
    color: var(--black);
}

.me.fullWidth,
.them.fullWidth {
    width: 100%;
    max-width: 625px;
}

.arrow {
    transform: rotate(180deg) scale(0.7);
}

.arrow path {
    fill: var(--night-gray);
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -800px 0;
    }
    100% {
        background-position: 800px 0;
    }
}
