.navSelectContainer {
    position: relative;
    user-select: none;
}

.navSelectContainer > button {
    padding: 5px 12px;
    font-size: 16px;
}

.options {
    width: 245px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    border-radius: 12px;
    box-shadow:
        0px 4px 12px 0px var(--black-transparent),
        0px 0px 1px 0px var(--black-transparent-secondary);
    position: absolute;
    background: var(--white);
    left: 0;
    top: calc(100% + 12px);
    z-index: 3;
}

.optionContainer {
    width: 100%;
    height: 60px;
    border: 1px solid var(--primary-light-gray);
    background: var(--white);
    border-radius: 8px;
    padding: 12px 14px;
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.1s;
}

.arrowDown,
.arrowUp {
    width: 12px;
    height: 12px;
    top: 50%;
    right: 13px;
    position: absolute;
    pointer-events: none;
}

.arrowDown {
    content: url('/src/assets/svg/lessons-arrow.svg');
    transform: rotate(180deg) translateY(50%);
}

.arrowUp {
    content: url('/src/assets/svg/lessons-arrow.svg');
    transform: translateY(-50%);
}

.iconContainer {
    width: 34px;
    min-width: 34px;
    height: 34px;
    border-radius: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.optionContainer.exercises .iconContainer {
    background: var(--blue-transparent);
}

.optionContainer.tracks .iconContainer {
    background: var(--light-purple);
}

.optionContainer.situations .iconContainer {
    background: var(--orange-transparent);
}

.optionContainer.dictionaries .iconContainer {
    background: var(--words-green-transparent-hover);
}

.title {
    font-size: 16px;
    line-height: 21.79px;
}

.subtitle {
    font-size: 12px;
    font-weight: 300;
    line-height: 16.34px;
    color: var(--text-light-gray-secondary);
}

@media (hover: hover) {
    .optionContainer.exercises:not(.onboarding):hover {
        background: var(--blue-transparent);
    }
    .optionContainer.exercises:not(.onboarding):hover .iconContainer {
        background: var(--blue);
    }
    .optionContainer.tracks:not(.onboarding):hover {
        background: var(--light-purple);
    }
    .optionContainer.tracks:not(.onboarding):hover .iconContainer {
        background: var(--purple);
    }
    .optionContainer.situations:not(.onboarding):hover {
        background: var(--orange-transparent);
    }
    .optionContainer.situations:not(.onboarding):hover .iconContainer {
        background: var(--orange);
    }
    .optionContainer.dictionaries:not(.onboarding):hover {
        background: var(--words-green-transparent-hover);
    }
    .optionContainer.dictionaries:not(.onboarding):hover .iconContainer {
        background: var(--words-green);
    }
    .optionContainer:not(.onboarding):hover .iconContainer path,
    .optionContainer:not(.onboarding):hover .iconContainer rect {
        filter: brightness(0) invert(1);
    }
}
