.PageWrapper {
    width: 100%;
    overflow: hidden;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 1440px;
    margin: 0 auto;
}
