.section {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.sectionInner {
    height: 60vh;
    min-height: 500px;
    max-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.sectionNumber {
    max-width: 260px;
    margin: 0 auto;
}
