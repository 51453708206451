.blacklistTrackCard {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    border: 1px solid var(--primary-light-gray);
    border-radius: 20px;
    gap: 10px;
    cursor: pointer;
}

.imageCont {
    max-width: 180px;
    max-height: 100px;
    width: 100%;
    height: 100px;
    background: var(--secondary-light-gray);
    width: 180px;
    overflow: hidden;
    border-radius: 20px;
    position: relative;
}

.imageCont img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.buttonsContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 0 auto;
    margin-top: 20px;
}

.buttons {
    margin-left: auto;
    margin-top: auto;
    max-height: 38px;
    display: flex;
    gap: 20px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s;
}

.reason {
    color: var(--text-light-gray-secondary);
}

@media (hover: hover) {
    .blacklistTrackCard:hover .buttons {
        opacity: 1;
        pointer-events: all;
    }
}
