.cardsCont,
.resultsCont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 90%;
    max-width: 650px;
    background: var(--white);
    border-radius: 32px;
    box-shadow: 0px 2px 46px 8px var(--black-transparent);
    z-index: 10;
}

.alertCont {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cardsCont {
    overflow: hidden;
}

.resultsCont {
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.resultsCont p {
    text-align: center;
}

.resultsCont p:last-child {
    margin-top: 20px;
}

.loaderContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cardControls {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    left: 0;
    top: 0;
}

.repeatButton,
.continueButton {
    width: 50%;
    height: 100%;
    position: relative;
    cursor: pointer;
    overflow: visible;
    opacity: 0;
    transition: opacity 0.2s;
}

.repeatButton::before,
.continueButton::before {
    content: '';
    position: absolute;
    top: 0;
    width: 200%;
    height: 100%;
    pointer-events: none;
}

.repeatButton::before {
    left: 0;
}

.continueButton::before {
    right: 0;
}

.repeatButton::before {
    background: linear-gradient(
        to left,
        transparent 0%,
        var(--primary-transparent-red) 50%
    );
}

.continueButton::before {
    background: linear-gradient(
        to right,
        transparent 0%,
        var(--primary-transparent-green) 50%
    );
}

.repeatButton:hover::before,
.continueButton:hover::before {
    opacity: 0.9;
}

.buttonInner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    gap: 5px;
}

.repeatButton .buttonInner {
    left: 7%;
}

.continueButton .buttonInner {
    right: 7%;
}

.buttonInner p {
    font-family: Noto Sans;
    font-size: 20px;
    font-weight: 20px;
    line-height: 24px;
    color: var(--black);
    white-space: nowrap;
}

.buttonInner svg {
    width: 16px;
}

.repeatButton svg {
    transform: rotate(180deg);
}

.repeatButton p,
.repeatButton path {
    color: var(--primary-red);
    stroke: var(--primary-red);
}

.continueButton p,
.continueButton path {
    color: var(--primary-green);
    stroke: var(--primary-green);
}

.endTitle,
.endSubtitle {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0px;
    margin-bottom: 12px;
}

.endSubtitle {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
}

.wordsList {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin: 20px 0;
}

.wordsList span {
    font-size: 22px;
}

.learnedList {
    font-weight: 500;
}

.cardTitle {
    position: absolute;
    padding-top: 12px;
    left: 10px;
    width: calc(100% - 20px);
    text-align: center;
    color: var(--text-light-gray);
    background: var(--white);
    z-index: 3;
}

@media (hover: hover) {
    .repeatButton:hover {
        opacity: 1;
    }

    .continueButton:hover {
        opacity: 1;
    }
}
