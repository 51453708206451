.modalInner {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-top: 10px;
}

.errorMessage {
    color: var(--primary-red);
}

.presetContainer {
    display: flex;
    gap: 5px;
    position: absolute;
    align-items: center;
    right: 20px;
    bottom: 10px;
}

.presetContainer button {
    padding: 3px 6px;
    padding-top: 3px;
    font-size: 12px;
    line-height: 16px;
    max-height: 22px;
}

.presetContainer input {
    font-size: 12px;
    line-height: 16px;
    height: 24px;
    max-height: 24px;
    width: 100px;
}

.sideLabel {
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
    position: absolute;
    left: 6px;
    top: -2px;
    transform: translateY(-100%);
    color: var(--text-light-gray-secondary);
}
