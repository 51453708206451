.chapter {
    display: flex;
    gap: 8px;
    height: 32px;
    padding: 6px;
    border-radius: 4px;
    transition: background-color 0.2s;
    position: relative;
}

.chapter.white {
    background: var(--white);
}

.chapter.grey {
    background: var(--light-gray);
}

.chapter.finished {
    background: var(--light-gray);
}

.chapter.transparent {
    background: transparent;
}

.icon {
    width: 20px;
    min-width: 20px;
}

.title {
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
