.container {
    margin-top: 10px;
    display: flex;
    gap: 12px;
    height: 100vh;
    max-height: calc(100vh - 80px);
}

.aside {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 30%;
    max-width: 30%;
    height: 100%;
}

.list {
    border: 1px solid var(--border-light-gray);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    max-height: 100%;
}

.mainPart {
    width: 100%;
    max-width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
