.localizations {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 800px;
}

.localization {
    display: flex;
    flex-direction: column;
    gap: 5px;
    background: var(--secondary-light-gray);
    border-radius: 12px;
    padding: 6px 12px;
    padding-bottom: 24px;
    position: relative;
}

.label {
    font-weight: 600;
    white-space: nowrap;
}

.loaderContainer {
    transform: scale(0.5);
    margin-top: -30px;
    margin-bottom: -30px;
}
