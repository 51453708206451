.authModal {
    padding: 0;
    width: 85vw;
    min-height: 90vh;
    height: 90vh;
    max-width: 1090px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    background: var(--white);
    border-radius: 16px;
    position: relative;
    overflow: auto;
}
