.label {
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 16px;
    color: var(--black);
    white-space: wrap;
    transition: color 0.2s;
    white-space: pre-line;
}

.label.error {
    color: var(--primary-red);
    animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-0.5px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(0.5px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-1px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(1px, 0, 0);
    }
}
