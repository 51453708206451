.name {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.note {
    width: 100%;
    font-weight: 400;
    padding: 8px 16px;
    margin: 0;
    border: 0.3px solid var(--border-light-gray);
    border-radius: 8px;
    font-size: 12px;
    box-sizing: border-box;
    min-height: 79px;
    line-height: 16.34px;
    resize: vertical;
    display: flex;
    align-items: center;
    flex-grow: 1;
    outline: none;
    overflow-y: hidden;
    height: max-content;
    transition: background-color 0.1s;
    resize: none;
}

.note::-webkit-scrollbar {
    width: 0;
}
