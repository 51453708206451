.button {
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
    width: min-content;
    position: relative;
    white-space: nowrap;
    user-select: none;
    -webkit-user-drag: none;
    cursor: pointer;
    transition:
        all 0.2s,
        transform 0s;
    font-family: Noto Sans;
    text-align: center;
}

.button[disabled] {
    filter: grayscale(1);
    opacity: 0.5;
    cursor: not-allowed;
}

.button.huge {
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    border-radius: 12px;
}

.button.large {
    height: 38px;
    min-width: 38px;
    min-height: 38px;
    border-radius: 12px;
}

.button.regular {
    height: 28px;
    min-width: 28px;
    min-height: 28px;
    padding: 5px 6px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.button.small {
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    padding: 5px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.button.huge .icon {
    transform: scale(1.2);
}

.button.small .icon {
    min-width: 10px;
}

.button.white {
    background: var(--white);
    color: var(--text-light-gray-secondary);
}

.button.grey {
    background: var(--primary-light-gray);
    color: var(--text-light-gray-secondary);
}

.button.greyOld {
    background: var(--secondary-light-gray);
    color: var(--black);
}

.button.dark {
    background: var(--dark-gray);
    color: var(--white);
}

.button.burgundy {
    background: var(--pdf-burgundy);
    color: var(--text-light-gray-secondary);
}

.button.blue {
    background: var(--hyperlink-blue);
    color: var(--text-light-gray-secondary);
}

.button.red {
    background: var(--white);
    color: var(--primary-red);
}

.button.red path {
    stroke: var(--primary-red);
}

@media (hover: hover) {
    .button.grey:hover:not([disabled]) {
        background: var(--primary-light-gray-hover);
        filter: unset;
    }

    .button.white:hover:not([disabled]),
    .button.red:hover:not([disabled]) {
        background: var(--white-hover);
    }

    .button.burgundy:hover:not([disabled]),
    .button.blue:hover:not([disabled]) {
        filter: brightness(0.9);
    }

    .button.dark:hover:not([disabled]) {
        background: var(--night-gray);
    }

    .button.greyOld:hover:not([disabled]) {
        background: var(--border-light-gray);
    }

    .button:disabled:hover {
        animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 100px;
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(1px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-2px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(2px, 0, 0);
    }
}
