.podcastCard {
    width: 100%;
    height: 180px;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;
    border: 1px solid var(--secondary-light-gray);
    border-radius: 20px;
    padding: 20px;
}

.imgCont {
    width: 150px;
    height: 150px;
    overflow: hidden;
    min-width: 150px;
    border-radius: 50%;
    border: 1px solid var(--secondary-light-gray);
}

.podcastImg {
    width: 150px;
    height: 150px;
    object-fit: cover;
    transition: transform 0.2s;
}

.imgCont:hover .podcastImg {
    transform: scale(1.05);
}

.podcastInfo {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    width: calc(100% - 140px);
}

.titleCont {
    display: flex;
    align-items: center;
    gap: 25px;
}

.title {
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    width: fit-content;
    position: relative;
}

.title::after {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    height: 2px;
    background: var(--night-gray);
    transition: all 0.3s;
    opacity: 0;
    width: 0;
}

.infoText {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--text-light-gray-secondary);
    white-space: nowrap;
}

.infoText.active {
    font-weight: 700;
}

.infoText.count {
    position: relative;
    cursor: pointer;
}

.infoText span {
    text-transform: uppercase;
    font-size: inherit;
    line-height: inherit;
    color: var(--black);
}

.description {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    -webkit-box-orient: vertical;
    min-height: 60px;
}

.tracksBar {
    display: flex;
    flex-direction: row;
    gap: 5px;
    overflow-y: scroll;
}

.tracksBar::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.trackBtn {
    width: 40px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondary-light-gray);
    border-radius: 3px;
    gap: 4px;
    padding: 4px 10px;
    transition: background-color 0.2s;
}

.verticalBox {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.recognizeBtnCont {
    margin-left: auto;
    position: relative;
}

.popup {
    height: 120px;
    width: 300px;
    position: absolute;
    bottom: -10px;
    right: -15px;
    padding: 10px 20px;
    padding-right: 15px;
    border-radius: 10px;
    border: 1px solid var(--border-light-gray);
    box-shadow: 3px -1px 10px 0px var(--black-transparent);
    background: var(--white);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    row-gap: 10px;
    z-index: 2;
}

.buttons {
    display: flex;
    gap: 20px;
}

.ratingCont {
    display: flex;
    gap: 3px;
}

.ratingOption {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: var(--secondary-light-gray);
    transition: all 0.2s;
    cursor: pointer;
}

.ratingOption.active {
    background: var(--night-gray);
    color: var(--white);
}

.quantityPopup {
    height: 55px;
    width: fit-content;
    position: absolute;
    bottom: -10px;
    right: -15px;
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid var(--border-light-gray);
    box-shadow: 3px -1px 10px 0px var(--black-transparent);
    background: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    z-index: 2;
}

.tracksCountByDifficulties {
    display: flex;
    gap: 15px;
    flex-direction: row;
    padding: 5px 10px;
    background: var(--secondary-light-gray);
    border-radius: 20px;
    transition: all 0.2s;
}

@media (hover: hover) {
    .title:hover::after {
        opacity: 0.5;
        width: 100%;
    }
    .ratingOption:hover {
        background-color: var(--border-light-gray);
    }
    .ratingOption.active:hover {
        background: var(--night-gray);
        box-shadow: 0px 7px 16px 0px var(--black-transparent);
    }
}
