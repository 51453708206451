.primarySelect {
    position: relative;
    padding: 0px 15px;
    padding-right: 47px;
    height: 38px;
    display: flex;
    color: var(--black);
    background: var(--secondary-light-gray);
    border-radius: 20px;
    font-weight: 400;
    cursor: pointer;
    outline: none;
    width: fit-content;
    align-items: center;
    width: 200px;
}

.primarySelect > * {
    width: 100%;
    user-select: none;
}

.primarySelect.expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.arrowDown {
    content: url('/src/assets/svg/arrow_down.svg');
    position: absolute;
    width: 12px;
    height: 12px;
    top: 50%;
    transform: translateY(-50%);
    right: 13px;
}

.arrowUp {
    content: url('/src/assets/svg/arrow_down.svg');
    position: absolute;
    width: 12px;
    height: 12px;
    top: 50%;
    right: 13px;
    transform: rotate(180deg) translateY(50%);
}

.options {
    position: absolute;
    top: calc(100% - 1px);
    border-top: none;
    left: 0;
    right: 0;
    width: 100%;
    height: max-content;
    max-height: 400px;
    overflow: hidden scroll;
    color: var(--black);
    background: var(--secondary-light-gray);
    z-index: 100;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top: none;
    padding-bottom: 5px;
}

.options::-webkit-scrollbar {
    width: 0;
}

.optionCont {
    display: flex;
    align-items: center;
    width: calc(100% - 10px);
    padding: 0 15px;
    margin: 0 auto;
    border-radius: 40px;
    border: none;
    min-height: 35px;
    cursor: pointer;
    position: relative;
}

.optionCont:hover {
    background: var(--border-light-gray);
}

.option {
    padding-right: 0px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.option.active {
    font-weight: 600;
}

.value {
    padding-right: 0px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
