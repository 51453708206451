.card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
}

.translationList {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    margin: 12px;
}

.originalText {
    color: var(--black);
    transition: filter 0.2s;
    user-select: none;
    text-align: center;
}

.translationText {
    color: var(--text-light-gray-secondary);
    transition: filter 0.2s;
    user-select: none;
    text-align: center;
    margin-bottom: 8px;
}

.translationText.active {
    color: var(--black);
    font-weight: 300;
    font-size: 15px;
}

.translationText.correct {
    font-weight: 300;
    color: var(--primary-green);
}

.answerCont,
.optionsCont {
    display: flex;
    justify-content: center;
    gap: 6px;
    flex-wrap: wrap;
    padding: 0 16px;
}

.word {
    border-radius: 4px;
    padding: 3px 6px;
    height: 22px;
    font-size: 15px;
    letter-spacing: 0px;
    text-align: center;
    color: var(--black);
    cursor: pointer;
    user-select: none;
    background: var(--transparent-gray);
    border: 0.5px solid var(--transparent-gray);
    transition: all 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.optionsCont .word {
    background: var(--night-gray);
    color: var(--white);
}

.word.correct {
    background: var(--green-transparent);
    border: 0.5px solid var(--green);
}

.word.wrong {
    background: var(--primary-transparent-red);
    border: 0.5px solid var(--primary-red);
}

.hintButton {
    width: 36px;
    height: 36px;
    max-height: 36px;
    border-radius: 50%;
    padding: 0;
    position: relative;
    margin-top: auto;
    margin-bottom: 24px;
}

.hintButton.hidden {
    opacity: 0;
    pointer-events: none;
}

.hintButton svg {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.hintButton path {
    stroke: var(--black);
}
