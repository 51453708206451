.previewContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    height: 100%;
    padding-bottom: 16px;
    overflow: scroll;
}

.previewContainer img {
    width: 100%;
    object-fit: contain;
    border-radius: 12px;
}

.pdfDocument {
    width: 100%;
}

.label {
    margin-left: 10px;
    font-size: 12px;
    line-height: 16px;
    color: var(--black);
    margin: auto;
}
