.theoryCard {
    padding: 40px 20px;
    overflow-y: scroll;
    position: relative;
}

.mainInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mainInfo {
    gap: 8px;
    margin-bottom: 32px;
}

.wordText {
    font-size: var(--_24px);
    font-weight: 700;
    line-height: 41px;
    margin-bottom: -8px;
    width: 100%;
    text-align: center;
    word-wrap: break-word;
}

.wordInfo {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: var(--text-light-gray-secondary);
}

.additionalInfo,
.infoCont,
.examplesCont {
    display: flex;
    flex-direction: column;
}

.additionalInfo {
    gap: 12px;
    width: 100%;
}

.examplesCont {
    gap: 6px;
}

.partOfSpeech {
    color: var(--words-green);
    font-size: 15px;
}

.infoLabel {
    text-transform: lowercase;
    color: var(--words-green);
    width: fit-content;
}

.infoText,
.infoTranslation {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
}

.infoTranslation {
    color: var(--text-light-gray-secondary);
}

.playButton {
    height: 56px;
    width: 56px;
    padding: 0;
    border-radius: 50%;
    position: relative;
}

.playButton svg {
    height: 16px;
    width: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.playButton path {
    stroke: var(--black);
}

.buttonContainer button {
    display: inline-flex;
    width: var(--_24px);
    height: var(--_24px);
    min-width: var(--_24px);
    margin-right: var(--_6px);
    margin-top: var(--_3px);
    padding: unset;
    position: absolute;
    left: 12px;
    top: 12px;
    margin: 0;
    z-index: 4;
}

.soundIcon {
    width: var(--_14px);
    height: var(--_12px);
}

.soundIcon path {
    stroke: var(--black);
}
