.container {
    padding: 7px 0;
    position: relative;
}

.trackLine {
    width: 100%;
    background: var(--secondary-light-gray);
    height: 6px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s;
    margin-bottom: 0px;
}

.trackLineWrapper {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom: 5px;
}

.controlsCont {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 28px;
}

.controlBtn {
    background: none;
    border: none;
    cursor: pointer;
}

.playBtn svg {
    width: 30px;
    height: 40px;
}

.controlBtn svg {
    width: 32px;
}

.dragger {
    position: absolute;
    top: 50%;
    transition: top 0.3s;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    width: 18px;
    height: 18px;
    background: var(--purple);
    pointer-events: none;
}

.timeCont {
    display: flex;
    justify-content: space-between;
}

.timeItem {
    color: var(--text-light-gray-secondary);
    font-size: 12px;
}

.trackProgress {
    background: var(--purple);
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;
}
