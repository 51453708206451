.card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0;
    justify-content: center;
    align-items: center;
}

.part {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
}

.part:first-child {
    justify-content: flex-end;
    padding-bottom: 10px;
}

.part:last-child {
    padding-top: 10px;
    justify-content: flex-start;
}

.part p,
.word,
.active {
    font-family: Noto Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: var(--text-light-gray-secondary);
    transition: filter 0.2s;
    display: inline-block;
}

.active {
    color: var(--black);
}

.part.blur p {
    filter: blur(5px);
    cursor: pointer;
}

.eyeButton {
    width: 36px;
    height: 36px;
    max-height: 36px;
    border-radius: 50%;
    padding: 0;
    position: absolute;
    left: 16px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s;
}

.eyeButton.visible {
    opacity: 1;
    pointer-events: all;
}

.eyeButton:nth-child(2) {
    top: 16px;
}

.eyeButton:nth-child(3) {
    bottom: 16px;
}

.eyeButton svg {
    height: 16px;
    width: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.playButton {
    width: 36px;
    height: 36px;
    max-height: 36px;
    border-radius: 50%;
    padding: 0;
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
}

.playButton svg {
    height: 12px;
    width: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.playButton path {
    stroke: var(--black);
}
