.trackPlayer {
    background: var(--black);
    border-radius: 12px;
    padding: 6px 24px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
}

.closeButton svg {
    filter: invert(1);
}
