.lessonsCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 25%;
    max-height: 58vh;
    cursor: pointer;
    border-radius: var(--_12px);
    background: var(--white);
    z-index: 2;
    position: relative;
}

.lessonsCard::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--white);
    z-index: -1;
    border-radius: var(--_12px);
}

.single {
    max-width: 100%;
}

.titleBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titleBlock > p {
    font-size: var(--_18px);
    font-weight: 400;
    line-height: var(--_24px);
}

.moreButton {
    cursor: pointer;
}

.cardContainer {
    background-color: #f0f0f6;
    border-radius: var(--_12px);
    padding: var(--_12px);
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: var(--_8px);
    border: 1px solid transparent;
}

.lessonsCard.focused .cardContainer {
    background-color: #b770ff33;
    border: 1px solid #b770ff;
}

.lessonsCard.focused .cardContainer.onboarding {
    background-color: #ead4ff94;
    cursor: default;
}

.cardContent {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: var(--_12px);
    padding: var(--_12px);
    box-shadow: 0px 4px 26.9px 0px var(--landing-transparent-shadow);
    max-height: 100%;
    overflow-y: auto;
    max-height: calc(100% - var(--_32px));
}

.cardTrack {
    display: flex;
    flex-direction: column;
}

.cardTrack div {
    display: flex;
    gap: var(--_4px);
    align-items: center;
    color: #3c3c4399;
    font-size: var(--_11px);
}

.cardTrack div p {
    color: #3c3c4399;
    font-size: var(--_11px);
}

.cardTasks {
    display: flex;
    flex-direction: column;
    gap: var(--_4px);
    max-width: 100%;
    overflow: hidden;
}

.task {
    background-color: #fafaff;
    border-radius: var(--_4px);
    max-width: 100%;
    display: flex;
    align-items: center;
    padding: var(--_9px);
    gap: var(--_8px);
}

.task svg {
    flex-shrink: 0;
    width: var(--_20px);
    height: var(--_20px);
}

.task p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    font-size: var(--_13px);
    font-weight: 400;
    line-height: var(--_18px);
}

.addButton {
    color: #3c3c434d;
    font-size: var(--_14px);
}
