.paramsContainer {
    display: flex;
    justify-content: space-between;
    gap: 12px;
}

.paramsContainer > div:not(:first-child) {
    width: 125px;
}

.flexContainer {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
}

.flexContainer > * {
    flex: 1;
}

.flexContainer:last-child {
    margin-top: auto;
}

.paramsContainer .select {
    min-width: 125px;
    width: 125px;
}
