.questionsList {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.questionCont {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    position: relative;
    align-items: center;
}

.text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--dark-gray);
    height: fit-content;
    flex-grow: 1;
}

@media (hover: hover) {
    .answer:hover {
        background-color: var(--secondary-light-gray);
    }
    .answer.active:hover {
        background-color: var(--text-light-green);
    }
    .answer.correct:hover {
        background-color: var(--primary-green);
    }
}
