.languageCard {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    background: var(--transparent-gray);
    border-radius: 32px;
    padding: 8px 10px 10px 10px;
    border: 1px solid var(--transparent-gray);
}

.langTitle {
    font-size: 16px;
    font-weight: 400;
    max-width: 100%;
    line-height: 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 0px;
}

.langTitle span {
    margin-left: 3px;
    font-size: 12px;
    font-weight: 700;
}

.optionCont {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}
