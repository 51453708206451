.icon,
.iconSmall,
.grammarIcon {
    position: absolute;
    top: 4px;
    right: 2px;
    width: 22px;
    height: 16px;
    background: var(--text-light-gray);
    padding: 3px 4px;
    border-radius: 8px;
}

.iconSmall {
    padding: 4px;
}

.icon path {
    fill: var(--white);
    width: 10px;
}

.grammarIcon {
    padding-top: 2.5px;
    padding-bottom: 3.5px;
}
