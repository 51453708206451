.chip {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--transparent-gray);
    border-radius: 70px;
    height: min-content;
    transition:
        opacity 0.6s,
        transform 0.3s;
}

.chip.small {
    padding: 0.8vw 2vw;
    padding-top: 0.5vw;
}

.chip.medium {
    padding: 0.8vw 1.6vw;
    padding-top: 0.4vw;
}

.chip.large {
    padding: 1vw 2vw;
    padding-top: 0.5vw;
}

.text {
    letter-spacing: 0px;
    text-align: center;
    font-family: 'Noto Sans', sans-serif;
    background-size: 100%;
    background-repeat: repeat;
    -webkit-text-fill-color: transparent;
    font-weight: 400;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    background-image: linear-gradient(
        180deg,
        var(--accent-orange) 0%,
        var(--accent-orange-transparent) 100%
    );
    -webkit-background-clip: text;
}

.text.small {
    font-size: calc(1vw + 1.5vh);
    line-height: calc(1.8vw + 2.4vh);
}

.text.medium {
    font-size: calc(1.2vw + 2vh);
    line-height: calc(2.4vw + 3vh);
}

.text.large {
    font-size: calc(2.2vw + 2.5vh);
    font-size: calc(2.4vw + 2.8vh);
}

@media (hover: hover) {
    .chip:hover {
        transform: scale(1.05);
    }
}
