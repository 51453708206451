.slide {
    width: 100%;
    height: 100%;
    min-height: 600px;
    padding: 0 10px;
    overflow: hidden;
    display: grid;
    grid-template-rows: min(15%, 100px) min(18%, 120px) 65%;
    justify-content: center;
    scroll-snap-align: center;
    scroll-snap-stop: always;
    position: relative;
    transition: left 0.5s ease;
    left: 0%;
}

.frameContainer {
    width: 100%;
    max-width: 25vw;
    margin: 0 auto;
}

.slideTitle {
    font-weight: 600;
    max-width: 90%;
}

.slideSubtitle {
    max-width: 95%;
}

@media (max-width: 768px) {
    .slide {
        grid-template-rows: 12% 12% 76%;
        padding: 0;
    }
    .frameContainer {
        max-width: 100vw;
        transform: translateY(5%) scale(1.1);
    }
}

@media (max-width: 480px) {
    .slide {
        grid-template-rows: 10% 14% 76%;
        padding: 0;
    }
}
