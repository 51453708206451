.word {
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 28px;
    background: var(--black-transparent);
    display: flex;
    justify-content: space-between;
    gap: 10px;
    transition: all 0.2s;
    cursor: pointer;
    height: 30px;
    min-height: 30px;
    overflow-y: hidden;
    white-space: nowrap;
    position: relative;
    transition: padding 0.2s;
}

.word.processed {
    background: var(--green-transparent);
}

.word.checked {
    background: var(--green-transparent);
}

.word.invalid {
    background: var(--primary-transparent-red);
}

.word.active {
    background: var(--night-gray);
}

.word.shifted {
    padding-left: 30px;
}

.word span {
    text-overflow: ellipsis;
    max-width: 60%;
}

.word span:last-child {
    max-width: 70%;
    color: var(--text-light-gray-secondary);
}

.word.active span,
.word.active span:last-child {
    color: var(--white);
}

.checkBoxCont {
    position: absolute;
    top: 6.5px;
    left: 8px;
}

@media (hover: hover) {
    .word:hover {
        background: var(--black-transparent);
    }
    .word.checked:hover {
        background: var(--green-transparent-hover);
    }
    .word.invalid:hover {
        background: var(--primary-transparent-red);
    }
    .word.active:hover {
        background: var(--night-gray);
    }
}
