.rangeContainer {
    width: 200px;
    height: 38px;
    padding: 10px;
    display: flex;
    background: var(--transparent-gray);
    border-radius: 32px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.range {
    -webkit-appearance: none;
    margin: 0 auto;
    width: 80%;
    height: 3px;
    border-radius: 3px;
    background-color: var(--night-gray);
    cursor: pointer;
}

.range::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 19px;
    height: 19px;
    background-color: var(--night-gray);
    border-radius: 50%;
    cursor: pointer;
}

.range::-moz-range-thumb {
    width: 24px;
    height: 24px;
    background-color: var(--night-gray);
    border-radius: 50%;
}

.range::-ms-thumb {
    width: 24px;
    height: 24px;
    background-color: var(--night-gray);
    border-radius: 50%;
}

.range::-moz-range-track {
    background-color: var(--night-gray);
    height: 3px;
}

.range::-ms-track {
    background-color: var(--night-gray);
    height: 3px;
}

.range::-ms-fill-lower {
    background-color: var(--night-gray);
}

.range::-ms-fill-upper {
    background-color: var(--night-gray);
}

.label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 10px;
    color: var(--text-light-gray);
}

.label:first-child {
    left: 10px;
}

.label:last-child {
    right: 10px;
}
