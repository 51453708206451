.loaderLine {
    width: 100%;
    height: 15px;
    border-radius: 200px;
    background: var(--border-light-gray);
    background-size: 30px 30px;
    background-image: linear-gradient(
        135deg,
        var(--text-light-gray-secondary) 25%,
        transparent 25%,
        transparent 50%,
        var(--text-light-gray-secondary) 50%,
        var(--text-light-gray-secondary) 75%,
        transparent 75%,
        transparent
    );
    animation: animate-stripes 1.5s linear infinite;
}

@keyframes animate-stripes {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 60px 0;
    }
}
