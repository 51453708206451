.notify {
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 28px;
    background: var(--black-transparent);
    display: flex;
    justify-content: space-between;
    gap: 10px;
    transition: all 0.2s;
    cursor: pointer;
    height: 30px;
    min-height: 30px;
    overflow-y: hidden;
    white-space: nowrap;
    position: relative;
    transition: padding 0.2s;
}

.notify.active {
    background: var(--night-gray);
}

.notify span {
    text-overflow: ellipsis;
    max-width: 60%;
}

.notify span:last-child {
    max-width: 70%;
    color: var(--text-light-gray-secondary);
}

.notify.active span,
.notify.active span:last-child {
    color: var(--white);
}

@media (hover: hover) {
    .notify:hover {
        background: var(--black-transparent);
    }
    .notify.active:hover {
        background: var(--night-gray);
    }
}
