.button {
    cursor: pointer;
    transition: all 0.2s;
    aspect-ratio: 3.14 / 1;
    height: min-content;
}

.button.large {
    width: 400px;
}

.button.medium {
    width: 200px;
}

.button.small {
    width: 170px;
}

@media (hover: hover) {
    .button:hover {
        transform: scale(1.025);
    }
}

@media (max-width: 768px) {
    .button.large {
        width: 90%;
        margin: 0 auto;
        max-width: 450px;
    }

    .button.medium {
        width: 140px;
    }

    .button.small {
        width: 140px;
    }
}
