.addButton {
    width: 100%;
    background: var(--blue);
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 32px;
    height: 32px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;
}

.addButton.copied {
    background: var(--words-green);
    pointer-events: none;
}

@media (hover: hover) {
    .addButton:hover {
        background: var(--blue-hover);
    }
}
