.searchBar {
    display: flex;
    column-gap: 20px;
    align-items: flex-end;
    flex-wrap: nowrap;
}

.wordTab {
    display: flex;
    gap: 15px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;
    margin: 0 5px;
    margin-right: 100px;
    height: 50px;
    border-radius: 28px;
    box-shadow: 0px 0px 7px 0px var(--black-transparent);
    transition: all 0.2s;
    text-overflow: ellipsis;
    position: relative;
}

.wordTab:last-child {
    margin-bottom: 60px;
}

.word {
    font-size: 14px;
    font-weight: 700;
    width: fit-content;
}

.wordInfo {
    font-size: 14px;
    font-weight: 400;
    color: var(--text-light-gray-secondary);
}

.loaderCont {
    height: 20px;
    width: 20px;
    position: relative;
}

.status {
    font-size: 12px;
    line-height: 12px;
    width: fit-content;
    white-space: nowrap;
    color: var(--primary-red);
}

.buttonContainer {
    margin-left: auto;
    opacity: 0;
    transition: opacity 0.5;
}

.emptyAlert {
    margin: auto;
}

.total {
    color: var(--text-light-gray-secondary);
    margin: 10px 0;
}

@media (hover: hover) {
    .wordTab:hover .buttonContainer {
        opacity: 1;
    }
}
