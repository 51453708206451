.searchContainer {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    user-select: none;
    width: 100%;
    margin: 0 auto;
}

.groupToggle {
    min-width: 160px;
}

.buttonsCont {
    display: flex;
    gap: 2px;
}

.select {
    min-width: 120px;
}
