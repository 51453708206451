.lessonsModal {
    width: 50vw;
    max-height: 80vh;
    min-height: 400px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 0;

    background: var(--white);
    border-radius: 16px;
    position: relative;
    min-width: 250px;
    overflow: auto;
    max-width: 1090px;
}

.title {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    padding: 14px 24px 0;
}

.subtitle {
    width: 100%;
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    color: var(--text-light-gray-secondary);
    padding: 0px 24px 6px;
}

.lessonsList {
    display: flex;
    flex-direction: row;
    gap: 12px;
    overflow: scroll;
    flex-wrap: wrap;
    padding: 24px;
}

.lessonsList > * {
    width: calc(50% - 8px);
}
