.page {
    width: 95vw;
    height: calc(100vh - 100px);
    margin: 0 auto;
    margin-top: 50px;
    min-height: 600px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 56px;
    box-shadow: 0px 10px 60px 0px var(--black-transparent);
    scroll-snap-align: center;
    scroll-snap-stop: always;
    overscroll-behavior: none;
}

.page:last-child {
    width: 100vw;
    height: 97.5vh;
    margin-bottom: 0;
    border-radius: 56px 56px 0 0;
}

.page::-webkit-scrollbar {
    width: 0;
}

.page.white {
    background: var(--white-pressed);
}

.page.black {
    background: var(--black);
}

@media (max-width: 768px) {
    .page {
        margin: 40px auto;
        height: calc(100vh - 80px);
    }
    .page:last-child {
        height: calc(100vh - 40px);
    }
    @supports (height: 100dvh) {
        .page {
            margin: 40px auto;
            height: calc(100dvh - 80px);
        }
        .page:last-child {
            height: calc(100dvh - 40px);
        }
    }
}

@media (max-width: 480px) {
    .page {
        margin: 16px auto;
        height: calc(100vh - 32px);
        border-radius: 32px;
    }
    .page:last-child {
        height: calc(100vh - 16px);
        border-radius: 32px 32px 0 0;
    }
    @supports (height: 100dvh) {
        .page {
            margin: 16px auto;
            height: calc(100dvh - 32px);
            border-radius: 32px;
        }
        .page:last-child {
            height: calc(100dvh - 16px);
            border-radius: 32px 32px 0 0;
        }
    }
}

@supports (height: 100dvh) {
    .page {
        width: 95dvw;
        height: 95dvh;
        margin: 2.5dvh auto;
    }
    .page:last-child {
        width: 100dvw;
        height: 97.5dvh;
    }
}
