.selectionBlock,
.selectionTaskBlock {
    display: flex;
    background-color: var(--white-pressed);
    border-radius: 12px;
    gap: var(--_10px);
    margin: 0 auto;
}

.selectionTaskBlock {
    width: 100%;
    max-width: fit-content;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.button {
    padding: var(--_16px) var(--_20px);
    border-radius: 12px;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;

    gap: var(--_16px);
    width: 100%;
    min-width: 120px;
    text-wrap: nowrap;
    font-size: var(--_16px);
    cursor: pointer;
}

.button > p {
    font-size: var(--_16px);
}

.focus {
    transition: all 0.2s linear;
    background-color: #ffffff;
    box-shadow: 0px 4px 26.9px 0px var(--landing-transparent-shadow);
}

.optionContainer {
    width: 100%;
    min-height: var(--_48px);
    height: var(--_48px);
    border-radius: 12px;
    border: 1px solid var(--primary-light-gray);
    background: var(--white);
    padding: var(--_12px);
    display: flex;
    gap: var(--_24px);
    justify-content: center;
    white-space: nowrap;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s;
}

.iconContainer {
    width: var(--_36px);
    min-width: var(--_36px);
    height: var(--_36px);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: var(--_16px);
    line-height: var(--_22px);
}

.optionContainer.exercise .iconContainer {
    background: var(--blue-transparent);
}

.optionContainer.track .iconContainer {
    background: var(--light-purple);
}

.optionContainer.situation .iconContainer {
    background: var(--orange-transparent);
}

.optionContainer.words .iconContainer {
    background: var(--words-green-transparent-hover);
}

.optionContainer.exercise.active {
    background: var(--blue-transparent);
    border: 1px solid var(--blue);
}

.optionContainer.exercise.active .iconContainer {
    background: var(--blue);
}

.optionContainer.track.active {
    background: var(--light-purple);
    border: 1px solid var(--purple);
}

.optionContainer.track.active .iconContainer {
    background: var(--purple);
}

.optionContainer.situation.active {
    background: var(--orange-transparent);
    border: 1px solid var(--orange);
}

.optionContainer.situation.active .iconContainer {
    background: var(--orange);
}

.optionContainer.words.active {
    background: var(--words-green-transparent-hover);
    border: 1px solid var(--words-green);
}

.optionContainer.words.active .iconContainer {
    background: var(--words-green);
}

.optionContainer.active .iconContainer path,
.optionContainer.active .iconContainer rect {
    stroke: white;
}

.iconContainer svg {
    width: var(--_18px);
    height: var(--_18px);
}

@media (hover: hover) {
    .optionContainer:not(.active):hover {
        box-shadow: 0px 4px 26.9px 0px var(--landing-transparent-shadow);
    }
}
