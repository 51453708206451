.previewContainer {
    width: 100%;
    height: 100%;
    border: 1px solid var(--border-light-gray);
    border-radius: 12px;
    overflow: hidden scroll;
    display: flex;
    flex-direction: column;
}

.wordCard {
    width: 100%;
    height: max-content;
    max-height: unset;
    position: static;
    border: none;
    box-shadow: none;
    overflow: visible;
    position: relative;
    top: 0;
    left: 0;
}

.dictionaryPlaceholder {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border: 1px solid var(--border-light-gray);
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dictionaryIcon,
.videoIcon,
.exerciseIcon,
.situationIcon {
    height: 85px;
    width: 85px;
    border-radius: 16px;
    background: var(--primary-light-gray);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dictionaryIcon svg {
    width: 40px;
    height: 40px;
}

.videoIcon svg {
    width: 40px;
    height: 40px;
}

.videoIcon svg * {
    stroke: var(--text-light-gray);
}

.videoIcon svg path:last-child {
    fill: transparent;
}

.exerciseIcon svg {
    width: 40px;
    height: 40px;
}

.exerciseIcon svg * {
    stroke: var(--text-light-gray);
}

.situationIcon svg {
    width: 40px;
    height: 40px;
}

.situationIcon svg * {
    stroke: var(--text-light-gray);
}

.selectWordText {
    font-family: Noto Sans;
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: center;
    color: var(--text-light-gray);
}
