.navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px;
    min-height: 45px;
    margin: 10px auto;
    gap: 10px;
    position: relative;
}
