* {
    color: #1c1c1e;
}

.languagesBar {
    position: absolute;
    left: 50%;
    bottom: 0%;
    transform: translate(-50%, -100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--_12px);
    z-index: 1;
    width: 100%;
}

.languagesBar p {
    font-weight: 500;
    font-size: var(--_16px);
    color: #1c1c1eb2;
}

.languagesSection {
    display: flex;
    gap: var(--_8px);
}
