.container {
    width: 100%;
    height: 100%;
    min-height: 600px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.textCont {
    width: 90%;
    height: 80%;
    max-height: 600px;
    margin-top: auto;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 12px;
    letter-spacing: 0px;
    text-align: center;
    z-index: 2;
}

.textCont span {
    margin: 0 3px;
    padding: 0px 3px;
    border-radius: 12px;
    color: inherit;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    transition:
        color 1.5s,
        background-color 1.5s;
    line-height: 90%;
    font-family: 'Noto Sans';
}

.textCont.highlighted span {
    color: var(--black);
    background: var(--white-transparent);
}

.gradient {
    width: 100%;
    max-width: 100vw;
    height: 100dvh;
    min-height: 600px;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    transform: scale(1.5);
}

.decoration {
    position: absolute;
}

.decoration:nth-child(1) {
    top: 0;
    left: 0;
}

.decoration:nth-child(2) {
    top: 0;
    right: 0;
}

.decoration:nth-child(3) {
    bottom: -20%;
    right: -25%;
}

.buttonCont {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.buttonContText {
    width: 100%;
    max-width: 450px;
    text-align: right;
    margin: 0;
    margin-left: auto;
}

@media (max-width: 768px) {
    .textCont {
        justify-content: space-evenly;
    }
    .textCont span {
        margin: 0;
    }
    .buttonContText {
        max-width: 80%;
    }
}

@media (max-width: 768px) {
    .buttonCont {
        flex-direction: column;
        justify-content: center;
        padding: 0;
        padding-bottom: 24px;
        width: 100%;
    }
    .buttonContText {
        text-align: center;
        margin: 0 auto;
    }
    .decoration:nth-child(1) {
        top: -10%;
        left: -10%;
        transform: scale(0.8);
    }
    .decoration:nth-child(2) {
        top: -15%;
        right: -5%;
        transform: scale(0.8);
    }
    .decoration:nth-child(3) {
        bottom: -20%;
        right: -20%;
        transform: scale(1.2) rotate(15deg);
    }
}
