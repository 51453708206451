.flexContainer {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
}

.flexContainer > * {
    flex: 1;
}

.wideComponent {
    width: 100%;
}

.currentTopicContainer {
    margin-left: -4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% + 4px);
    height: 32px;
    background-color: var(--transparent-gray);
    border-radius: 8px;
    padding: 4px;
    padding-right: 0;
}

.currentTopicContainer p {
    font-size: 12px;
}

.buttonsContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: auto;
}

.searchContainer {
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 12px;
}
