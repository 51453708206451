.checkbox {
    appearance: none;
    -webkit-appearance: none;
    background: var(--white);
    border-radius: 5px;
    border: 1px solid var(--dialogue-accent-gray);
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    width: 16px;
    height: 16px;
    cursor: pointer;
    position: relative;
}

.checkbox::before {
    content: '';
    width: 6px;
    height: 6px;
    gap: 0px;
    border-radius: 50%;
}

.checkbox.checkmark::before {
    content: url('../../assets/svg/lessons-check_alt.svg');
    width: 8px;
    height: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent !important;
    position: relative;
    top: -1px;
}

.checkbox.pressed:not(:checked) {
    background: var(--white-pressed);
}

.checkbox:checked {
    border: 1px solid var(--purple);
    background: var(--purple);
}

.checkbox.pressed:checked {
    background: var(--purple-pressed);
}

.checkbox:checked::before {
    background: var(--white);
}

.checkbox.situation:checked {
    border: 1px solid var(--orange);
    background: var(--orange);
}

.checkbox.situation.pressed:checked {
    border: 1px solid var(--orange-pressed);
    background: var(--orange-pressed);
}

.checkbox.exercise:checked {
    border: 1px solid var(--blue);
    background: var(--blue);
}

.checkbox.exercise.pressed:checked {
    border: 1px solid var(--blue-pressed);
    background: var(--blue-pressed);
}

.checkbox.words:checked {
    border: 1px solid var(--words-green);
    background: var(--words-green);
}

.checkbox.words.pressed:checked {
    border: 1px solid var(--words-green-pressed);
    background: var(--words-green-pressed);
}

.checkbox:checked::before {
    background: var(--white);
}

.label span {
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    text-align: center;
    color: var(--black);
}

.label {
    display: flex;
    gap: 10px;
    height: min-content;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
}

@media (hover: hover) {
    .checkbox:not(.pressed):not(:checked):hover {
        background: var(--white-hover);
    }

    .checkbox:not(.pressed):checked:hover {
        background: var(--purple-hover);
    }

    .checkbox:not(.pressed).situation:checked:hover {
        background: var(--orange-hover);
    }

    .checkbox:not(.pressed).words:checked:hover {
        background: var(--words-green-hover);
    }

    .checkbox:not(.pressed).exercise:checked:hover {
        background: var(--blue-hover);
    }
}
