.learnContainer,
.modalContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.containerInner {
    border: 1px solid var(--border-light-gray);
    border-radius: 12px;
    overflow: hidden scroll;
    padding: 16px 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.modalContainer .containerInner {
    border: none;
}

.modeContainer {
    flex-grow: 1;
    position: relative;
    background: var(--primary-light-gray);
    border-radius: 8px;
    overflow: hidden;
}

.modeSelector {
    display: flex;
    flex-wrap: nowrap;
    gap: 12px;
    margin-bottom: 12px;
}

.mode {
    width: 100%;
    height: 28px;
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s;
    border: 1px solid transparent;
}

.card {
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--white);
    box-shadow: 0px 10px 60px 0px var(--black-transparent);
    border-radius: 28px;
    padding: 16px;
    width: fit-content;
    min-width: 450px;
    max-width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    cursor: grab;
}

.card * {
    font-weight: 300 !important;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    position: relative;
}

.situationTitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
}

.buttonIcon {
    height: 12px;
    width: 20px;
    min-width: 20px;
    margin-left: -6px;
}

.buttonIcon path {
    stroke: var(--white);
}

.buttonIcon.dark path {
    stroke: var(--black);
}

.genderIcon {
    min-width: 16px;
    margin-left: -3px;
}

.genderIcon path {
    fill: var(--white);
}

.genderIcon.dark path {
    fill: var(--black);
}

.happy,
.neutral,
.sad {
    min-width: 12px;
}

.happy path,
.neutral path,
.sad path {
    stroke: var(--black);
}

.icons {
    display: flex;
    align-items: center;
    width: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.icons svg:first-child path {
    fill: var(--black);
}

.icons svg:last-child path {
    stroke: var(--black);
}

.icons svg:first-child {
    width: 16px;
    height: 20px;
    margin-right: -3px;
    margin-left: 2px;
}

.icons svg:last-child {
    width: 16px;
    height: 12px;
    margin-left: -1px;
}

.icons.white svg:first-child path {
    fill: var(--white);
}

.icons.white svg:last-child path {
    stroke: var(--white);
}

.progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
    gap: 4px;
    height: 5px;
    padding: 0 6px;
}

.progressChip {
    content: '';
    width: 100%;
    height: 100%;
    flex: 1;
    border-radius: 8px;
    background: var(--dialogue-accent-gray);
}

.progressChip.active {
    background: var(--purple);
}

.statementControls {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    width: calc(100% - 28px);
    left: 14px;
}

.arrowButton {
    width: 44px;
    min-width: 44px;
    padding: 0;
    height: 44px;
    max-height: 44px;
    border-radius: 50%;
    box-shadow: 0px 3px 8px 0px var(--black-transparent);
    position: relative;
}

.arrowIcon {
    height: 16px;
    min-height: 16px;
    width: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
}

.arrowButton:first-child .arrowIcon {
    transform: translate(-50%, -50%) rotate(-90deg);
}

.arrowButton:last-child .arrowIcon {
    transform: translate(-50%, -50%) rotate(90deg);
}

.arrowIcon path {
    stroke: var(--black);
    stroke-opacity: 1;
}

.settingsButton {
    cursor: pointer;
    width: 38px;
    min-width: 38px;
    height: 38px;
    border-radius: 8px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    position: relative;
}

.settingsButton svg {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.settingsModal {
    padding: 25px;
    background: var(--white);
    border-radius: 16px;
    position: relative;
    overflow: auto;

    max-width: 1090px;
    min-width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 25px;
    max-height: 95vh;
    overflow: scroll;
}

.settingsModal > * {
    width: 100%;
}

.microButtons {
    display: flex;
    gap: 6px;
}

.settingsTitle {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: -8px;
}

.settingsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px 16px;
}

.switchContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--primary-light-gray);
    border-radius: 12px;
    height: 60px;
    padding: 16px;
    box-sizing: border-box;
    min-width: 25vw;
}

.horizontalContainer {
    margin-top: 30px;
    display: flex;
    gap: 24px;
    width: 100%;
}

.packSizeSelector {
    width: 100%;
    padding: 4px;
    height: 42px;
    border-radius: 10px;
    background: var(--primary-light-gray);
    position: relative;
}

.toggleLabel,
.rangeLabel {
    font-size: 14px;
    color: var(--text-light-gray-secondary);
    text-align: left;
    width: 100%;
    font-family: Noto Sans;
    font-weight: 300;
    line-height: 22px;
}

.toggleLabel {
    top: 0%;
    transform: translateY(calc(-100% - 5px));
    position: absolute;
}

.rangeLabel {
    margin-bottom: -10px;
}

.iconMe,
.iconThey {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: fit-content;
    margin: 0 auto;
    padding: 2px 6px;
    border-radius: 16px;
}

.iconMe span,
.iconThey span {
    font-size: 12px;
}

.iconThey {
    background: var(--primary-light-gray);
}

.iconThey path,
.iconThey span {
    color: var(--black);
    stroke: var(--black);
}

.iconMe {
    background: var(--dialogue-accent-purple);
}

.iconMe path,
.iconMe span {
    color: var(--white);
    stroke: var(--white);
}

.equalizer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5px;
}

.equalizer span {
    content: '';
    width: 1.5px;
    height: 12px;
    border-radius: 12px;
}

.iconMe .equalizer span {
    background: var(--white);
}

.iconThey .equalizer span {
    background: var(--black);
}

.equalizer.active span {
    animation: equalize 1s infinite ease-in-out;
}

.equalizer span:nth-child(4) {
    animation-delay: 0s;
}
.equalizer span:nth-child(3) {
    animation-delay: 0.2s;
}
.equalizer span:nth-child(5) {
    animation-delay: 0.4s;
}
.equalizer span:nth-child(2) {
    animation-delay: 0.6s;
}
.equalizer span:nth-child(1) {
    animation-delay: 0.8s;
}

.emptyAlert {
    margin: auto;
    text-align: center;
    color: var(--text-light-gray-secondary);
}

@keyframes equalize {
    0%,
    100% {
        height: 6px;
    }
    50% {
        height: 12px;
    }
}
