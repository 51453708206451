.userItem {
    display: flex;
    border: 1px solid var(--secondary-light-gray);
    border-radius: 20px;
    overflow: hidden;
    min-height: 20px;
    flex-shrink: 0;
}

.userCell {
    display: block;
    padding: 6px 12px;
    width: auto;
    box-sizing: border-box;
    overflow-x: auto;
    overflow-y: hidden;
    text-align: center;
    background-color: var(--white-pressed);
    white-space: nowrap;
    text-overflow: ellipsis;
}

.userCell:nth-child(1) {
    flex: 0 1 20%;
    font-weight: 500;
    cursor: pointer;
}

.userCell:nth-child(2) {
    flex: 0 1 20%;
    font-weight: 500;
    background-color: var(--white);
}

.userCell:nth-child(3) {
    flex: 0 1 15%;
}

.userCell:nth-child(4) {
    flex: 0 1 15%;
    text-transform: uppercase;
    background-color: var(--white);
}

.userCell:nth-child(5) {
    flex: 0 1 15%;

    text-transform: uppercase;
}

.userCell:nth-child(6) {
    flex: 0 1 15%;
    text-transform: uppercase;
    background-color: var(--white);
}

.userItem:last-child {
    margin-bottom: 30px;
}
