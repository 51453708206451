.sentence {
    margin: 0;
    line-height: 1.3;
    transition: all 0.1s;
    padding: 3px;
    display: flex;
    flex-wrap: wrap;
    color: var(--black);
    cursor: text;
    position: relative;
}

.sentence * {
    font-family: Noto Sans;
}

.span {
    padding: 0px 2.3px;
    height: 20px;
}

.sentence.active .span.active {
    background: var(--dark-gray);
    color: var(--white);
}

.sentence:last-child {
    padding-bottom: 200px;
}
