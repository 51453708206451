.themeWrap {
    min-width: calc(33% - 5px);
    max-width: calc(33% - 5px);
    margin-right: 12px;
    height: 100%;
    border-radius: 12px;
}

.theme {
    width: 100%;
    height: 100%;
    user-select: none;
    border-radius: 12px;
    border: 1px solid var(--primary-light-gray);
    background: var(--light-gray);
    padding-top: 10px;
}

.theme:last-child {
    margin-right: 0;
}

.lessons {
    padding: 16px;
    padding-top: 10px;
    overflow: scroll;
    height: 100%;
}

.lessons::-webkit-scrollbar {
    width: 0;
}

.title {
    padding: 0px 0px 0px 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    height: 32px;
    text-align: left;
    background: transparent;
    border: transparent;
}

.emptyThemeAlert {
    font-size: 12px;
    color: var(--text-light-gray-secondary);
    margin: 24px 0px;
    white-space: pre-wrap;
}
