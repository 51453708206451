.flexContainer,
.flexContainerBottom,
.extraParamsContainer {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
}

.flexContainer > *,
.extraParamsContainer > * {
    flex: 1;
    width: 100%;
}

.extraParamsContainer {
    padding-bottom: 19px;
}

.mediaEditor .extraParamsContainer {
    margin-bottom: -24px;
}

.flexContainerBottom > div:first-child {
    flex-grow: 1;
}

.horizontalContainer,
.exerciseTypeContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 12px;
}

.searchFilter {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.exerciseTypeContainer {
    justify-content: space-between;
    height: 100%;
}

.checkboxContainer {
    display: flex;
    gap: 12px;
    height: 32px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: var(--transparent-gray);
    border-radius: 8px;
    padding: 4px;
}

.wideSelect {
    width: 100%;
    min-height: 32px;
}

.loader {
    flex: 1;
    height: 100%;
}

.dialogueLoader {
    max-height: 100px;
    transform: scale(0.6);
}

.currentTopicContainer {
    margin-left: -4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% + 4px);
    height: 32px;
    background-color: var(--transparent-gray);
    border-radius: 8px;
    padding: 4px;
    padding-right: 0;
}

.currentTopicContainer p {
    font-size: 12px;
}

.pathIcon,
.strokeIcon,
.videoIcon,
.ytIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 30px;
    min-width: 40px;
    position: static;
    transform: none;
}

.ytIcon {
    max-width: 30px;
}

.pathIcon path {
    fill: var(--blue);
}

.strokeIcon path {
    stroke: var(--blue);
}

.videoIcon path,
.videoIcon rect {
    stroke: var(--blue);
}

.videoIcon path:last-child {
    stroke: var(--blue);
    fill: var(--blue);
}

.labeledContainer {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.exerciseCardContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    gap: 12px;
}

.exerciseCardContainerAlt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 66% !important;
    height: 50%;
    margin: auto 0;
    gap: 12px;
}

.basicCard {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 12px 14px;
    gap: 8px;
    align-items: center;
    border-radius: 8px;
    border: 1px dashed var(--border-light-gray);
    cursor: pointer;
    transition: background-color 0.2s;
    position: relative;
}

.basicCard.supported {
    border-color: var(--blue);
    border-style: solid;
}

.basicCard.disabled {
    cursor: not-allowed;
    filter: grayscale(1);
}

.basicCard.disabled .basicCardText,
.basicCard.disabled > svg {
    opacity: 0.5;
}

.basicCard svg {
    flex-shrink: 0;
}

.basicCardText {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
    height: 140px;
    justify-content: center;
    overflow: visible;
}

.basicCardText > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    text-transform: capitalize;
    margin: 0;
}

.basicCardText > span {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: var(--text-light-gray-secondary);
    white-space: pre-line;
    margin: 0;
}

.exerciseCardContainer .basicCardText {
    height: 90px;
}

.basicCard svg {
    min-width: 30px;
    min-height: 20px;
}

.basicCard .modalIcon {
    min-width: 40px;
    position: static;
    transform: none;
}

.expandButton {
    font-weight: 400;
    font-size: 12px;
    line-height: 32px;
    color: var(--text-light-gray-secondary);
    transition: color 0.2s;
    white-space: nowrap;
    text-align: left;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    gap: 2px;
    flex: unset;
    width: 150px;
}

.expandButton svg {
    transform: rotate(90deg) translateY(-50%);
    top: 50%;
}

.expandButton svg path {
    transition: all 0.2s;
}

.levelToggle {
    width: fit-content;
}

.textParamsContainer {
    display: flex;
    gap: 12px;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.textParamsContainer > * {
    flex-grow: 1;
    max-width: 50%;
}

.levelContainer {
    max-width: 212px;
}

.textEditorContainer {
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.textEditor {
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    padding: 6px 32px 8px 8px;
    margin: 0;
    border: 0.3px solid var(--border-light-gray);
    border-radius: 8px;
    box-sizing: border-box;
    min-height: 79px;
    line-height: 23px;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    outline: none;
    overflow-y: scroll;
    height: max-content;
    transition: background-color 0.1s;
    resize: none;
}

.videoPreviewContainer {
    margin: 0;
}

.videoPreview {
    min-height: 80px;
    max-height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    padding: 12px 16px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
    background: var(--transparent-gray);
    border-radius: 8px;
}

.imageContainer {
    width: 100px;
    min-width: 100px;
    height: 56px;
    border-radius: 8px;
    transition: margin 0.2s cubic-bezier(0.42, 0, 0.58, 1);
    position: relative;
}

.imageContainer img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 8px;
    opacity: 1;
    animation: fadeIn 0.2s ease-in-out forwards;
}

.cardInner {
    display: flex;
    flex-grow: 1;
    max-width: calc(100% - 116px);
    overflow: visible;
    flex-direction: column;
    gap: 4px;
    position: relative;
}

.cardTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    white-space: pre-wrap;
    text-align: left;
    overflow: hidden;
}

.videoInfoContainer {
    display: flex;
    gap: 4px;
    justify-content: flex-start;
    align-items: center;
    min-height: 12px;
    max-height: 12px;
}

.cardLabel {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: var(--text-light-gray);
    text-transform: uppercase;
    white-space: nowrap;
}

.cardLabel::after {
    content: '∙';
    font-weight: 600;
    margin-left: 4px;
}

.cardLabel:last-child::after {
    display: none;
}

.mediaEditor {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.textContainer {
    width: 100%;
    font-weight: 400;
    padding: 8px 12px;
    margin: 0;
    border: 1px solid var(--border-light-gray);
    border-radius: 8px;
    box-sizing: border-box;
    min-height: 176px;
    max-height: 176px;
    line-height: 23px;
    overflow: hidden scroll;
}

.showMoreCheckbox {
    margin: auto 0px 0px;
}

.transformationControls {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
}

.transformationControls > * {
    flex: 1;
    width: 100%;
}

.controlsContainer {
    position: relative;
}

.mediaEditor:not(.small) {
    margin-top: 18px;
}

.mediaEditor .controlsContainer > div {
    gap: 12px;
}

.mediaEditor.small .controlsContainer > div {
    gap: 12px;
    margin-top: 6px;
    margin-bottom: -24px;
}

.mediaEditor.small .controlsContainer > div > div:first-child {
    flex-grow: 1;
}

.mediaEditor.small .controlsContainer > div > div:last-child {
    max-height: 24px;
    gap: 6px;
}

.mediaEditor.small .controlsContainer > div > div:last-child svg {
    max-height: 22px;
}

.mediaEditor.small .controlsContainer > div {
    flex-direction: row-reverse;
}

.infoText {
    position: relative;
    font-size: 12px;
    line-height: 14px;
    margin-top: -4px;
    color: var(--black);
}

.infoText .limit {
    font-size: 12px;
    line-height: 16px;
    color: var(--primary-red);
}

.infoText .ok {
    font-size: 12px;
    line-height: 16px;
    color: var(--green);
}

.mediaEditContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-height: 220px;
    gap: 12px;
}

.mediaEditContainer > *:first-child {
    max-width: 100%;
}

.moreParamsContainer {
    display: grid;
    grid-template-columns: calc(65% - 3px) 212px;
    height: 100%;
    gap: 12px;
}

.verticalContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.verticalContainer > div {
    width: 100%;
}

.buttonsContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: auto;
}

.tooltip {
    opacity: 0;
    pointer-events: none;
    left: unset;
    top: 5px;
    right: 5px;
    width: min-content;
    transition: opacity 0.1s;
    animation: unset;
}

.tooltip.centered {
    left: 50%;
    top: unset;
    bottom: 5px;
    transform: translate(-50%);
    width: calc(100% + 20px);
    box-shadow: 0px 0px 2px 2px var(--black-transparent);
}

.tooltip.centered span {
    white-space: wrap;
}

.select {
    width: 100%;
}

.searchContainer {
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 12px;
}

.unsupportedAlert {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    color: var(--text-light-red);
    white-space: pre-line;
    margin: 0;
    position: absolute;
    width: 90%;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    text-align: left;
    text-transform: none;
}

.hidden {
    height: 0;
    opacity: 0;
    pointer-events: none;
    display: none;
}

.intro {
    padding: 20px;
    opacity: 0;
    user-select: none;
    background: var(--white);
    border-radius: 16px;
    position: relative;
    transition: opacity 0.5s;
    animation: fadeIn 0.2s ease-in-out forwards;
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 560px;
}

.intro > button {
    margin: 12px auto 0px;
}

.title {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
}

.subtitle {
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    text-align: left;
    color: var(--text-light-gray-secondary);
    margin-top: 12px;
    white-space: pre-line;
    text-align: justify;
}

@media (hover: hover) {
    .basicCard:hover {
        background: var(--blue-transparent);
        border-style: solid;
    }

    .basicCard:hover .tooltip {
        opacity: 1;
    }

    .expandButton:hover,
    .expandButton:hover svg path {
        color: var(--black);
        stroke: var(--black);
        stroke-opacity: 1;
    }
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
