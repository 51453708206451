.lessonHeader {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    min-height: 33px;
    max-height: 33px;
}

.inputContainer {
    width: 300px;
    max-width: 300px;
}

.lessonInfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    width: calc(100% - 30px);
}

.lessonInfoText {
    font-size: 15px;
    font-weight: 400;
    color: var(--text-light-gray-secondary);
    width: fit-content;
    text-align: start;
}

.title {
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    overflow: hidden;
    max-width: calc(100% - 100px);
    width: fit-content;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 20px;
}

.plug {
    max-width: 60%;
    min-width: 60%;
}

.switchModeButton {
    margin-left: auto;
}

.targetLangSelect {
    min-width: 200px;
}

.switchModeButton,
.targetLangSelect {
    box-shadow: 0px 0px 5px 0.3px var(--text-light-gray);
}
