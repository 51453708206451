.pronunciationCard {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 20px;
}

.translationText {
    color: var(--text-light-gray-secondary);
    transition: filter 0.2s;
    user-select: none;
    text-align: center;
    margin-bottom: 8px;
}

.translationText.active {
    color: var(--black);
    font-size: 22px;
}

.translationText.correct {
    color: var(--primary-green);
}

.translation,
.errorAlert {
    color: var(--text-light-gray-secondary);
    transition: filter 0.2s;
    user-select: none;
    text-align: center;
}

.translation {
    color: var(--black);
    cursor: pointer;
    margin: 30px 0;
}

.translation.blur {
    filter: blur(5px);
}

.recognitionBlock {
    margin-top: auto;
}

.recognizedText {
    padding: 6px 12px;
    border-radius: 24px;
    border: 1px solid var(--secondary-light-gray);
    background-color: var(--secondary-light-gray);
    min-width: 50%;
    font-weight: 300;
    font-size: 22px;
    line-height: 29px;
    min-height: 29px;
    text-align: center;
}

.recognizedText.correct {
    border: 0.5px solid var(--green);
    background: var(--green-transparent);
}

.recognizedText.wrong {
    background: var(--primary-transparent-red);
    border: 0.5px solid var(--primary-red);
    font-weight: 300;
}

.recognizedText span {
    font-size: inherit;
    color: inherit;
}

.playButton {
    height: 56px;
    width: 56px;
    padding: 0;
    border-radius: 50%;
    position: relative;
    margin-bottom: 20px;
}

.playButton svg {
    height: 16px;
    width: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.playButton path {
    stroke: var(--black);
}

.dotsCont {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 32px 0;
    margin: 0 -5%;
    width: 100%;
    overflow: hidden;
}

.noPermissionAlert {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: center;
    color: var(--dark-gray);
    opacity: 0.66;
}

.dots {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--transparent-gray);
    color: var(--transparent-gray);
    box-shadow:
        9984px 0 0 0 var(--transparent-gray),
        9999px 0 0 0 var(--transparent-gray),
        10014px 0 0 0 var(--transparent-gray);
    animation: dots 1.5s infinite linear;
}

.resultsCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    height: 100%;
}

.sentenceResult {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sentenceResult .translation {
    margin: 0;
}

.sentenceResult .translation:last-child {
    color: var(--text-light-gray-secondary);
}

@keyframes dots {
    0% {
        box-shadow:
            9984px 0 0 -1px var(--transparent-gray),
            9999px 0 0 1px var(--transparent-gray),
            10014px 0 0 -1px var(--transparent-gray);
    }
    50% {
        box-shadow:
            10014px 0 0 -1px var(--transparent-gray),
            9984px 0 0 -1px var(--transparent-gray),
            9999px 0 0 1px var(--transparent-gray);
    }
    100% {
        box-shadow:
            9999px 0 0 1px var(--transparent-gray),
            10014px 0 0 -1px var(--transparent-gray),
            9984px 0 0 -1px var(--transparent-gray);
    }
}

@media (hover: hover) {
    .audioBtn:hover {
        background: var(--secondary-light-gray);
    }
    .recordBtn:hover {
        box-shadow: 0px 0px 10px 5px var(--black-transparent);
    }
}
