* {
    user-select: none;
}

.profitSectionContainer {
    margin: 100px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    position: relative;
}

.title {
    font-size: var(--_36px);
    font-weight: 500;
    line-height: var(--_42px);
    letter-spacing: -0.02em;
    color: var(--black-hover);
    max-width: 55vw;
    margin-bottom: var(--_12px);
}

.subtitle {
    font-size: var(--_16px);
    font-weight: 400;
    line-height: var(--_22px);
    letter-spacing: -0.02em;
    color: #1c1c1eb2;
    max-width: 55vw;
    margin-bottom: var(--_40px);
}

.subtitle span {
    font-size: var(--_16px);
    font-weight: 500;
    line-height: var(--_22px);
    letter-spacing: -0.02em;
    color: var(--black);
}

.cardsBlock {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: var(--_16px);
    width: 100%;
    max-width: 100%;
    justify-content: center;
    padding: 0 var(--_24px);
    background: var(--white);
}

.profitSectionContainer .logoES {
    width: 14vw;
    height: 14vw;
    top: -11%;
    right: -4%;
    left: unset;
    z-index: -2;
    transform: rotate(-45deg);
}
