.learnWords {
    width: 100%;
    position: relative;
    border: 1px solid var(--border-light-gray);
    border-radius: 12px;
    overflow: hidden;
}

.learnWords::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
}

.learnWords.continue::before {
    background: var(--green-transparent);
}

.learnWords.repeat::before {
    background: var(--primary-transparent-red);
}
