.mediaEditor {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.hideYT {
    visibility: hidden;
    height: 0;
}

.textContainer {
    width: 100%;
    font-weight: 400;
    padding: 8px 12px;
    margin: 0;
    border: 1px solid var(--border-light-gray);
    border-radius: 8px;
    box-sizing: border-box;
    min-height: 250px;
    max-height: 250px;
    line-height: 23px;
    overflow: hidden scroll;
}

.controlsContainer {
    position: relative;
}

.trackInfo {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    margin-bottom: -12px;
}

.trackTitle,
.trackLevel {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.trackLevel {
    color: var(--text-light-gray);
}

.trackInfo > *:nth-child(3) {
    margin-left: auto;
}

.infoText {
    position: relative;
    font-size: 12px;
    line-height: 14px;
    margin-top: -4px;
    color: var(--black);
}

.infoText .limit {
    font-size: 12px;
    line-height: 16px;
    color: var(--primary-red);
}

.infoText .ok {
    font-size: 12px;
    line-height: 16px;
    color: var(--green);
}

.collapseIcon {
    min-width: 12px;
    min-height: 12px;
}

.collapseIcon path {
    fill: var(--night-gray);
    fill-opacity: 0.6;
}
