.container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.buttonsCont {
    display: flex;
    justify-content: space-between;
}

.titleCont {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 44px;
    min-height: 44px;
    position: relative;
    width: fit-content;
    margin: 0 auto;
    padding: 0 20px;
    cursor: pointer;
}

.situationTitle {
    margin: 10px 0;
    min-height: 24px;
    width: fit-content;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    text-wrap: nowrap;
    font-size: 18px;
    font-weight: 400;
    text-transform: capitalize;
}

.editIcon {
    position: absolute;
    right: -10px;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.2s;
}

.editIcon path {
    fill: var(--dark-gray);
}

.closeIcon {
    min-width: 16px;
}

.closeIcon path {
    fill: var(--white);
}

.levelOptions {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    min-height: 44px;
    gap: 10px;
}

.selectCategoryCont {
    position: relative;
}

.selectLabel {
    position: absolute;
    top: 0;
    left: 10px;
    transform: translateY(-100%);
    font-size: 12px;
    font-size: 12px;
    color: var(--text-light-gray-secondary);
    white-space: nowrap;
}

@media (hover: hover) {
    .titleCont:hover .editIcon {
        opacity: 1;
    }
}
