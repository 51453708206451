.addButton {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: var(--text-light-gray-secondary);
    position: relative;
    margin-left: 10px;
    margin-bottom: 12px;
    transition: color 0.2s;
    width: min-content;
    white-space: nowrap;
    cursor: pointer;
}

.addButton.disabled {
    filter: saturate(0);
    opacity: 0.5;
    pointer-events: none;
}

.addButton::before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -10px;
    content: '+';
}

@media (hover: hover) {
    .addButton:hover {
        color: var(--black);
    }
}
