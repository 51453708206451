.keyboardCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 60px 20px;
}

.answerCont {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 6px;
    padding: 20px;
    margin-top: 100px;
    transition: opacity 0.8s;
}

.letter {
    border-radius: 6px;
    padding: 5px 8px 9px 8px;
    height: 36px;
    width: 36px;
    min-width: 25px;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    text-transform: lowercase;
    color: var(--black);
    cursor: pointer;
    user-select: none;
    background: var(--transparent-gray);
    border: 0.5px solid var(--transparent-gray);
}

.answerCont.correct .letter {
    background: var(--green-transparent);
    border: 0.5px solid var(--green);
}

.answerCont.wrong .letter {
    background: var(--primary-transparent-red);
    border: 0.5px solid var(--primary-red);
}

.translationText {
    font-size: 15px;
    text-align: center;
    font-weight: 400;
    line-height: 20px;
    color: var(--text-light-gray-secondary);
    user-select: none;
    transition: filter 0.2s;
    cursor: pointer;
}

.translationText.blur {
    filter: blur(5px);
}

.playButton {
    height: 56px;
    width: 56px;
    padding: 0;
    border-radius: 50%;
    position: relative;
    margin-top: 20px;
}

.playButton svg {
    height: 16px;
    width: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.playButton path {
    stroke: var(--black);
}
