.wordsWrapper {
    gap: 12px;
    padding-bottom: 20px;
    max-width: 100%;
}

.wordsWrapper.modal {
    padding: 20px;
}

.wordsWrapper.learn {
    padding: 0;
    margin: 20px;
    position: absolute;
    top: -21px;
    left: -21px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
}

.wordsList {
    position: relative;
}

.wordsList.demo {
    display: flex;
    flex-grow: 1;
}

.chaptersContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    position: relative;
    overflow: scroll;
}

.modesContainer {
    position: absolute;
    bottom: 20px;
    transform: translate(-50%, -100%);
    left: 50%;
    width: calc(100% - 20px);
    background: var(--primary-light-gray);
    padding: 4px;
    height: 42px;
    border-radius: 10px;
    box-shadow: 0px 3px 4px 0px var(--black-transparent);
}

.modeToggle,
.modeToggle > * {
    font-size: 15px;
}

.learnButton {
    width: 100%;
    font-size: 12px;
}

.addWordButton {
    cursor: pointer;
    width: 38px;
    min-width: 38px;
    height: 38px;
    border-radius: 8px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.addWordButton {
    height: 32px;
    width: 32px;
    min-width: 32px;
}

.addWordButton svg {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.addWordButton path {
    fill: var(--black);
}

.settingsModal {
    padding: 25px;
    background: var(--white);
    border-radius: 16px;
    position: relative;
    min-width: 250px;
    max-width: 1090px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 25px;
    max-height: 95vh;
    overflow: scroll;
}

.settingsModal > div {
    width: 100%;
}

.settingsTitle {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: -8px;
}

.settingsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px 16px;
}

.switchContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--primary-light-gray);
    border-radius: 12px;
    height: 60px;
    padding: 16px;
    box-sizing: border-box;
    min-width: 25vw;
}

.label,
.toggleLabel {
    font-size: 14px;
    color: var(--black);
    text-align: left;
    width: 100%;
}

.toggleLabel {
    margin-bottom: -20px;
}

.packSizeSelector {
    width: 100%;
    padding: 4px;
    height: 42px;
    border-radius: 10px;
    background: var(--primary-light-gray);
}

.rangeSelector {
    width: 100%;
}

.emptyAlert {
    margin: auto;
    text-align: center;
    color: var(--text-light-gray-secondary);
}

.microButtons {
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    gap: 6px;
    z-index: 3;
}
