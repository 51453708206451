.h2,
.h2 * {
    font-family: Montserrat;
    font-size: 96px;
    font-weight: 600;
    line-height: 108px;
    text-align: center;
    white-space: pre-line;
    margin: 0 auto;
    max-width: 85%;
}

.h3,
.h3 * {
    font-family: Montserrat;
    font-size: 72px;
    font-weight: 600;
    line-height: 80px;
    letter-spacing: 0px;
    text-align: center;
    white-space: pre-line;
    margin: 0 auto;
    max-width: 75%;
}

.h4,
.h4 * {
    font-family: Montserrat;
    font-size: 34px;
    font-weight: 400;
    line-height: 41px;
    letter-spacing: 0px;
    text-align: center;
    white-space: pre-line;
    margin: 0 auto;
    max-width: 65%;
}

.h5,
.h5 * {
    font-family: Noto Sans;
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
    white-space: pre-line;
    margin: 0 auto;
    max-width: 65%;
}

.h6,
.h6 * {
    font-family: Noto Sans;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    white-space: pre-line;
    margin: 0 auto;
    max-width: 65%;
}

@media (max-width: 1368px) {
    .h2,
    .h2 * {
        font-size: 86px;
        line-height: 98px;
    }
    .h3,
    .h3 * {
        font-size: 65px;
        line-height: 73px;
    }
    .h4,
    .h4 * {
        font-size: 34px;
        line-height: 41px;
    }
    .h5,
    .h5 * {
        font-size: 20px;
        line-height: 25px;
    }
}

@media (max-width: 1024px) {
    .h2,
    .h2 * {
        font-size: 65px;
        line-height: 74px;
        max-width: 75%;
    }
    .h3,
    .h3 * {
        font-size: 49px;
        line-height: 55px;
        max-width: 75%;
    }
    .h4,
    .h4 * {
        font-size: 23px;
        line-height: 27px;
        max-width: 70%;
    }
    .h5,
    .h5 * {
        font-size: 15px;
        line-height: 18px;
        max-width: 70%;
    }
}

@media (max-width: 768px) {
    .h2,
    .h2 * {
        font-size: 56px;
        line-height: 62px;
        max-width: 85%;
    }
    .h3,
    .h3 * {
        font-size: 40px;
        line-height: 51px;
        max-width: 85%;
    }
    .h4,
    .h4 * {
        font-size: 22px;
        line-height: 28px;
        max-width: 85%;
    }
    .h5,
    .h5 * {
        font-size: 17px;
        line-height: 22px;
        max-width: 85%;
    }
    .h6,
    .h6 * {
        font-size: 15px;
        line-height: 20px;
    }
}

@media (max-width: 480px) {
    .h2,
    .h2 * {
        font-size: 32px;
        line-height: 35px;
        max-width: 95%;
    }
    .h3,
    .h3 * {
        font-size: 32px;
        line-height: 35px;
        max-width: 95%;
    }
    .h4,
    .h4 * {
        font-size: 17px;
        line-height: 22px;
        max-width: 95%;
    }
    .h5,
    .h5 * {
        font-size: 15px;
        line-height: 20px;
        max-width: 95%;
    }
}
