.container {
    width: 100%;
    height: 100%;
    overflow: scroll hidden;
}

.sliderContainer {
    display: grid;
    align-items: center;
    height: 100%;
    grid-auto-flow: column;
    grid-auto-columns: 50%;
    overflow: scroll hidden;
    overscroll-behavior: contain;
    padding-top: min(100px, 10%);
}

.sliderContainer.compact {
    grid-auto-columns: 40%;
}

.sliderContainer.snap {
    scroll-snap-type: x mandatory;
}

.sliderContainer.show:first-child {
    margin-left: -50%;
}

.sliderContainer::-webkit-scrollbar {
    height: 0;
    width: 0;
    display: none;
}

@media (max-width: 768px) {
    .sliderContainer,
    .sliderContainer.compact {
        grid-auto-columns: 100%;
    }
}
