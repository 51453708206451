.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttons.large {
    gap: 18px;
}

.buttons.small {
    gap: 16px;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    cursor: pointer;
    transition: all 0.2s;
}

.button svg {
    width: 100%;
    height: 100%;
}

.buttons.large .button {
    height: 64px;
    border-radius: 15px;
    border: 1.5px solid var(--black);
}

.buttons.large .button:first-child {
    width: 195px;
}

.buttons.large .button:last-child {
    padding: 9px;
    width: 215px;
}

.buttons.small .button {
    width: 120px;
    height: 40px;
    border-radius: 10px;
    border: 1px solid var(--black);
}

.buttons.small .button:first-child {
    width: 105px;
}

.buttons.small .button:first-child svg {
    min-width: 110px;
    min-height: 35px;
}

.buttons.small .button:last-child {
    padding: 6.5px;
    width: 125px;
}

.buttons.medium {
    gap: 10px;
    /* flex-direction: column;
    align-items: flex-start; */
}

.buttons.medium .button {
    width: 145px;
    border-radius: 6px;
    padding: 0 6px;
}

.buttons.medium .button:last-child {
    padding: 6px 12px;
}

.buttons.medium_landing {
    gap: 10px;
}

.buttons.medium_landing .button {
    width: 200px;
    height: 64px;
    border-radius: 6px;
    padding: 0 6px;
}

.buttons.grey .button {
    background: var(--primary-light-gray);
    border: none;
}

.buttons.white .button {
    background: var(--white);
    border: none;
}

@media (hover: hover) {
    .button:hover {
        transform: scale(1.02);
    }
}
