.datePicker input {
    width: 100%;
    padding: 0px 15px;
    margin: 0;
    background: var(--white);
    border: 1px solid var(--transparent-gray);
    height: 38px;
    max-height: 38px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    outline: none;
    border-radius: 20px;
}

.datePicker * {
    font-family: 'Noto Sans', sans-serif;
}

.label {
    position: relative;
    left: 10px;
    font-size: 12px;
    line-height: 16px;
    color: var(--text-light-gray-secondary);
    white-space: nowrap;
}
