.wordsContainer {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 30% 70%;
    height: 100%;
    max-height: calc(100% - 170px);
    gap: 20px;
}

.emptyAlert {
    margin: auto;
}
