.modalInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.title {
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 24px;
}

.horizontalContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
}

.imageCont {
    width: 30vw;
    max-width: 460px;
    height: 100px;
    border-radius: 20px;
    margin: 25px 0;
    position: relative;
}

.imageCont img {
    width: 30vw;
    max-width: 460px;
    height: 100px;
    object-fit: cover;
    border-radius: 20px;
}

.imageCont.placeholder {
    background: var(--primary-light-gray);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
}

.entityTitle,
.infoObj {
    background: var(--text-light-gray-secondary);
    color: var(--white);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    border-radius: 14px;
    backdrop-filter: blur(2px);
    z-index: 2;
}

.entityTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    max-height: 60%;
    max-width: 80%;
    padding: 8px 16px;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.infoCont {
    position: absolute;
    bottom: 3px;
    right: 3px;
    z-index: 3;
    display: flex;
    gap: 3px;
    max-width: 100%;
}

.infoObj {
    display: flex;
    gap: 3px;
    padding: 3px;
    align-items: center;
}

.icon svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
}

.friendly path {
    fill: var(--primary-green);
}

.neutral path {
    fill: var(--yellow);
}

.rude path {
    fill: var(--primary-red);
}

.error {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -150%);
    width: max-content;
    white-space: nowrap;
    color: var(--primary-red);
}

@media (hover: hover) {
    .imageCont.placeholder:hover {
        background: var(--primary-light-gray);
        box-shadow: 0px 5px 11px 0px var(--black-transparent-secondary);
    }
}
