* {
    font-family: Noto Sans !important;
}

.h1,
.h1 * {
    font-size: 46px;
    font-weight: 500;
    line-height: 56px;
    text-align: center;
}

.h2,
.h2 * {
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    white-space: pre-line;
    margin: 0 auto;
    max-width: 70%;
}

.h3,
.h3 * {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    white-space: pre-line;
    margin: 0 auto;
    max-width: 70%;
}

.h4,
.h4 * {
    font-size: 34px;
    font-weight: 400;
    line-height: 41px;
    letter-spacing: 0px;
    text-align: center;
    white-space: pre-line;
    margin: 0 auto;
    max-width: 65%;
}

.h5,
.h5 * {
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
    white-space: pre-line;
    margin: 0 auto;
    max-width: 65%;
}

.h6,
.h6 * {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    white-space: pre-line;
    margin: 0 auto;
    max-width: 65%;
}
