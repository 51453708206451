.modalInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    padding: 25px 50px;
}

.title {
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 24px;
}

.imageCont {
    width: 250px;
    height: 100px;
    border-radius: 20px;
}

.imageCont img {
    width: 250px;
    height: 100px;
    object-fit: cover;
    border-radius: 20px;
}

.imageCont.placeholder {
    background: var(--primary-light-gray);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
}

@media (hover: hover) {
    .imageCont.placeholder:hover {
        background: var(--primary-light-gray);
        box-shadow: 0px 5px 11px 0px var(--black-transparent-secondary);
    }
}
