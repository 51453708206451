.plusIcon path {
    stroke-opacity: 1;
    stroke: var(--white);
}

.wizardModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 680px;
    height: 440px;
    max-height: 440px;
    overflow: hidden;
    padding: 0 24px 24px;
    background: var(--white);
    border-radius: 16px;
    position: relative;
    min-width: 250px;

    max-width: 1090px;
}

.wizardModal.hidden {
    opacity: 0;
    pointer-events: none;
}

.wizardModal > * {
    width: 100%;
}

.modalInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
    height: 100%;
    overflow: visible;
    padding-top: 10px;
}

.modalInner > * {
    width: 100%;
}

.title,
.shiftedTitle {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    width: calc(100% + 48px);
    padding: 14px 24px;
    background-position: calc(50% - 50px) calc(50% + 6px);
}

.shiftedTitle {
    padding-left: 34px;
}

.title.exercise,
.shiftedTitle.exercise {
    background-image: url('../../../../assets/svg/wizard-exercise-background.svg');
    background-position: calc(50% - 50px) calc(50% + 12px);
}

.title.words,
.shiftedTitle.words {
    background-image: url('../../../../assets/svg/wizard-words-background.svg');
}

.title.situation,
.shiftedTitle.situation {
    background-image: url('../../../../assets/svg/wizard-situation-background.svg');
}

.title.track,
.shiftedTitle.track {
    background-image: url('../../../../assets/svg/wizard-track-background.svg');
}

.subtitle {
    width: 100%;
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    color: var(--text-light-gray-secondary);
}

.backArrow {
    position: absolute;
    left: 12px;
    top: 13px;
    transform: rotate(-90deg);
    height: 26px;
    width: 14px;
    cursor: pointer;
}

.backArrow path {
    stroke: var(--black);
    stroke-opacity: 1;
}
