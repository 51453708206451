.categoriesContainer {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;
}

.categoriesTitle,
.category {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--text-light-gray-secondary);
    white-space: nowrap;
}

.category {
    color: var(--black);
    background: var(--secondary-light-gray);
    padding: 3px 6px;
    border-radius: 12px;
}

.categoriesList {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    max-width: 100%;
    padding: 10px 20px;
    flex-wrap: wrap;
    gap: 6px 12px;
    background: var(--white);
    position: absolute;
    z-index: 5;
    border-radius: 10px;
    box-shadow: 3px -1px 10px 0px var(--black-transparent);
    top: 0%;
    height: 100%;
}

.categoryButtonContainer button,
.categoriesList button {
    padding: 3px 6px;
    padding-top: 3px;
    font-size: 12px;
    line-height: 16px;
    max-height: 22px;
}

.categoriesList input {
    width: 100px;
    height: 22px;
    font-size: 12px;
    line-height: 16px;
    padding: 0 6px;
}
