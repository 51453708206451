.languageCard {
    display: flex;
    gap: var(--_6px);
    /* border: 0.5px solid #d8dce6; */
    /* border-radius: var(--_32px); */
    padding: var(--_6px) var(--_8px);
    /* background-color: var(--white); */
    align-items: center;
}

.languageCard svg {
    flex-shrink: 0;
}

.languageCard p {
    font-weight: 400;
    font-size: var(--_16px);
}
