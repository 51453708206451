.active {
    max-width: 100vw;
    gap: 12px;
}

.lessons {
    max-width: 100vw;
    gap: 0px;
}

.hidden {
    opacity: 0;
    height: 0;
    max-height: 0;
    pointer-events: none;
}

.abortButtonContainer {
    position: fixed;
    bottom: 12px;
    right: 12px;
    z-index: 20;
    opacity: 0.5;
}

.abortButtonContainer > button {
    font-size: 10px;
    padding: 3px 6px;
    min-height: 20px;
    height: 20px;
    border-radius: 4px;
}

.delayLock {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
}

.loader {
    position: absolute;
    left: 12px;
    bottom: 12px;
    opacity: 0.5;
}
