.intro {
    background: var(--white);
    border-radius: var(--_16px);
    position: relative;
    min-width: 300px;
    max-height: 80vh;
    overflow: auto;
    padding: var(--_20px);
    max-width: 40vw;
    opacity: 0;
    transition: opacity 0.5s;
    animation: fadeIn 0.2s ease-in-out forwards;
    animation-delay: 0.5s;
    user-select: none;
}

.title {
    font-size: var(--_24px);
    font-weight: 700;
    line-height: var(--_28px);
    text-align: left;
}

.subtitle,
.subtitle span {
    font-family: Noto Sans;
    font-size: var(--_16px);
    font-weight: 400;
    line-height: var(--_22px);
    text-align: left;
    color: var(--text-light-gray-secondary);
    margin-top: var(--_12px);
    white-space: pre-line;
    text-align: justify;
}

.subtitle span {
    color: var(--black);
    font-weight: 500;
}

.intro > button {
    width: 100%;
    margin-top: var(--_24px);
    font-size: var(--_16px);
    line-height: var(--_20px);
    height: var(--_40px);
    min-height: var(--_40px);
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
