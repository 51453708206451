.playerCont {
    min-width: 500px;
}

.playerCont.disabled {
    pointer-events: none;
    filter: saturate(0);
    opacity: 0.5;
}

.playerCont.video {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.playerCont.audio,
.playerCont.custom_audio {
    border: 1px solid var(--secondary-light-gray);
    padding: 15px;
    border-radius: 22px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
}

.playerCont.audio.preview,
.playerCont.custom_audio.preview {
    padding: 5px;
    align-items: center;
}

.trackCont {
    position: relative;
    display: flex;
    justify-content: space-between;
    column-gap: 9px;
    min-width: 400px;
}

.trackCont.preview {
    align-items: center;
}

.timeItem {
    color: var(--text-light-gray-secondary);
    font-size: 12px;
}

.timeItem.right {
    text-align: end;
}

.playerCont.audio .trackCont,
.playerCont.custom_audio .trackCont {
    flex: 0 0 80%;
}

.sliderCont {
    margin-top: 4px;
    flex: 0 0 calc(100% - 100px);
}

.slider {
    position: relative;
    width: 100%;
}

.sliderTrack,
.sliderRange,
.trackProgress {
    position: absolute;
    height: 4px;
}

.sliderTrack {
    background-color: var(--primary-light-gray);
    left: 0;
    right: 0;
}

.sliderTrackPreview {
    position: absolute;
    height: 4px;
    background-color: var(--primary-light-gray);
    left: 0;
    right: 0;
    cursor: pointer;
}

.sliderTrackPreview::before {
    position: absolute;
    width: 100%;
    height: 20px;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    background-color: transparent;
    cursor: pointer;
}

.sliderRange.main {
    background-color: var(--purple-transparent);
}

.sliderRange.preview {
    background-color: var(--border-light-gray);
}

.sliderRange.disabled {
    opacity: 0;
}

.sliderRange::before {
    position: absolute;
    width: 100%;
    height: 20px;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    background-color: transparent;
    cursor: grab;
}

.trackProgress {
    height: 4px;
    background-color: var(--purple);
    pointer-events: none;
}

.leftValue,
.rightValue {
    width: 30px;
    text-align: center;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: var(--text-light-gray-secondary);
    position: absolute;
    user-select: none;
}

.leftValue {
    left: 0;
    top: 10px;
}

.rightValue {
    right: 0;
    bottom: 8px;
    text-align: end;
}

.leftValue.main,
.rightValue.main {
    color: var(--text-light-gray-secondary);
}

.leftValue.preview,
.rightValue.preview {
    color: var(--border-light-gray);
}

.thumb,
.thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.thumb.disabled {
    opacity: 0;
}

.thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    left: 47px;
    right: 47px;
    outline: none;
}

.thumb.left {
    z-index: 1;
}

.thumb.right {
    z-index: 1000;
}

.thumb::-webkit-slider-thumb {
    border: none;
    cursor: ew-resize;
    height: 13px;
    width: 3px;
    border-radius: 0;
    margin-top: 3px;
    pointer-events: all;
    position: relative;
}

.thumb.left::-webkit-slider-thumb,
.thumb.right::-webkit-slider-thumb {
    background-color: var(--purple);
    border: 1px solid var(--purple);
}

.thumb.preview::-webkit-slider-thumb {
    pointer-events: none;
    background-color: var(--border-light-gray);
    border: 1px solid var(--border-light-gray);
    cursor: default;
}

.controlsCont {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 28px;
}

.controlsCont.disabled {
    pointer-events: none;
    filter: saturate(0);
    opacity: 0.5;
}

.playBtn svg {
    width: 30px;
    height: 40px;
}

.controlBtnLeft svg,
.controlBtnRight svg {
    width: 32px;
}
