.pdfContainer {
    position: absolute;
    top: -10000px;
    left: -10000px;
    overflow: hidden;
    width: 210mm;
    height: 297mm;
    background: var(--white);
    padding: 60px 50px 0;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.logo {
    display: flex;
    gap: var(--_6px);
    margin-left: var(--_4px);
    margin-top: var(--_6px);
    position: relative;
    width: 100px;
    min-width: 100px;
    cursor: pointer;
    margin-right: auto;
}

.teacherLogo {
    width: 32px;
    height: 32px;
    margin-top: -4.5px;
}

.teacherLogoText {
    margin-top: 1px;
    transform: scale(0.8);
}

.exerciseTitle {
    font-size: 28px;
    font-weight: 400;
    line-height: 34px;
    margin-bottom: 24px;
}

.exerciseTitle span {
    font-size: 28px;
    font-weight: 400;
    line-height: 34px;
    color: var(--text-light-gray);
    margin-left: 10px;
}

.exerciseContainer > div {
    height: min-content;
}

.bottomContainer {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.platformButton {
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    width: min-content;
    height: 32px;
    padding: 12px 24px;
    border-radius: 8px;
    white-space: nowrap;
    user-select: none;
    -webkit-user-drag: none;
    transition: all 0.2s;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    background: var(--black);
    color: var(--white);
    text-decoration: underline;
    margin: 0 auto;
}

.buttonLogo {
    filter: invert(1);
    min-width: 16px;
    min-height: 16px;
    margin-left: -4px;
}

.footer {
    background: var(--primary-light-gray);
    width: calc(100% + 100px);
    margin-left: -50px;
    padding: 0 50px;
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer p {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: var(--text-light-gray);
}

.footer p:last-child {
    text-decoration: underline;
}
