.optionContainer {
    width: 100%;
    height: 100%;
    border: 1px dashed var(--dialogue-accent-gray);
    background: var(--white);
    border-radius: 8px;
    padding: 12px 14px;
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.1s;
}

.optionContainer.disabled {
    cursor: not-allowed;
    filter: grayscale(1);
    opacity: 0.5;
}

.optionContainer.disabled .title {
    color: var(--black-transparent-secondary);
}

.iconContainer {
    width: 34px;
    min-width: 34px;
    height: 34px;
    border-radius: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.optionContainer.exercise .iconContainer {
    background: var(--blue-transparent);
}

.optionContainer.track .iconContainer {
    background: var(--light-purple);
}

.optionContainer.situation .iconContainer {
    background: var(--orange-transparent);
}

.optionContainer.words .iconContainer {
    background: var(--words-green-transparent-hover);
}

.title {
    font-size: 16px;
    line-height: 21.79px;
}

@media (hover: hover) {
    .optionContainer:not(.onboarding):not(.disabled):hover {
        border-style: solid;
    }

    .optionContainer.exercise:not(.onboarding):hover {
        background: var(--blue-transparent);
    }
    .optionContainer.exercise:not(.onboarding):hover .iconContainer {
        background: var(--blue);
    }
    .optionContainer.track:not(.onboarding):hover {
        background: var(--light-purple);
    }
    .optionContainer.track:not(.onboarding):hover .iconContainer {
        background: var(--purple);
    }
    .optionContainer.situation:not(.onboarding):hover {
        background: var(--orange-transparent);
    }
    .optionContainer.situation:not(.onboarding):hover .iconContainer {
        background: var(--orange);
    }
    .optionContainer.words:not(.onboarding):not(.disabled):hover {
        background: var(--words-green-transparent-hover);
    }
    .optionContainer.words:not(.onboarding):not(.disabled):hover
        .iconContainer {
        background: var(--words-green);
    }
    .optionContainer:not(.onboarding):not(.disabled):hover .iconContainer path,
    .optionContainer:not(.onboarding):not(.disabled):hover .iconContainer rect {
        filter: brightness(0) invert(1);
    }
}
