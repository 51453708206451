.arrow {
    margin-left: -6px;
    height: 12px;
    width: 20px;
    min-width: 20px;
}

.arrow path {
    fill: var(--white);
}
