.container {
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    border-radius: 12px;
    border: 1px solid var(--border-light-gray);
    gap: 20px;
    overflow: visible scroll;
    position: relative;
    flex: 1;
}

.container > * {
    opacity: 0;
    animation: fadeIn 0.1s ease-in-out forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
