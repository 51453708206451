.searchBar {
    display: flex;
    align-items: flex-end;
    gap: 6px;
}

.searchBar.blacklist {
    margin-top: 17px;
}

.amount {
    padding: 10px;
    padding-bottom: 3px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--text-light-gray-secondary);
}
