.categoryCard {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;
    border: 1px solid var(--secondary-light-gray);
    border-radius: 20px;
    padding: 10px 20px;
}

.imageCont {
    width: 250px;
    min-width: 250px;
    height: 100px;
    overflow: hidden;
    border-radius: 20px;
}

.categoryImg {
    width: 250px;
    min-width: 250px;
    height: 100px;
    object-fit: cover;
    transition: transform 0.2s;
    cursor: pointer;
}

.infoCont {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100%;
}

.horizontalCont {
    display: flex;
    align-items: center;
    gap: 18px;
}

.buttonCont {
    opacity: 0;
    transition: opacity 0.2s;
}

.situationsList {
    width: 100%;
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
}

.categoryTitle {
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    width: -moz-fit-content;
    width: fit-content;
}

.situationTitle {
    margin: 0;
    width: max-content;
    padding: 4px 8px;
    background: var(--text-light-gray-secondary);
    color: var(--white);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    border-radius: 14px;
    transition: background-color 0.2s;
    cursor: pointer;
    text-transform: capitalize;
}

@media (hover: hover) {
    .categoryImg:hover {
        transform: scale(1.05);
    }
    .situationTitle:hover {
        background: var(--text-light-gray-secondary);
    }
    .categoryCard:hover .buttonCont {
        opacity: 1;
    }
}
